import { FC } from 'react'
import { Button, Icon, Count } from '@platform/ui'
import { Logo } from '@src/components/common/logo'
import { UserBar } from '@src/components/desktop/user-bar'
import { UserGuestBar } from '@src/components/desktop/user-guest-bar'
import { Flow } from '@src/components/common/flow'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { HeaderWrapper } from '@src/components/common/header-wrapper'

import { HeaderProps } from './types'

export const Header: FC<HeaderProps> = ({ autorized, location, cartCount = 0, onLogout }) => {
  const handleToCreateProduct = useNavigationApp({ to: LINKS.CREATE_PRODUCT })
  const handleToByCart = useNavigationApp({ to: LINKS.BY_CART })

  const handleToFavorites = useNavigationApp({ to: LINKS.PROFILE_FAVORITES })
  return (
    <HeaderWrapper>
      <Flow align="center" className="justify-between">
        <Flow align="center" space={3}>
          <Logo className="h-8" />
          {/* <Button icon={<Icon.Location />} color="white" size="md" variant="link">
              {location ?? 'Город / Нас.пункт'}
            </Button> */}
        </Flow>
        <Flow align="center" space={'34px'}>
          <Button
            className="pl-2.5 pr-3.5"
            textClassName="font-medium"
            icon={<Icon.Plus />}
            size="md"
            color="positive"
            onClick={handleToCreateProduct}
          >
            разместить объявление
          </Button>
          <Flow align="center">
            <Button icon={<Icon.FavoriteFull />} color="white" size="sm" variant="link" onClick={handleToFavorites} />
            <Count value={cartCount} colorName="red" size="xs" className="pb-1">
              <Button
                icon={<Icon.ShoppingCartFill />}
                size="sm"
                color="white"
                variant="link"
                onClick={handleToByCart}
              />
            </Count>
          </Flow>

          {autorized ? <UserBar onLogout={onLogout} /> : <UserGuestBar />}
        </Flow>
      </Flow>
    </HeaderWrapper>
  )
}
