import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { Info } from '@platform/ui'

export const Payment: FC = observer(() => {
  return (
    <Flow directory="col">
      <SectionH2 className="py-6" title="Оплата">
        <div>
          <Flow directory="col" align="center">
            <Info size="3xl" type="success" color="positive" title="Оплата выполнена успешно" />
            <span>После успешного прохождения подерации объявление будет опубликовано.</span>
          </Flow>
        </div>
      </SectionH2>
    </Flow>
  )
})
