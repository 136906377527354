import { useEffect, useCallback } from 'react'
import { core } from '@src/core'
import { ParamsProductsByGeoHash } from '@platform/core/dist/api/v1/product'

const { productService } = core

const { locationProducts } = productService

export interface UseLocationsProductsProps {
  params: ParamsProductsByGeoHash
  hash: Nullable<string>
  count: Nullable<number>
}

export const UseLocationProducts = ({ params, hash, count }: UseLocationsProductsProps) => {
  useEffect(() => {
    if (hash && count) {
      productService.fetchProductsByGeoHash(hash, count, params)
    }
  }, [hash, count, params])

  const loadNextPage = useCallback(() => {
    if (hash && count) {
      locationProducts.setState({ page: productService.locationProducts.page + 1 })
      productService.fetchProductsByGeoHash(hash, count, params)
    }
  }, [])

  const reset = useCallback(() => {
    locationProducts.reset()
  }, [])

  return {
    products: locationProducts.list,
    requestStatus: locationProducts.status,
    isLast: locationProducts.isLast,
    page: locationProducts.page,
    loadNextPage,
    resetState: reset,
    totalPages: locationProducts.totalPages,
    status: locationProducts.status,
  }
}
