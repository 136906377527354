import { FC, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Flow } from '@src/components/common/flow'
import { Title } from '@src/components/common/title'
import { Checkbox } from '@platform/ui'
import { ProductTag } from '@platform/core/dist/models'

import { validateSearchParam } from '../helper'
import { extraConfig } from './constants'
import { ProductTagFilterProps } from './types'
import { FilterSearchParam } from '../types'

const { EXTRA } = FilterSearchParam

export const ProductTagFilter: FC<ProductTagFilterProps> = observer(
  ({ setSearchParam, getSearchParam, hasSearchParam, searchParams, deleteSearchParam }) => {
    let defaultState: ProductTag[] = []
    if (validateSearchParam(EXTRA, getSearchParam(EXTRA))) {
      defaultState = (getSearchParam(EXTRA) as string).split(',') as ProductTag[]
    }
    const [state, setState] = useState(defaultState)

    useEffect(() => {
      if (hasSearchParam(EXTRA)) {
        setState((getSearchParam(EXTRA) as string).split(',') as ProductTag[])
      } else {
        setState([])
      }
    }, [searchParams])

    const handleChange = useCallback(
      (checked: boolean, value: string) => {
        let newSelected = [...state]
        if (checked) {
          newSelected.push(value as ProductTag)
        } else {
          newSelected = newSelected.filter((item) => item !== value)
        }

        setState(newSelected)
        if (newSelected.length) {
          setSearchParam(EXTRA, newSelected.join(','))
        } else {
          deleteSearchParam(EXTRA)
        }
      },
      [setSearchParam]
    )

    return (
      <Flow directory="col">
        <Title size="md" weight="medium">
          Дополнительно
        </Title>
        <Flow directory="col" space={3.5} className="pt-18px">
          {extraConfig.map((item, index) => (
            <Checkbox
              key={index}
              isChecked={state.includes(item.value)}
              value={item.value}
              onChangeChecked={handleChange}
            >
              {item.label}
            </Checkbox>
          ))}
        </Flow>
      </Flow>
    )
  }
)
