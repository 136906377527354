import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, Icon, Drawer, Title } from '@platform/ui'

import { LINKS } from '@src/routes'
import { core, ScreenMode } from '@src/core'
import { SectionH1 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { useSearchParamsApp } from '@src/hooks/use-search-params-app'
import { CategorySwiper } from '@src/container/category-swiper'
import { ProductSort } from '@src/search-params-settings'
import NotFound from '@src/pages/not-found'

import { SelectedFilters } from './container/selected-filters'
import { ProductFilter } from './container/product-filter'
import { SearchParamsFilters } from './container/search-params-filters'
import { Breadcrumbs } from './container/breadcrumbs'
import { FilterParamsProvider } from './context'

import { useAppLayoutContext } from '@src/container/layout'

const isServer = process.env.APP_ENV === 'server'

const { categoryService, layoutService } = core
const { screen } = layoutService

export const Category: React.FC = observer(() => {
  const { slug } = useParams()

  const handleToCategoryAll = useNavigationApp({ to: LINKS.CATEGORY_HOME })

  const context = useAppLayoutContext()

  const {
    clearBrowserSearchParams,
    clearLocalSearchParams,
    searchParams,
    deleteSearchParam,
    getSearchParam,
    hasSearchParam,
    setSearchParam,
    setLocalParamsInGlobal,
  } = useSearchParamsApp(screen.mode !== ScreenMode.desktop)

  const [modalState, setModalState] = useState<boolean>(false)
  const openModal = useCallback(() => setModalState(true), [])
  const closeModal = useCallback(() => setModalState(false), [])

  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const category = useMemo(() => {
    if (!slug) return
    const [, categoryId] = (slug as string).split('--')
    return categoryService.categories.findById(Number(categoryId))
  }, [slug, categoryService.categories.list])

  const handleClearSearchParams = useCallback(() => {
    clearBrowserSearchParams()
    clearLocalSearchParams()
  }, [clearBrowserSearchParams, clearLocalSearchParams, handleToCategoryAll])

  const handleApplyParams = useCallback(() => {
    setLocalParamsInGlobal()
    closeModal()
  }, [setLocalParamsInGlobal])

  if (categoryService.isEmpty) return null

  if (slug && !category && !categoryService.isEmpty) {
    return <NotFound />
  }

  return (
    <Flow directory="col" space={10} className="content-main py-8">
      <Breadcrumbs />
      <FilterParamsProvider
        value={{
          getSearchParam,
          hasSearchParam,
          setSearchParam,
          deleteSearchParam,
          searchParams,
        }}
      >
        <SectionH1 title={slug && category?.name ? category.name : 'Все категории'}>
          <Flow>
            {screen.mode === ScreenMode.desktop && <SearchParamsFilters className="w-345px flex-static" />}
            <Flow directory="col" space={6} full className="overflow-hidden">
              <CategorySwiper maxSlideRowCount={1} withNavigation={false} initialCategory={category} />
              <Flow className="justify-between"full>
                <Flow space={5} directory={screen.mode === ScreenMode.desktop ? 'row' : 'col'} align="end" full>
                  <ProductSort
                    setSearchParam={setSearchParam}
                    deleteSearchParam={deleteSearchParam}
                    getSearchParam={getSearchParam}
                    hasSearchParam={hasSearchParam}
                    searchParams={searchParams}
                  />
                  <SelectedFilters />
                </Flow>
                {screen.mode !== ScreenMode.desktop && (
                  <Button color="primary" iconPosition="left" icon={<Icon.Filter />} onClick={openModal}>
                    Фильтры
                  </Button>
                )}
              </Flow>
              <ProductFilter />
            </Flow>
          </Flow>
          {!isServer && (
            <Drawer isOpen={modalState} onClose={closeModal} width="100%" containerRef={context.mainNode}>
              <Flow
                directory="col"
                space={7}
                className="w-full h-full overflow-y-scroll overflow-x-hidden scrollbar px-7 pt-4 pb-16"
              >
                <Flow className="justify-between">
                  <Title size="1xl" weight="semibold">
                    Фильтры
                  </Title>
                  <Button variant="link" className="text-xss" onClick={closeModal}>
                    <Icon.Close size="lg" />
                  </Button>
                </Flow>
                <SearchParamsFilters className="w-full max-w-345px" />
                <Flow directory="col" space={3} full>
                  <Button
                    variant="outline"
                    className="w-full"
                    textClassName="text-xs md:text-sm"
                    color="black"
                    onClick={handleClearSearchParams}
                  >
                    Сбросить фильтры
                  </Button>
                  <Button
                    color="primary"
                    className="w-full"
                    textClassName="text-xs md:text-sm"
                    onClick={handleApplyParams}
                  >
                    Применить фильтры
                  </Button>
                </Flow>
              </Flow>
            </Drawer>
          )}
        </SectionH1>
      </FilterParamsProvider>
    </Flow>
  )
})
