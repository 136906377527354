import { FC, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { notification, Button, Input, Icon, List, ListItem, Info } from '@platform/ui'
import { FormItem } from '@src/components/common/form-item'
import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'
import { useNavigationApp } from '@src/hooks'

import { LoginForm } from './login-form'
import { AuthFormProps } from './types'
import { passwordRulesMap } from './constants'

const { userService } = core
const { registrationForm } = userService
const { phone, email, password, confirmPassword } = registrationForm

export const SingUp: FC<AuthFormProps> = observer(({ defaultLinkPath = '' }) => {
  const [isComplteSignUp, setIsCompliteSignUp] = useState(false)
  const handleToSignIn = useNavigationApp({ to: defaultLinkPath + LINKS.AUTH_SIGNIN })

  useEffect(() => {
    return () => {
      registrationForm.reset()
    }
  }, [])

  const handleSubmit = useCallback(() => {
    userService.signUp(`${window.location.origin}${defaultLinkPath + LINKS.AUTH_CODE}?code=`).then((result) => {
      if (result.isSuccess) {
        setIsCompliteSignUp(true)
        return
      }

      if (result.isFailure) {
        notification.error({
          message: 'Ошибка при регистрации',
          description: result.getError().toString(),
        })
      }
    })
  }, [])

  return (
    <LoginForm title="Регистрация" hideSocial={isComplteSignUp} onSubmit={handleSubmit}>
      {!isComplteSignUp && (
        <>
          <Flow directory="col" className="space-y-2.5 md:space-y-18px">
            <FormItem withoutLabel errorText={email.errorText}>
              <Input
                size="md"
                color="primary"
                placeholder={email.label}
                onChangeValue={email.changeValue}
                error={!email.isValid}
              />
            </FormItem>
            <FormItem withoutLabel errorText={password.errorText}>
              <Input
                size="md"
                color="primary"
                type="password"
                placeholder={password.label}
                onChangeValue={password.changeValue}
                error={!password.isValid}
              />
            </FormItem>
          </Flow>
          <List className="list-disc list-inside mt-3 mb-18px">
            {passwordRulesMap.map((item, key) => (
              <ListItem key={key} className="text-xss md:text-xs">
                {item}
              </ListItem>
            ))}
          </List>
          <FormItem withoutLabel errorText={confirmPassword.errorText}>
            <Input
              size="md"
              color="primary"
              type="password"
              placeholder={confirmPassword.label}
              onChangeValue={confirmPassword.changeValue}
              error={!confirmPassword.isValid}
            />
          </FormItem>
          <Button
            color="primary"
            size="md"
            shape="round"
            type="submit"
            loading={registrationForm.isLoading === 'loading'}
            className="w-full mt-14px md:mt-22px"
          >
            Зарегистрироваться
          </Button>
          <Flow className="mt-2.5 md:mt-14px justify-center">
            <Flow align="center" space={1.5}>
              <span>Уже есть аккаунт?</span>
              <Button
                className="underline font-normal"
                color="primary"
                variant="link"
                onClick={handleToSignIn}
                iconPosition="right"
                size="md"
                icon={<Icon.ArrowLong position="right" />}
              >
                Войти
              </Button>
            </Flow>
          </Flow>
        </>
      )}

      {isComplteSignUp && (
        <Flow directory="col" align="center" space={'22px'}>
          <Info size="3xl" type="success" color="positive" />
          <Flow directory="col" space={3} align="center">
            <span className="text-1xl font-medium leading-2xl">Подтвердите ваш email</span>
            <span className="leading-md text-center">
              Мы выслали вам на почту <span className="font-bold">{email.value}</span> письмо для подтверждения
              регистрации. Если вы его не видете, проверьте папку “Спам”.
            </span>
          </Flow>
          <a href={`mailto:${email.value}`} target="_blank">
            <Button size="md" color="primary" className="w-full">
              Проверить почту
            </Button>
          </a>
        </Flow>
      )}
    </LoginForm>
  )
})
