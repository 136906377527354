import { useSearchParamsAppReturn } from '@src/hooks/use-search-params-app'

export enum SortParam {
  NEW = 'new',
  OLD = 'old',
  PRICE_DESС = 'priceDesс',
  PRICE_ASC = 'priceAsc',
}

export interface ProductSortProps {
  deleteSearchParam: useSearchParamsAppReturn['deleteSearchParam']
  setSearchParam: useSearchParamsAppReturn['setSearchParam']
  getSearchParam: useSearchParamsAppReturn['getSearchParam']
  hasSearchParam: useSearchParamsAppReturn['hasSearchParam']
  searchParams: useSearchParamsAppReturn['searchParams']
}
