import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, ButtonFavorite } from '@platform/ui'

import { core } from '@src/core'
import { Price } from '@src/container/price'
import { Flow } from '@src/components/common/flow'

import { CategoriesExchange } from './container/categories-exchange'
import { useProductContext } from './hooks/useProductContext'
import { PersonalControl } from './components/personal-control'

const { productService, authService } = core
const { product } = productService
const { authorizedUser } = authService

export const ProductExchange: FC = observer(() => {
  const { onClickFavorite } = useProductContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const handleToOffer = useCallback(() => navigate(`/product/exchange/offer/${id}`), [id])

  if (!product.data) return null

  return (
    <>
      {product.data.price && (
        <Price value={product.data.price} measure={product.data.measure.name} description="Цена за 1 " />
      )}
      <>
        {product.data.quantity && (
          <Flow className="font-semibold" space={1.5} align="center">
            <span>В наличии:</span>
            <span className="text-lg">{`${product.data.quantity} ${product.data.measure.name}`}</span>
          </Flow>
        )}
        <Flow space={4} directory="col">
          <span className="font-medium text-md">Обмен на:</span>
          <CategoriesExchange product={product.data} />
        </Flow>
        {product.data.exchangeDescription && (
          <Flow directory="col" className="text-xs" space={2}>
            <span className="text-neutral-400 font-medium">Детали обмена:</span>
            <span>{product.data.exchangeDescription}</span>
          </Flow>
        )}
      </>
      {authorizedUser.user?.id === product.data.userId && <PersonalControl productId={product.data.id} />}
      {authorizedUser.user?.id !== product.data.userId && (
        <>
          <Flow className="justify-between pt-10" align="center">
            <Button
              size="xl"
              color="primary"
              className="w-5/6"
              textClassName="text-xs md:text-sm font-semibold"
              onClick={handleToOffer}
            >
              Предложить обмен
            </Button>
            <ButtonFavorite isActive={product.data.isFavorite} onClick={onClickFavorite} />
          </Flow>
          <Button size="xl" className="w-full" variant="outline" textClassName="text-xs md:text-sm font-semibold">
            Написать сообщение
          </Button>
        </>
      )}
    </>
  )
})
