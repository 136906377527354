import { FC } from 'react'
import { Button, Icon } from '@platform/ui'
import { Logo } from '@src/components/common/logo'
import { Flow } from '@src/components/common/flow'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { HeaderWrapper } from '@src/components/common/header-wrapper'

import { HeaderProps } from './types'

export const Header: FC<HeaderProps> = ({ autorized, location }) => {
  const handleToProfile = useNavigationApp({ to: LINKS.PROFILE_HOME })
  const handleToEntry = useNavigationApp({ to: LINKS.AUTH_SIGNIN })
  const handleToCreateProduct = useNavigationApp({ to: LINKS.CREATE_PRODUCT })
  const handleToFavorite = useNavigationApp({ to: LINKS.PROFILE_FAVORITES })

  return (
    <HeaderWrapper>
      <Flow align="center" className="justify-between text-xs font-medium">
        <Flow align="center" space={3}>
          <Logo className="h-8" />
          {/* <Button icon={<Icon.Location />} color="white" size="md" variant="link">
              {location ?? 'Город / Нас.пункт'}
            </Button> */}
        </Flow>
        <Flow align="end" space={3}>
          <Flow align="center" directory="col">
            <Button
              icon={<Icon.AddFull className="bg-white rounded-full" color="positive" />}
              size="2xl"
              onClick={handleToCreateProduct}
              variant="link"
            />
            <span>Добавить</span>
          </Flow>
          <Flow align="center" directory="col">
            <Button icon={<Icon.FavoriteFull />} color="white" size="2xl" variant="link" onClick={handleToFavorite} />
            <span>Избранное</span>
          </Flow>
          {autorized ? (
            <Flow align="center" directory="col">
              <Button icon={<Icon.UserFull />} color="white" size="2xl" variant="link" onClick={handleToProfile} />
              <span>Профиль</span>
            </Flow>
          ) : (
            <Flow align="center" directory="col">
              <Button icon={<Icon.Entry />} color="white" size="2xl" variant="link" onClick={handleToEntry} />
              <span>Вход</span>
            </Flow>
          )}
        </Flow>
      </Flow>
    </HeaderWrapper>
  )
}
