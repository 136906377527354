import { FC } from 'react'

import { CategoryIconProps } from './types'
import { categoryIconMap } from './constants'

export const CategoryIcon: FC<CategoryIconProps> = ({ code, className }) => {
  if (!categoryIconMap[code]) {
    return null
  }

  const Component = categoryIconMap[code]

  return <Component className={className} />
}
