import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Select, SelectMulti, SelectOption } from '@platform/ui'
import { FieldSelectInterface } from '@platform/core/dist/modules/product/domain/fields/models'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldSelect: FC<Detail> = observer(({ id }) => {
  const { label, required, value, changeValue, options, isMulti, errorText, description } = createProduct.fields.find(
    (item) => item.id === id
  ) as FieldSelectInterface

  const handleChange = useCallback(
    (value: SelectOption | SelectOption[]) => {
      const newValue = isMulti ? (value as FieldSelectOption) : (value as FieldSelectOption[])
      changeValue(newValue)
    },
    [changeValue, isMulti]
  )

  return (
    <div className="pt-2 space-y-2">
      <Label
        required={required}
        label={label}
        size="sm"
        textStyle="font-medium text-black text-sm"
        tooltipText={description}
      />

      {isMulti ? (
        <SelectMulti
          size="md"
          options={options}
          placeholder="Выберите значение"
          onChange={handleChange}
          defaultValue={value as SelectOption[]}
          className="w-full"
        />
      ) : (
        <Select
          size="md"
          options={options}
          defaultValue={value as SelectOption}
          onChange={handleChange}
          className="w-full"
        />
      )}
      <div className="mt-2 text-invalid">{errorText}</div>
    </div>
  )
})
