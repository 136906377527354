import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Title, Button, Input, Icon, notification } from '@platform/ui'
import { SectionH2 } from '@src/components/common/section'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { useProfileProducts } from '@src/hooks/use-profile-products'
import { ProductStateDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { Brief } from './components/brief'
import { OfferProduct } from './components/offer-product'
import { LINKS } from '@src/routes'

const { productService, offerService } = core
const { product } = productService
const { exchangeOffer } = offerService

export const OfferExchange: FC = observer(() => {
  const { id } = useParams()

  const handleToCreateProduct = useNavigationApp({ to: LINKS.CREATE_PRODUCT })

  const handleToProduct = useNavigationApp({ to: `/product/exchange/${id}` })

  const handleCreateExchange = useCallback(() => {
    if (product.data) {
      offerService.createExchnageOffer(product.data.id, product.data.userId).then((res) => {
        if (res.isSuccess) {
          notification.success({ message: 'Предложение на обмен успешно отправлено' })
          handleToProduct()
        }
        if (res.isFailure) {
          notification.error({ message: 'Произошла ошибка, попробуйте снова' })
        }
      })
    }
  }, [])

  const handleChangeOfferProduct = useCallback((id: number) => {
    exchangeOffer.productId.changeValue(id)
  }, [])

  const { products } = useProfileProducts({
    params: { state: ProductStateDTO.active, size: 20, type: ProductTypeDTO.exchange },
  })

  if (!product.data) return null

  return (
    <Flow directory="col" space={11}>
      <Title size="2xl" textClassName="font-semibold">
        <h1>Предложить обмен</h1>
      </Title>
      <SectionH2 title="Объявление продавца">
        <Brief product={product.data} />
      </SectionH2>
      <SectionH2 title="Вы предлагаете на обмен">
        <Flow directory="col" className=" max-w-710px h-380px overflow-y-scroll overflow-x-hidden scrollbar" space={4}>
          {products.map((item) => (
            <OfferProduct
              key={item.id}
              productType={ProductTypeDTO.exchange}
              onChange={handleChangeOfferProduct}
              data={item}
              selected={exchangeOffer.productId.value === item.id}
            />
          ))}
          <div onClick={handleToCreateProduct}>
            <Flow
              directory="col"
              className="py-7 border-xs border-neutral-300 rounded-xl cursor-pointer"
              full
              align="center"
            >
              <Button
                variant="link"
                color="black"
                textClassName="text-sm"
                iconPosition="left"
                icon={<Icon.Plus position="left" />}
              >
                Создать объявление на обмен
              </Button>
              <Flow directory="col" className="text-sm text-neutral-400" align="center">
                <span>Если у вас нет подходящих объявлений на обмен,</span>
                <span>то вы легко можете создать их.</span>
              </Flow>
            </Flow>
          </div>
        </Flow>
      </SectionH2>
      <SectionH2 title="Комментарий">
        <Input
          className="w-md p-3.5"
          placeholder="Сюда можно написать важные детали обмена.."
          multiline
          onChangeValue={exchangeOffer.comment.changeValue}
          value={exchangeOffer.comment.value}
          rows={12}
        />
      </SectionH2>
      <div className="items-start flex">
        <Button
          size="xl"
          color="primary"
          textClassName="text-xs md:text-sm font-semibold"
          className="px-20"
          loading={exchangeOffer.statusCreate === 'loading'}
          onClick={handleCreateExchange}
        >
          Предложить обмен
        </Button>
      </div>
    </Flow>
  )
})
