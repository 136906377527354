import React from 'react'
import { Title } from '@platform/ui'

export const ConditionsDelivery: React.FC = (props) => {
  return (
    <div>
      <Title size="lg" colorNumber={900}>
        Способ доставки
      </Title>
    </div>
  )
}
