import { observer } from 'mobx-react-lite'
import { core } from '@src/core'

import { CartGrid } from './cart-grid'
import { Empty } from './empty'

const cartService = core.cartService

export const CartLayout = observer(() => {
  const { count, status } = cartService

  if (status !== 'loading' && !count) {
    return <Empty />
  }

  return <CartGrid />
})

CartLayout.displayName = 'CartLayout'
