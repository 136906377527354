import { ROUTES } from '@src/routes'

const { CODE, SIGNIN, SIGNUP, SOCIAL, VERIFY_PHONE } = ROUTES.AUTH

export const navigationAuth = {
  [SIGNIN.INDEX]: 'Вход',
  [SIGNUP.INDEX]: 'Регистрация',
  [SOCIAL.INDEX]: 'Вход',
  [CODE.INDEX]: 'Проверка кода',
  [VERIFY_PHONE.INDEX]: 'Регистрация',
}
