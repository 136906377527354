import { FC, useContext } from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@platform/ui'
import { SectionItemProps } from './types'
import { CategoriesContext } from './context'
import { CategoryIcon } from '../category-icon'

export const SectionItem: FC<SectionItemProps> = ({ name, code, id }) => {
  const context = useContext(CategoriesContext)

  return (
    <div
      className="hover:bg-neutral-100 px-3 py-2 rounded-md flex items-center justify-between w-full cursor-pointer"
      onMouseOver={() => context?.setActiveSection(code)}
    >
      <div className="flex gap-x-2.5 items-center">
        <CategoryIcon code={code} className="text-md text-neutral-500" />
        <Link
          to={`/category/${code}--${id}`}
          className="whitespace-nowrap font-normal text-lg leading-4.8 text-black mr-4"
        >
          {name}
        </Link>
      </div>
      <div>
        <Icon.Arrow className="text-md text-neutral-400" position="right" />
      </div>
    </div>
  )
}
