import { FC, useCallback, useRef } from 'react'
import { Map as MapLib, YMaps, ZoomControl } from '@pbe/react-yandex-maps'
import ymaps from 'yandex-maps'
import { MapProps } from './types'

export const DEFAULT_STATE = {
  center: [55.758251, 37.622997],
  zoom: 13,
}

export const Map: FC<MapProps> = (props) => {
  const { onClick, onBoundsChange, children, options, ...rest } = props

  const ymapsRef = useRef<typeof ymaps | null>(null)
  const mapRef = useRef<ymaps.Map>()

  const handleMapClick = useCallback(
    (e: ymaps.MapEvent) => {
      const coords = e.get('coords')
      onClick?.(coords)
    },
    [onClick]
  )

  const handleLoadYmaps = useCallback(
    (api: typeof ymaps) => {
      ymapsRef.current = api

      if (mapRef.current) {
        onBoundsChange?.({
          // @ts-ignore
          newZoom: mapRef.current.getZoom(),
          // @ts-ignore
          newBounds: mapRef.current.getBounds(),
          // @ts-ignore
          newCenter: mapRef.current.getCenter(),
        })
      }
    },
    [onBoundsChange]
  )

  const handleBoundsChange = useCallback(
    (event: any) => {
      onBoundsChange?.({
        newZoom: event.get('newZoom'),
        newBounds: event.get('newBounds'),
        newCenter: event.get('newCenter'),
      })
    },
    [onBoundsChange]
  )

  return (
    <YMaps
      version="2.1"
      // query={{ //todo
      //   apikey: '',
      // }}
    >
      <MapLib
        height="100%"
        width="100%"
        modules={['templateLayoutFactory', 'layout.ImageWithContent']}
        onBoundsChange={handleBoundsChange}
        onClick={handleMapClick}
        defaultState={DEFAULT_STATE}
        options={{ suppressMapOpenBlock: true, yandexMapDisablePoiInteractivity: true, ...options }}
        instanceRef={mapRef}
        onLoad={handleLoadYmaps}
        {...rest}
      >
        <ZoomControl
          options={{
            position: {
              top: 3,
              right: 3,
            },
          }}
        />
        {children}
      </MapLib>
    </YMaps>
  )
}
