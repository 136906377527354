import { FC, useState, useCallback, memo, useEffect } from 'react'

import { TreeCategoriesProps } from './types'
import { CategoriesContext } from './context'
import { Categories } from './Categories'
import { SectionItem } from './SectionItem'
import { CategoryDTO } from '@platform/core/dist/modules/catalog/infra/dtos'

export const TreeCategories: FC<TreeCategoriesProps> = memo(({ tree = [] }) => {
  const [activeSection, setActiveSection] = useState<CategoryDTO>(tree[0])

  useEffect(() => {
    if (tree.length) {
      setActiveSection(tree[0])
    }
  }, [tree])

  const handleChangeCategory = useCallback(
    (sectionCode: string) => {
      setActiveSection(tree.find((section) => section.code === sectionCode) as CategoryDTO)
    },
    [tree]
  )

  return (
    <CategoriesContext.Provider value={{ activeSection: activeSection, setActiveSection: handleChangeCategory }}>
      <div className="w-full xl:max-w-3xl mx-auto flex py-4">
        <div className="px-4 border-r-xs border-neutral-400">
          {tree.map((item) => (
            <SectionItem key={item.id} {...item} />
          ))}
        </div>
        <Categories />
      </div>
    </CategoriesContext.Provider>
  )
})
