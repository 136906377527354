import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SearchForm } from '@src/container/search-form'
import { Flow } from '@src/components/common/flow'
import { SectionH2, Section } from '@src/components/common/section'
import { useFilteredProducts } from '@src/hooks/use-filtered-products'
import { CardUser, Swiper, SwiperSlide } from '@platform/ui'
import { core, ScreenMode } from '@src/core'
import { ProductList } from '@src/components/desktop/product-list'
import { CategorySwiper } from '@src/container/category-swiper'
import { ParamsPaginationSort, ParamsPaginationSortColumn } from '@platform/core/dist/api/v1/types'
import { useScreenModeValue } from '@src/hooks'

import { EmailSubscribe } from './container/email-subscribe'
import { PopularCategories } from './container/popular-categories'
import { RegionList } from './container/region-list'
import { Banner } from './container/banner'
import { ProductTag } from '@platform/core/dist/models'

const { layoutService, userCompilationService } = core
const { screen } = layoutService
const { verifiedUsers } = userCompilationService

export const Home: FC = observer(() => {
  const userSlide = useScreenModeValue<{ slideClassName: string; slidePerGroup: number }>({
    desktop: {
      slideClassName: 'w-1/4 mr-4',
      slidePerGroup: 4,
    },
    tablet: {
      slideClassName: 'w-1/3 mr-4',
      slidePerGroup: 3,
    },
    mobile: {
      slideClassName: 'w-full',
      slidePerGroup: 1,
    },
  })

  const { products: productsDiscount, requestStatus: productDiscountRequestStatus } = useFilteredProducts({
    params: {
      tag: [ProductTag.DISCOUNT],
      size: 4,
    },
  })

  const {
    products,
    loadNextPage: productsLoadNextPage,
    requestStatus: productsRequestStatus,
    isLastPage: productsIsLastPage,
  } = useFilteredProducts({
    params: {
      size: 12,
      sort: ParamsPaginationSort.Desc,
      sortColumn: ParamsPaginationSortColumn.CreateDate,
    },
  })

  useEffect(() => {
    userCompilationService.fetchCompilationVerifiedUsers({
      pageSize: 8,
      page: verifiedUsers.page,
    })
  }, [verifiedUsers.page])

  return (
    <Flow
      directory="col"
      space={screen.mode === ScreenMode.mobile ? '54px' : '88px'}
      className="content-main pb-36 md:pb-59"
    >
      <div className="w-full mt-7 md:mt-20">
        <Banner />
        <div className="max-w-xl mx-auto w-full mt-9 md:mt-46px ">
          <SearchForm />
        </div>
      </div>
      <SectionH2 title="Популярные категории">
        <PopularCategories />
      </SectionH2>
      <Section>
        <CategorySwiper />
      </Section>
      {!!productsDiscount.length && (
        <SectionH2
          title="Товары со скидками"
          linkPath={`/category?extra=${ProductTag.DISCOUNT}`}
          linkTitle="Смотреть все товары со скидками"
        >
          <ProductList products={productsDiscount} requestStatus={productDiscountRequestStatus} />
        </SectionH2>
      )}
      <SectionH2 title="Объявления по регионам России">
        <RegionList />
      </SectionH2>
      {!verifiedUsers.isEmpty && (
        <SectionH2 title="Проверенные продавцы">
          <Swiper
            arrowPosition={screen.mode === ScreenMode.desktop ? 'inner' : 'bottom'}
            freeMode={false}
            slidesPerGroup={userSlide.slidePerGroup}
            onNextIndexChange={verifiedUsers.loadNextPage}
            loadbleStatus={verifiedUsers.status}
          >
            {verifiedUsers.list.map((item, key) => (
              <SwiperSlide key={key} className={userSlide.slideClassName}>
                <CardUser
                  title={item.name}
                  description={item.description}
                  avatarSrc={item.image}
                  rating={item.rating}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SectionH2>
      )}
      <EmailSubscribe />
      <SectionH2 title="Новые объявления" linkPath={`/category?sorting=new`} linkTitle="Смотреть последние объявления">
        <ProductList
          products={products}
          requestStatus={productsRequestStatus}
          loadMore={productsLoadNextPage}
          isLastPage={productsIsLastPage}
        />
      </SectionH2>
    </Flow>
  )
})

Home.displayName = 'HomePage'
