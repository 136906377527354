import { FC } from 'react'
import { Link } from 'react-router-dom'
import { CategoryTitleProps } from './types'
import cn from 'classnames'

export const CategoryTitle: FC<CategoryTitleProps> = ({ href, name, active }) => {
  const weight = active ? 'bold' : 'normal'
  return (
    <Link to={href}>
      <span className={cn('hover:underline text-black text-md leading-5', `font-${weight}`)}>{name}</span>
    </Link>
  )
}
