import React from 'react'
import { Rating as UIRating } from '@platform/ui'

import { RatingProps } from './types'

export const Rating: React.FC<RatingProps> = (props) => {
  const { value, count, text, onClick } = props
  let defaultText = text

  if (!count) {
    defaultText = ''
  }
  const child = React.useMemo(() => [count, defaultText].filter(Boolean).join(' '), [count, defaultText])
  return (
    <div className="flex items-center space-x-2" onClick={onClick}>
      <UIRating value={value} />
      <span className="text-primary-500">{child}</span>
    </div>
  )
}
