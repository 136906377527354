import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useRef, Suspense } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import DesktopHeader from '@src/components/desktop/header'
import TabletHeader from '@src/components/tablet/header'
import MobileHeader from '@src/components/mobile/header'
import NavMenuBar from '@src/components/mobile/nav-menu-bar'
import Footer from '@src/components/common/footer'
import { ScreenMode, EventBus, RequestErrorEvent } from '@platform/core'
import { notification, Spinner } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'

import { AppLayoutProvider } from './context'

const headerComponent: Record<ScreenMode, any> = {
  [ScreenMode.desktop]: DesktopHeader,
  [ScreenMode.tablet]: TabletHeader,
  [ScreenMode.mobile]: MobileHeader,
}

const { authService, filterService, metaDataService, layoutService, cartService } = core
const { metaData } = metaDataService

export const Layout: React.FC = observer(() => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const wrapperNode = useRef<HTMLDivElement>(null)
  const mainNode = useRef<HTMLElement>(null)
  const [handleToTop] = useScrollIntoView({ ref: wrapperNode })

  useEffect(() => {
    const cb = (event: RequestErrorEvent) => {
      if (typeof event.status !== 'undefined') {
        if (event.status === 403 && pathname !== LINKS.AUTH_SIGNIN) {
          navigate(LINKS.AUTH_SIGNIN)
        } else if (event.status >= 500) {
          notification.error({
            message: 'Ошибка',
            description: 'Возникла ошибка на сервере, перезагрузите страницу',
          })
        }
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Возникла непредвиденная ошибка',
        })
      }
    }
    new EventBus().subscribe('REQUEST_ERROR', cb)

    return () => {
      new EventBus().unsubscribe('REQUEST_ERROR', cb)
    }
  }, [])

  useEffect(() => {
    if (!metaDataService.isLoading) {
      metaDataService.fetchData(pathname)
    }
  }, [pathname])

  const handleLogout = useCallback(() => {
    authService
      .logout()
      .then(() => cartService.removeCart())
      .then(() => {
        navigate(LINKS.AUTH_SIGNIN)
      })
  }, [])

  const mode = layoutService.screen.mode as ScreenMode
  const Header = headerComponent[mode]

  return (
    <div ref={wrapperNode}>
      <Helmet>
        <title>{metaData.data?.title ?? 'Заголовок'}</title>
        <meta name="description" content={metaData.data?.metaDescription ?? 'мета описание'}></meta>
        <meta name="title" content={metaData.data?.metaTitle ?? 'мета заголовок'}></meta>
      </Helmet>
      <AppLayoutProvider
        value={{
          mainNode,
        }}
      >
        <Header
          onLogout={handleLogout}
          autorized={authService.isAuthorized}
          location={filterService.location.name}
          cartCount={cartService.count}
        />
        <main
          ref={mainNode}
          className={cn('flex relative', {
            'map-wrapper': pathname === LINKS.MAP,
            'min-h-screen': pathname !== LINKS.MAP,
          })}
        >
          <Suspense fallback={<Spinner size="3xl" className="text-primary-500 mx-auto my-10" />}>
            <Outlet />
          </Suspense>
        </main>
        {pathname !== LINKS.MAP && (
          <Footer handleToTop={handleToTop} className={cn({ 'pb-54px': mode === ScreenMode.mobile })} />
        )}
        {mode === ScreenMode.mobile && <NavMenuBar />}
      </AppLayoutProvider>
    </div>
  )
})
