import { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { Input } from '@platform/ui'
import { FieldTextInterface } from '@platform/core/dist/modules/product/domain/fields/models'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldText: FC<Detail> = observer(({ id }) => {
  const { value, changeValue, maxLength, required, label, errorText, isValid, isNumber, decimalCount, description } =
    createProduct.fields.find((item) => item.id === id) as FieldTextInterface

  return (
    <div className="pt-2 space-y-2">
      <Label
        required={required}
        label={label}
        size="sm"
        textStyle="font-medium text-black text-sm"
        tooltipText={description}
      />
      {isNumber ? (
        <Input.Number
          value={value}
          maxLength={maxLength}
          onChange={changeValue}
          error={!isValid}
          isDecimal={!!decimalCount}
          decimalCount={decimalCount}
          notMinus
        />
      ) : (
        <Input value={value} maxLength={maxLength} onChangeValue={changeValue} error={!isValid} />
      )}

      <div className="mt-2 text-invalid">{errorText}</div>
    </div>
  )
})
