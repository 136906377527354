import { useState, useEffect, useCallback } from 'react'
import { ParamsRecommendedProducts } from '@platform/core/dist/api/v1/product'
import { ProductSimplifiedDTO } from '@platform/core/dist/modules/product/infra/dtos/ProductSimplifiedDTO'

import { core } from '@src/core'

const { productService } = core

export interface UseRecommendedProductsProps {
  params: ParamsRecommendedProducts
}

export const useRecommendedProducts = ({ params = {} }: UseRecommendedProductsProps) => {
  const [status, setStatus] = useState<Status>('pending')
  const [products, setProducts] = useState<ProductSimplifiedDTO[]>([])
  const [isLast, setIsLast] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    setStatus('loading')
    productService
      .fetchRecommendedProducts({ ...params, page })
      .then((res) => {
        if (res.isSuccess) {
          const pagination = res.getValue()
          setProducts((state) => {
            return page === 0 ? pagination.content : [...state, ...pagination.content]
          })
          setIsLast(pagination.last)
        }
      })
      .then(() => setStatus('loaded'))
      .catch(() => setStatus('failed'))
  }, [params.size, params.sort, params.sortColumn, params.searchName, page])

  const loadNextPage = useCallback(() => setPage((state) => state + 1), [])

  return {
    requestStatus: status,
    products,
    isLastPage: isLast,
    page,
    setPage,
    loadNextPage,
  }
}
