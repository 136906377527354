import { FC, useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Title, Checkbox } from '@platform/ui'
import type { ProductPayment } from '@platform/core/dist/models/product'

import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'

import { FilterSearchParam } from '../types'
import { PaymentMethodFilterProps } from './types'

const { productService } = core
const { PAYMENT_METHOD } = FilterSearchParam

export const PaymentMethodFilter: FC<PaymentMethodFilterProps> = observer(({ setSearchParam, deleteSearchParam }) => {
  const [list, setList] = useState<ProductPayment[]>([])
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    productService.getPaymentProductList().then((res) => {
      if (res.isSuccess) {
        setList(res.getValue())
      }
    })
  }, [])

  useEffect(() => {
    if (!selected.length) {
      deleteSearchParam(PAYMENT_METHOD)
      return
    }
    setSearchParam(PAYMENT_METHOD, selected.join(','))
  }, [selected])

  const handleDeliveryChange = useCallback((checked: boolean, value: string) => {
    setSelected((state) => {
      let newSelected = [...state]
      if (checked) {
        newSelected.push(value)
      } else {
        newSelected = newSelected.filter((item) => item !== value)
      }

      return newSelected
    })
  }, [])

  return (
    <Flow directory="col">
      <Title size="md" textClassName="font-medium">
        Способ оплаты
      </Title>
      <Flow directory="col" space={3.5} className="pt-18px">
        {list.map((item) => (
          <Checkbox
            onChangeChecked={handleDeliveryChange}
            key={item.id}
            isChecked={selected.includes(String(item.id))}
            value={String(item.id)}
          >
            <span className="mr-1">{item.name}</span>
          </Checkbox>
        ))}
      </Flow>
    </Flow>
  )
})
