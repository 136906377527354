import { Flow } from '@src/components/common/flow'
import { FC, useCallback, useEffect, useState } from 'react'
import { Input, Title } from '@platform/ui'

import { FilterSearchParam } from '../types'
import { PriceInputFilterProps } from './types'

const { START_PRICE, END_PRICE } = FilterSearchParam

export const PriceInputFilter: FC<PriceInputFilterProps> = ({ setSearchParam, deleteSearchParam }) => {
  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')

  useEffect(() => {
    if (!minValue) {
      deleteSearchParam(START_PRICE)
      return
    }
  }, [minValue])

  useEffect(() => {
    if (!maxValue) {
      deleteSearchParam(END_PRICE)
      return
    }
  }, [maxValue])

  const handleMinValueBlur = useCallback(
    (value: string, numValue: number) => {
      if (value) {
        setSearchParam(START_PRICE, String(numValue))
      }
    },
    [setSearchParam, deleteSearchParam]
  )

  const handleMaxValueBlur = useCallback(
    (value: string, numValue: number) => {
      if (value) {
        setSearchParam(END_PRICE, String(numValue))
      }
    },
    [setSearchParam, deleteSearchParam]
  )

  return (
    <Flow directory="col">
      <Title size="md">Цена, товара</Title>
      <Flow full className="justify-between" space={2.5} align="center">
        <Input.Number
          onChange={setMinValue}
          value={minValue}
          placeholder="Цена от"
          size="lg"
          onBlur={handleMinValueBlur}
          notMinus
        />
        <Input.Number
          onChange={setMaxValue}
          value={maxValue}
          placeholder="Цена до"
          size="lg"
          onBlur={handleMaxValueBlur}
          notMinus
        />
      </Flow>
    </Flow>
  )
}
