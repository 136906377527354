import { createContext } from 'react'

export type FieldCreateProduct = {
  handleScroll: () => void
  key: string
  orderMarkup: number
}

export interface createProductFieldsProps {
  isEdit: boolean
  fields: FieldCreateProduct[]
  addFields: (fields: FieldCreateProduct[]) => void
  clearFields: () => void
  fieldsDetailed: FieldCreateProduct[]
  updateFieldsDetaileds: (fields: FieldCreateProduct[]) => void
}

export const CreateProductContext = createContext<createProductFieldsProps | null>(null)
