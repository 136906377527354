import axios from 'axios'
import { Core, ScreenMode } from '@platform/core'

if (process.env.NODE_ENV === 'development') {
  const https = require('https')
  axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
}

const core = new Core()

export { core, ScreenMode }
