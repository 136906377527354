import { FC, useCallback } from 'react'
import { Button } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { Icon } from '@platform/ui'
import cn from 'classnames'

import { PaginationProps } from './types'

export const Pagination: FC<PaginationProps> = ({ totalPages, currentPage, setPage }) => {
  const handleSetPage = useCallback(
    (index: number) => {
      if (index < 0 || index > totalPages) return
      setPage(index)
    },
    [setPage, totalPages]
  )

  if (!totalPages) {
    return null
  }

  return (
    <div className="w-full flex justify-center items-center py-8">
      <Flow space={4}>
        <Button
          size="xs"
          variant="link"
          disabled={currentPage === 0}
          className="rounded-full border-xs border-neutral-900 p-2"
          icon={<Icon.Arrow position="left" color="black" />}
          onClick={() => handleSetPage(currentPage - 1)}
        ></Button>
        {[...Array(totalPages)].map((_, index) => (
          <Button
            key={index}
            variant="link"
            size="xs"
            className={cn({ 'text-black': index === currentPage, 'text-neutral-400': index !== currentPage })}
            onClick={() => handleSetPage(index)}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          size="xs"
          variant="link"
          disabled={currentPage === totalPages || totalPages === 1}
          className="rounded-full border-xs border-neutral-900 p-2"
          icon={<Icon.Arrow position="right" color="black" />}
          onClick={() => handleSetPage(currentPage + 1)}
        ></Button>
      </Flow>
    </div>
  )
}
