import React from 'react'
import cn from 'classnames'
import { Avatar, Rating } from '@platform/ui'
import { Flow } from '@src/components/common/flow'

import { Replay } from './reply'
import { CommentProps } from './types'

export const Comment: React.FC<CommentProps> = (props) => {
  const { className, name, src, date, rating, feedback, comments = [], images = [] } = props
  return (
    <div className={cn(className, 'flex flex-row w-full space-x-2')}>
      <Avatar src={src} />
      <div className="flex flex-col space-y-5 text-neutral-900">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-4 items-end">
            <span className="text-lg font-bold">{name}</span>
            <span className="text-md text-neutral-400">{date}</span>
          </div>
          <Rating value={rating} />
          <Flow space={2}>
            {images.map((img) => (
              <img key={img.id} src={img.src} className="object-contain h-14 w-16 rounded-md" />
            ))}
          </Flow>
        </div>
        <div className="flex flex-col space-y-3">
          <div className="text-lg text-neutral-500">
            Достоинства:
            <div className="text-md text-neutral-800" dangerouslySetInnerHTML={{ __html: feedback.positive ?? '' }} />
          </div>
          <div className="text-lg text-neutral-500">
            Недостатки:
            <div className="text-md text-neutral-800" dangerouslySetInnerHTML={{ __html: feedback.negative ?? '' }} />
          </div>
          <div className="text-lg text-neutral-500">
            Комментарий:
            <div className="text-md text-neutral-800" dangerouslySetInnerHTML={{ __html: feedback.text ?? '' }} />
          </div>
        </div>
        <div className="w-full flex flex-col space-y-6">
          {comments.map((comment, index) => {
            const { name, date, text, src } = comment
            return <Replay key={index} name={name} date={date} text={text} src={src} />
          })}
        </div>
      </div>
    </div>
  )
}
