import { ProductStateDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { TypeTabs, StateTabs, SettingsTabs } from './types'

export const productTypeTabMap: Record<TypeTabs, ProductTypeDTO | undefined> = {
  [TypeTabs.all]: undefined,
  [TypeTabs.buy]: ProductTypeDTO.buy,
  [TypeTabs.sell]: ProductTypeDTO.sell,
  [TypeTabs.exchange]: ProductTypeDTO.exchange,
  [TypeTabs.auction]: ProductTypeDTO.auction,
}

export const productStateTabMap: Record<StateTabs, ProductStateDTO> = {
  [StateTabs.active]: ProductStateDTO.active,
  [StateTabs.archive]: ProductStateDTO.archive,
  [StateTabs.waitModeration]: ProductStateDTO.waitModeration,
  [StateTabs.rejected]: ProductStateDTO.rejected,
  [StateTabs.waitPayment]: ProductStateDTO.waitPayment,
}

export const settingsTabsMap = [
  {
    index: SettingsTabs.profile,
    title: 'Профиль',
  },
  {
    index: SettingsTabs.organizationData,
    title: 'Данные организации',
  },
  {
    index: SettingsTabs.notifications,
    title: 'Уведомления',
  },
  {
    index: SettingsTabs.security,
    title: 'Безопасность',
  },
]

export const productStateTabsMap = [
  {
    index: StateTabs.active,
    title: 'Активные',
  },
  {
    index: StateTabs.waitPayment,
    title: 'Ждут оплаты',
  },
  {
    index: StateTabs.waitModeration,
    title: 'На модерации',
  },
  {
    index: StateTabs.rejected,
    title: 'Отклоненные',
  },
  {
    index: StateTabs.archive,
    title: 'Архив',
  },
]
