import cn from 'classnames'
import { FC } from 'react'

import { TooltipInfo } from '@src/components/common/tooltip-info'
import { LabelProps } from './types'

export const Label: FC<LabelProps> = ({ label, hint, required, size = 'lg', textStyle, tooltipText }) => {
  const clsLabel = cn(`flex items-start text-${size}`)
  return (
    <div className="flex flex-col space-y-1">
      <div className={clsLabel}>
        <span className={textStyle ? textStyle : ''}>{label}</span>
        {required && <span className="pl-1 text-negative-500">*</span>}
        {!!tooltipText && (
          <span className="pl-1">
            <TooltipInfo labelText={tooltipText} />
          </span>
        )}
      </div>
      {hint && <span className="text-neutral-400">{hint}</span>}
    </div>
  )
}
