import { FC, useCallback, useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Button, Icon, Drawer } from '@platform/ui'
import { Logo } from '@src/components/common/logo'
import { Flow } from '@src/components/common/flow'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { HeaderProps } from './types'
import { linkMap } from './constants'

const isServer = process.env.APP_ENV === 'server'

export const Header: FC<HeaderProps> = ({ autorized, location }) => {
  const [menuState, setMenuState] = useState<boolean>(false)

  const handleToEntry = useNavigationApp({ to: LINKS.AUTH_SIGNIN })
  const handleToByCart = useNavigationApp({ to: LINKS.BY_CART })

  const openMenu = useCallback(() => setMenuState(true), [])
  const closeMenu = useCallback(() => setMenuState(false), [])

  return (
    <header className="bg-primary-500 text-white z-header w-full content-main py-1.5 text-xss">
      <Flow align="center" className="justify-between">
        <Flow align="center" directory="col">
          <Button icon={<Icon.Menu />} color="white" size="xl" variant="link" onClick={openMenu} />
          <span>Меню</span>
        </Flow>
        <Logo className="h-8" />
        {autorized ? (
          <Flow align="center" directory="col">
            <Button icon={<Icon.ShoppingCartFill />} color="white" size="xl" variant="link" onClick={handleToByCart} />
            <span>Корзина</span>
          </Flow>
        ) : (
          <Flow align="center" directory="col">
            <Button icon={<Icon.Entry />} color="white" size="xl" variant="link" onClick={handleToEntry} />
            <span>Вход</span>
          </Flow>
        )}
      </Flow>
      {!isServer && (
        <Drawer isOpen={menuState} onClose={closeMenu}>
          <div className="p-2 text-neutral-500">
            <Flow className="justify-end">
              <Button
                icon={<Icon.Close size="lg" />}
                variant="link"
                className="text-xss"
                onClick={closeMenu}
                iconPosition="right"
              >
                Закрыть
              </Button>
            </Flow>
            <Flow directory="col" space={4} className="mt-4">
              {/* <Flow directory="col" space={1}>
                  <span className="font-medium">Местоположение:</span>
                  <Button
                    icon={<Icon.Location />}
                    size="sm"
                    color="text-neutral-500"
                    variant="outline"
                    className="justify-start"
                  >
                    {location ?? 'Город / Нас.пункт'}
                  </Button>
                </Flow> */}
              <Flow directory="col" space={1}>
                <span className="font-medium">Меню:</span>
                <Flow directory="col">
                  {linkMap.map(({ name, to }, key) => (
                    <Link key={key} to={to} onClick={closeMenu}>
                      <div
                        className={cn('border-t border-neutral-100 p-2.5 pl-7', {
                          'border-b': key === linkMap.length - 1,
                        })}
                      >
                        <span className="text-sm text-black font-medium">{name}</span>
                      </div>
                    </Link>
                  ))}
                </Flow>
              </Flow>
            </Flow>
          </div>
        </Drawer>
      )}
    </header>
  )
}
