import React, { useEffect, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { notification } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'

import { AuthSocialProps } from './types'

const { authService, userService, cartService } = core
const { authSocialService } = authService
const { userProfile } = userService

export const Social: React.FC<AuthSocialProps> = ({ defaultLinkPath = '', nextPageLink = LINKS.PROFILE_HOME }) => {
  const navigate = useNavigate()

  const signInWithSocial = async () => {
    let message = 'Ошибка'
    let description: string | ReactNode[] = 'Возникла непредвиденная ошибка'

    const tokenResult = await authSocialService.parseUrl()

    if (typeof tokenResult === 'undefined') {
      return
    }

    if (tokenResult.isFailure) {
      notification.error({ message, description: tokenResult.getError().toString() })
      navigate(defaultLinkPath + LINKS.AUTH_SIGNIN)
      return
    }

    const authResult = await authService.authorized()

    if (authResult.isFailure) {
      notification.error({ message, description })
      navigate(defaultLinkPath + LINKS.AUTH_SIGNIN)
      return
    }

    userProfile.setState(authResult.getValue())

    const cartResult = await cartService.getUserCart()
    if (cartResult.isFailure) return

    notification.success({ message: 'Авторизация выполнена успешно' })
    navigate(nextPageLink)
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    signInWithSocial()
  }, [])

  return null
}
