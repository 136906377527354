import { useEffect, useCallback } from 'react'
import { ParamsUserFavorite } from '@platform/core'

import { core } from '@src/core'

const { productService } = core

const { userFavoriteProducts } = productService

export interface useFavoritesProductsProps {
  params?: ParamsUserFavorite
}

export const useFavoritesProducts = ({ params = {} }: useFavoritesProductsProps) => {
  useEffect(() => {
    productService.fetchUserFavoritesProducts(params)
  }, [params?.size, params?.sort, params?.sortColumn])

  const loadNextPage = useCallback(() => {
    productService.userFavoriteProducts.setState({ page: productService.userFavoriteProducts.page + 1 })
    productService.fetchUserFavoritesProducts(params)
  }, [])

  return {
    products: userFavoriteProducts.list,
    requestStatus: userFavoriteProducts.status,
    isLastPage: userFavoriteProducts.isLast,
    page: userFavoriteProducts.page,
    loadNextPage,
    totalPages: userFavoriteProducts.totalPages,
  }
}
