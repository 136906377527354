import React from 'react'
import cn from 'classnames'
import { Avatar } from '@platform/ui'

import { ReplyProps } from './types'

export const Replay: React.FC<ReplyProps> = (props) => {
  const { className, name, date, text, src } = props
  return (
    <div className={cn(className, 'flex flex-row space-x-2')}>
      <Avatar src={src} />
      <div className="flex flex-col space-y-0">
        <div className="flex flex-row space-x-4 items-end">
          <span className="text-sm font-bold">{name}</span>
          <span className="text-sm text-neutral-400">{date}</span>
        </div>
        <div className="text-md">{text}</div>
      </div>
    </div>
  )
}
