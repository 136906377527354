import React, { useEffect, forwardRef, useCallback } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Button, utils, Icon } from '@platform/ui'

import { Flow } from '@src/components/common/flow'

import { SectionProps } from './types'

export const Section = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {
  const {
    className,
    title,
    titleClassName,
    titleWrapperClassName,
    titleContainerClassName,
    children,
    fixed,
    tag = 'h2',
    sizeTitle = '1xl',
    weight = 'medium',
    linkTitle,
    linkPath = '/',
    onBackClick,
  } = props
  const Title = tag
  const node = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!node.current || !fixed) {
      return
    }

    const clientRect = node.current.getBoundingClientRect()
    node.current.style.minHeight = `${clientRect.height}px`
  }, [node.current, fixed])

  return (
    <section ref={utils.mergeRefs(node, ref)} className={className}>
      <Flow
        className={cn({
          [`${titleContainerClassName} flex-col md:flex-row items-start md:items-baseline md:space-x-4`]: title,
        })}
      >
        <Flow align="center" className={titleWrapperClassName}>
          {onBackClick && (
            <Button variant="link" onClick={onBackClick}>
              <Icon.ArrowLong position="left" color="black" size="1xl" />
            </Button>
          )}
          {title && <Title className={cn(titleClassName, `text-${sizeTitle} font-${weight}`)}>{title}</Title>}
        </Flow>
        {linkTitle && (
          <Link to={linkPath}>
            <Button
              variant="link"
              color="primary"
              textClassName="underline font-normal leading-xl text-sm md:text-md"
              iconPosition="right"
              icon={<Icon.ArrowLong position="right" />}
            >
              {linkTitle}
            </Button>
          </Link>
        )}
      </Flow>
      {children}
    </section>
  )
})
