import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import NotFound from '../not-found'
import { Layout } from './container/layout'
import { Moderation } from './moderation'
import { ProductFormation } from '@src/container/product-formation'
import { Service } from './service'
import { Payment } from './payment'
import { Auth } from './auth'
import { useEffect } from 'react'

const { CREATE, AUTH } = ROUTES

const { createProductService } = core
const { createProduct } = createProductService

const CreatePages = observer(() => {
  useEffect(() => {
    return () => {
      createProduct.reset()
      createProductService.resetCreatedProduct()
    }
  }, [])

  return (
    <ScrollToTop>
      <Routes>
        <Route element={<Layout />}>
          <Route path={AUTH.INDEX} element={<Auth />} />
          <Route path={CREATE.PRODUCT.INDEX} element={<ProductFormation />} />
          <Route path={CREATE.SERVICE.INDEX} element={<Service />} />
          <Route path={CREATE.MODERATION.INDEX} element={<Moderation />} />
        </Route>
        <Route path={CREATE.PAYMENT.INDEX} element={<Payment />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
})
export default CreatePages
