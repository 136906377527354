import { FC } from 'react'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import { Home } from './home'

const HomePages: FC = () => {
  return (
    <ScrollToTop>
      <Home />
    </ScrollToTop>
  )
}

HomePages.displayName = 'HomePages'

export default HomePages
