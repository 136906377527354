import { LINKS } from '@src/routes'
import { FooterSection } from './types'

const sections: FooterSection[] = [
  {
    title: 'Покупателям',
    links: [
      {
        title: 'Регистрация',
        to: LINKS.AUTH_SIGNUP,
      },
      {
        title: 'Оплата и доставка',
        to: '#',
      },
      {
        title: 'Гарантия возврата денег',
        to: '#',
      },
      {
        title: 'Участие в аукционе',
        to: '#',
      },
      {
        title: 'О сервисе',
        to: '#',
      },
      {
        title: 'Обратная связь',
        to: '#',
      },
      {
        title: 'Подписка',
        to: '#',
      },
    ],
  },
  {
    title: 'Продавцам',
    links: [
      {
        title: 'Личный кабинет',
        to: LINKS.PROFILE_HOME,
      },
      {
        title: 'Продажа',
        to: '#',
      },
      {
        title: 'Аукцион',
        to: '#',
      },
    ],
  },
  {
    title: 'Сотрудничество',
    links: [
      {
        title: 'О компании',
        to: '#',
      },
      {
        title: 'Новости',
        to: '#',
      },
      {
        title: 'Вакансии',
        to: '#',
      },
      {
        title: 'Рекламируйте вместе с нами',
        to: '#',
      },
      {
        title: 'Правила',
        to: '#',
      },
    ],
  },
  {
    title: 'Сообщество',
    links: [
      {
        title: 'Объявления',
        to: '#',
      },
      {
        title: 'Благотворительность',
        to: '#',
      },
    ],
  },
]

export default sections
