import { Title } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { FC } from 'react'
import { parseMilliseconds } from '@src/utils/helpers'

import { NotificationProps } from './types'

export const Notification: FC<NotificationProps> = ({ timeAt, title, message }) => {
  return (
    <Flow directory="col" space={2.5}>
      <div className="flex-col md:flex-row gap-x-2.5 gap-y-2.5">
        <Title color="neutral" weight="semibold" colorNumber={900}>
          {title}
        </Title>
        <span className="text-sm text-neutral-400 font-semibold">{parseMilliseconds(timeAt)}</span>
      </div>
      <span>{message}</span>
    </Flow>
  )
}
