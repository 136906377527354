import { FC, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Input, Option } from '@platform/ui'
import { isObject } from '@src/utils/helpers'
import { FilterSearchParam } from '../types'

import { AddressFilterProps } from './types'

const { autocompliteService } = core

const autocompliteAddress = (query: string) =>
  autocompliteService.address({ query }).then((resultOrError) => {
    if (resultOrError.isSuccess) {
      return resultOrError.getValue()
    }
    return []
  })

export const AddressFilter: FC<AddressFilterProps> = observer(({ setSearchParam }) => {
  const [address, setAddress] = useState<Option>()

  const handleLocationChange = useCallback(
    (option: Option) => {
      if (isObject(option)) {
        setAddress(option.label || '')
        const { geo_lat, geo_lon } = option
        setSearchParam(FilterSearchParam.CENTER, `${geo_lat},${geo_lon}`)
      }
    },
    [setSearchParam]
  )

  return (
    <Input.Autocomplete
      placeholder="Введите регион, область..."
      size="lg"
      value={address}
      getOptions={autocompliteAddress}
      onChange={handleLocationChange}
    />
  )
})
