import { FC, useCallback, useState, useRef } from 'react'
import { Placemark as PlacemarkLib } from '@pbe/react-yandex-maps'
import ymaps from 'yandex-maps'

import { PlacemarkProps } from './types'

export const Placemark: FC<PlacemarkProps> = ({ onClick, ...rest }) => {
  const [ymapsLoad, setYmapsLoad] = useState(false)
  const ymapsRef = useRef<typeof ymaps | null>(null)

  const handleClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  const handleLoadYmaps = useCallback((api: typeof ymaps) => {
    ymapsRef.current = api
    setYmapsLoad(true)
  }, [])

  return (
    <PlacemarkLib
      onClick={handleClick}
      onLoad={handleLoadYmaps}
      options={{
        iconLayout: ymapsLoad
          ? ymapsRef.current?.templateLayoutFactory.createClass(
              `
              <div class="absolute">
                <span class="relative -left-6 -top-8">
                  <svg fill="#208FF1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="3em" height="3em" >
                  <path fill-rule="evenodd" d="M8 15c1.5 0 5-6.57 5-9.36C13 3.08 10.76 1 8 1S3 3.08 3 5.64C3 8.43 6.5 15 8 15zm0-7.5a2 2 0 100-4 2 2 0 000 4z"></path>
                  </svg>
                </span>
              </div>
              `
            )
          : undefined,
      }}
      {...rest}
    />
  )
}
