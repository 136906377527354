import { FC, useMemo, useEffect } from 'react'
import { core } from '@src/core'
import { Breadcrumbs as BreadcrumbsComponent } from '@src/components/common/breadcrumbs'

import { BreadcrumbsProps } from './types'
import { observer } from 'mobx-react-lite'

const { categoryService } = core
const { categories } = categoryService

export const Breadcrumbs: FC<BreadcrumbsProps> = observer(({ categoryId, productTitle }) => {
  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const items = useMemo(() => {
    return [
      { href: '/', children: 'Главная' },
      ...categories.getListParentsById(categoryId).map((item) => ({
        href: `/category/${item.code}--${item.id}`,
        children: item.name,
      })),
      { children: productTitle },
    ]
  }, [categoryId, productTitle, categories.list])

  if (categoryService.isEmpty) return null

  return <BreadcrumbsComponent items={items} />
})
