import { FC, useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Button, Spinner } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'

import { DeatailedInfo } from './detailed-info'
import { ImageUpload } from './image-upload'
import { Location } from './location'
import { Category } from './category'
import { Description } from './description'
import { Publish } from './publish'
import { Logistics } from './logisitics'
import { CreateProductContext, FieldCreateProduct } from './context'

import { ProductFormationProps } from './types'

const { authService, createProductService } = core
const { createProduct } = createProductService

export const ProductFormation: FC<ProductFormationProps> = observer(({ isEdit }) => {
  const [fields, setFields] = useState<FieldCreateProduct[]>([])
  const [fieldsDetailed, setFieldsDetailed] = useState<FieldCreateProduct[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!authService.isAuthorized) {
      navigate(LINKS.CREATE_AUTH)
      return
    }

    return () => {
      setFields([])
      setFieldsDetailed([])
    }
  }, [])

  const addFields = useCallback((newfields: FieldCreateProduct[]) => {
    return setFields((fields) => [...fields, ...newfields])
  }, [])

  const updateFieldsDetaileds = useCallback((newfields: FieldCreateProduct[]) => {
    return setFieldsDetailed(newfields)
  }, [])

  const handleClick = () => {
    core.createProductService.sendProduct().then((notValidFields) => {
      if (notValidFields) {
        const allFields = [...fields, ...fieldsDetailed].sort((a, b) => a.orderMarkup - b.orderMarkup)

        let fieldFound = false
        let index = 0

        while (!fieldFound && index < allFields.length) {
          if (notValidFields.includes(allFields[index].key)) {
            allFields[index].handleScroll()
            fieldFound = true
          }
          index++
        }
        return
      }

      if (isEdit) {
        navigate(LINKS.PROFILE_HOME)
        return
      }

      navigate(LINKS.CREATE_MODERATION)
    })
  }

  const clearFields = useCallback(() => setFields([]), [])

  return (
    <CreateProductContext.Provider
      value={{ fields, addFields, clearFields, fieldsDetailed, updateFieldsDetaileds, isEdit: !!isEdit }}
    >
      <Flow directory="col" className="w-3/4 pt-88px" space={'88px'}>
        <Category />
        {createProduct.isFetchFields && (
          <div className="flex justify-center">
            <Spinner size="2xl" className="text-primary-500" />
          </div>
        )}
        {!!createProduct.category.value && (
          <>
            <ImageUpload />
            <Description />
            <Location />
            <Logistics />
            {!!createProduct.fields.length && <DeatailedInfo />}
            <Publish />
            <div className="py-10 flex justify-start">
              <Button
                size="md"
                color="positive"
                className="px-16"
                onClick={handleClick}
                loading={createProduct.isRequestCreate}
              >
                {isEdit ? 'Сохранить' : 'Продолжить'}
              </Button>
            </div>
          </>
        )}
      </Flow>
    </CreateProductContext.Provider>
  )
})
