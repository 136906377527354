import { Category } from '@platform/core/dist/modules/catalog/domain/category'
import { MenuTreeNode } from '@platform/ui'

export function categoryToMenuTree(categories: Category[]): MenuTreeNode[] {
  const stack = [...categories]
  const results: MenuTreeNode[] = []

  while (stack.length) {
    const { childs, ...category } = stack.pop() as Category
    const menuTreeNode: MenuTreeNode = {
      id: String(category.id),
      label: category.name,
      children: categoryToMenuTree(childs),
    }

    results.push(menuTreeNode)
  }

  return results
}
