import { FC } from 'react'
import { CategoryIcon } from '@src/components/common/category-icon'

import { CategoryNavigationItemProps } from './types'

export const CategoryNavigationItem: FC<CategoryNavigationItemProps> = ({ data, onClick }) => {
  const handleClick = () => onClick(data)
  return (
    <span className="flex gap-x-2.5 items-center">
      <CategoryIcon code={data.code} className="text-md text-primary-500" />
      <span className="hover:underline text-black text-md leading-5" onClick={handleClick}>
        {data.name}
      </span>
    </span>
  )
}
