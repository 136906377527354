import React from 'react'
import { Button } from '@platform/ui'

import { Flow } from '@src/components/common/flow'
import { Field } from '@src/components/common/field'
import { core, ScreenMode } from '@src/core'
import { Address } from '@src/components/common/address'

import { Extra } from '../extra'
import { TagList } from '../tag-list'
import { DetailsProps } from './types'

const { screen } = core.layoutService

export const Details: React.FC<DetailsProps> = ({
  format,
  properties,
  onClickDescription,
  vip,
  discount,
  deliveryList,
  paymentList,
  className,
  address,
}) => {
  return (
    <Flow directory="col" className={className} align="start" space={8}>
      {screen.mode === ScreenMode.desktop && <TagList format={format} vip={vip} discount={discount} />}
      <Flow directory="col" className="text-md" space={'18px'} full>
        {properties.map(({ name, id, value, measure, description }) => (
          <Field
            key={id}
            label={name}
            value={`${value}${measure ?? ''}`}
            labelClassName="text-sm"
            valueClassName="font-medium"
            description={description}
          />
        ))}
      </Flow>
      <Address address={address} />
      <Extra
        deliveryList={deliveryList}
        paymentList={paymentList}
        direction={screen.mode === ScreenMode.desktop ? 'col' : 'row'}
      />
      <Button variant="link" color="primary" onClick={onClickDescription}>
        Перейти к описанию
      </Button>
    </Flow>
  )
}
