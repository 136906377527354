import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { core, ScreenMode } from '@src/core'

import { Breadcrumbs } from '../breadcrumbs'
import { LINKS } from '@src/routes'
import { observer } from 'mobx-react-lite'

const { layoutService, authService } = core
const { screen } = layoutService

export const Layout: FC = observer(() => {
  const navigate = useNavigate()

  useEffect(() => {
    if (authService.isAuthorized) {
      navigate(LINKS.HOME)
    }
  }, [])

  return (
    <div className="content-main pt-7">
      {screen.mode !== ScreenMode.mobile && <Breadcrumbs />}
      <div className="mx-auto mt-7 md:mt-88px">
        <Outlet />
      </div>
    </div>
  )
})
