export enum FilterSearchParam {
  EXTRA = 'extra',
  PRICE = 'price',
  START_PRICE = 'startPrice',
  END_PRICE = 'endPrice',
  CATEGORIES = 'categories',
  DELIVERY = 'delivery',
  PAYMENT_METHOD = 'paymentMethod',
  CENTER = 'center',
  USER = 'user',
  EXCHANGE_CATEGORY = 'exchangeCategory',
  PRODUCT_TYPE = 'productType',
  SORT = 'sort',
}
