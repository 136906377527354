import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { ColorNameType, ColorNumberType } from '@platform/ui/src/types'

export const badgeProductMap: Record<
  string,
  {
    number: ColorNumberType
    color: ColorNameType
  }
> = {
  [ProductTypeDTO.auction]: {
    number: 400,
    color: 'attention',
  },
  [ProductTypeDTO.buy]: {
    number: 600,
    color: 'attention',
  },
  [ProductTypeDTO.sell]: {
    number: 500,
    color: 'primary',
  },
  [ProductTypeDTO.exchange]: {
    number: 600,
    color: 'positive',
  },
}
