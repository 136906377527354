import React from 'react'
import { Grid, GridCell, Title } from '@platform/ui'

export interface IEmptyProps {}

export const Empty: React.FC<IEmptyProps> = () => {
  return (
    <Grid columnsNumber={1}>
      <GridCell>
        <Title size="2xl">Корзина пуста</Title>
      </GridCell>
    </Grid>
  )
}

Empty.displayName = 'Empty'
