import { FC } from 'react'
import { ProductList } from '@src/components/desktop/product-list'
import { useRecommendedProducts } from '@src/hooks/use-recommended-products'

import { RecommendedProductsProps } from './types'

export const RecommendedProducts: FC<RecommendedProductsProps> = ({ search }) => {
  const { requestStatus, products } = useRecommendedProducts({
    params: { size: 4, searchName: search },
  })

  return <ProductList products={products} requestStatus={requestStatus} />
}
