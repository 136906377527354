import React from 'react'
import { Breadcrumbs as UIBreadcrumbs, BreadcrumbItem } from '@platform/ui'

import { BreadcrumbsProps } from './types'

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { items, className } = props
  return (
    <UIBreadcrumbs className={className}>
      {items.map((item, index) => {
        const { tag, href, navsrc, children } = item
        return (
          <BreadcrumbItem key={`${index}${href}`} tag={tag} href={href} navsrc={navsrc}>
            {children}
          </BreadcrumbItem>
        )
      })}
    </UIBreadcrumbs>
  )
}
