import { Icon } from '@platform/ui'

export const categoryIconMap: Record<string, any> = {
  'agrarnyj-rynok': Icon.Agro,
  'nedvizhimost': Icon.Realty,
  'obschepit': Icon.Obshepit,
  'transport': Icon.Car,
  'elektrika': Icon.Electronics,
  'meditsina': Icon.Medicine,
  'sport-i-turizm': Icon.SportAndTourism,
  'spetsmagaziny': Icon.Specshop,
  'produkty-pitaniya': Icon.IconProduktyPitaniya,
  'avtoservis': Icon.AutoService,
  'obrazovanie': Icon.Education,
  'krasota-i-zdorove': Icon.BeautyHealth,
  'odezhda': Icon.Cloth,
  'remont-strojka': Icon.Remont,
  'promyshlennye-tovary': Icon.Industry,
  'biznes-dlya-biznesa': Icon.B2B,
  'vlast': Icon.Power,
  'rabota': Icon.Work,
  'uslugi': Icon.Services,
  'drugoe': Icon.Other,
}
