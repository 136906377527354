import { FC, useEffect, useState, useCallback } from 'react'
import { useParams, Outlet, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Spinner, Button, Icon } from '@platform/ui'
import { ProductTypeDTOName } from '@platform/core/dist/modules/product/infra/dtos'

import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'
import NotFound from '@src/pages/not-found'

import { Breadcrumbs } from '../breadcrumbs'

const { productService, layoutService } = core
const { product } = productService
const { screen } = layoutService

export const OfferLayout: FC = observer(() => {
  const { id } = useParams()
  const productId = Number(id)

  const navigate = useNavigate()

  const [isNotFound, setIsNotFound] = useState(false)

  const handleToProduct = useCallback(() => navigate(`/product/${product.data?.format}/${id}`), [id])

  useEffect(() => {
    productService.fetchProduct(productId).then((resultOrError) => {
      if (resultOrError.isFailure) {
        setIsNotFound(true)
      }
    })
  }, [productId])

  useEffect(() => {
    return () => {
      productService.resetProduct()
    }
  }, [])

  if (isNotFound) {
    return <NotFound />
  }

  if (product.isLoading) {
    return (
      <Flow className="content-main flex-1 justify-center" align="center">
        <Spinner size="2xl" className="text-primary-500" />
      </Flow>
    )
  }

  if (!product.data) return null

  return (
    <Flow className="content-main py-8" directory="col">
      {screen.mode === ScreenMode.desktop && (
        <Breadcrumbs categoryId={product.data.categoryId} productTitle={product.data.name} />
      )}
      <Button
        onClick={handleToProduct}
        variant="link"
        color="primary"
        className="pt-10"
        textClassName="text-lg"
        iconPosition="left"
        icon={<Icon.ArrowLong position="left" />}
      >
        Назад к объявлению
      </Button>
      <Outlet />
    </Flow>
  )
})
