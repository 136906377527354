import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'

import { Tariff } from './tariff'

const { userSubscriptionsService, createProductService } = core
const { tariffs } = userSubscriptionsService
const { createProduct } = createProductService

export const Tariffs: FC = observer(() => {
  useEffect(() => {
    userSubscriptionsService.fetchTariffs()
  }, [])

  if (tariffs.isLoading) {
    return null
  }

  return (
    <div className="flex flex-row flex-wrap xl:justify-between gap-x-10 xl:gap-x-0">
      {tariffs.list.map((item) => {
        return (
          <Tariff
            key={item.id}
            active={createProduct.tariffId === item.id}
            {...item}
            onClick={() => createProduct.setTariffId(item.id)}
          />
        )
      })}
    </div>
  )
})
