import { LINKS } from '@src/routes'
import { Icon } from '@platform/ui'

import { UserMenuItemProps } from './types'

export const menuConfig: Omit<UserMenuItemProps, 'isActive' | 'onClick'>[] = [
  {
    title: 'Объявления',
    icon: <Icon.Ad />,
    path: LINKS.PROFILE_HOME,
  },
  // {
  //   title: 'Сообщения',
  //   icon: <Icon.Comment />,
  //   path: LINKS.PROFILE_MESSENGER,
  // },
  {
    title: 'Закладки',
    icon: <Icon.Favorite />,
    path: LINKS.PROFILE_FAVORITES,
  },
  {
    title: 'Уведомления',
    icon: <Icon.Notification />,
    path: LINKS.PROFILE_NOTIFICATIONS,
  },
  {
    title: 'Настройки',
    icon: <Icon.Settings />,
    path: LINKS.PROFILE_SETTINGS,
  },
]
