import { Routes, Route } from 'react-router-dom'

import { core } from '@src/core'
import { Code } from '@src/container/auth/code'
import { Social } from '@src/container/auth/social'
import { SingIn } from '@src/container/auth/sign-in'
import { SingUp } from '@src/container/auth/sign-up'
import { VerifyPhone } from '@src/container/auth/verify-phone'
import { ScrollToTop } from '@src/components/common/scrollToTop'
import { ROUTES, LINKS } from '@src/routes'

import { Layout } from './components/layout'
import NotFound from '../not-found'

const { AUTH } = ROUTES
const HOST = process.env.HOST
const PORT = process.env.PORT
core.authService.authSocialService.redirectUri = `${HOST}:${PORT}${LINKS.AUTH_SOCIAL}`

const Auth = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route element={<Layout />}>
          <Route path={AUTH.CODE.INDEX} element={<Code />} />
          <Route path={AUTH.SOCIAL.INDEX} element={<Social />} />
          <Route path={AUTH.SIGNIN.INDEX} element={<SingIn />} />
          <Route path={AUTH.SIGNUP.INDEX} element={<SingUp />} />
          <Route path={AUTH.VERIFY_PHONE.INDEX} element={<VerifyPhone />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

export default Auth
