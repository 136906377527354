import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DatePicker } from '@platform/ui'
import { FieldDateInterface } from '@platform/core/dist/modules/product/domain/fields/models'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { Flow } from '@src/components/common/flow'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldDate: FC<Detail> = observer(({ id }) => {
  const { changeValue, label, required, format, errorText, value, maxDate, description } = createProduct.fields.find(
    (item) => item.id === id
  ) as FieldDateInterface

  return (
    <Flow directory="col" className="pt-2" space={2}>
      <Flow className="justify-between">
        <Label
          required={required}
          label={label}
          size="sm"
          textStyle="font-medium text-black text-sm"
          tooltipText={description}
        />
        <DatePicker
          maxDate={maxDate}
          format={format}
          showOnlyYear={format === 'yyyy'}
          onChange={changeValue}
          initialDate={value}
          size="sm"
        />
      </Flow>
      <div className="text-invalid">{errorText}</div>
    </Flow>
  )
})
