import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { Input } from '@platform/ui'
import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'

const { createProduct } = core.createProductService
const { priceBuy, measure, quantityBuy } = createProduct

export const PublishBuy: FC = observer(() => {
  const handleChange = useCallback((value: string) => {
    priceBuy.changeValue(value)
  }, [])

  const handleQuantityChange = useCallback((value: string) => {
    quantityBuy.changeValue(value)
  }, [])

  return (
    <div className="space-y-11">
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label required={!!priceBuy.required} label={priceBuy.label} textStyle="font-medium text-black text-sm" />
        }
        errorText={priceBuy.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={priceBuy.value ?? ''}
            onChange={handleChange}
            className="w-44"
            size="lg"
            isDecimal
            error={!priceBuy.isValid}
          />
          <span className="ml-4 text-lg font-medium">₽</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label
            required={!!quantityBuy.required}
            label={quantityBuy.label}
            textStyle="font-medium text-black text-sm"
          />
        }
        errorText={quantityBuy.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={quantityBuy.value ?? ''}
            onChange={handleQuantityChange}
            className="w-44"
            size="lg"
            isDecimal
            notMinus
            error={!quantityBuy.isValid}
          />
          <span className="ml-4 text-lg font-medium">{(measure.value as FieldSelectOption)?.name || ''}</span>
        </div>
      </FormItem>
    </div>
  )
})
