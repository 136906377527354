import React from 'react'
import cn from 'classnames'
import { Price as UIPrice } from '@platform/ui'
import { observer } from 'mobx-react-lite'
import { core, ScreenMode } from '@src/core'

import { PriceProps } from './types'

const { layoutService } = core

export const Price: React.FC<PriceProps> = observer((props) => {
  const { className, measure, description = 'Цена за 1', ...rest } = props
  return (
    <div className={cn(className, 'space-y-1 md:space-y-2')}>
      <div className="text-xs md:text-md text-neutral-400">{`${description} ${measure}`}</div>
      <UIPrice
        className="mt-1 items-center"
        {...rest}
        size={layoutService.screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}
      />
    </div>
  )
})
