import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Title } from '@platform/ui'

import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'

export const Category: FC = observer(() => {
  return (
    <Flow directory="col" className="pt-7 content-main" space={7}>
      <Breadcrumbs
        items={[
          { children: 'Главная', href: LINKS.HOME },
          { children: 'Помощь', href: LINKS.HELP_HOME },
          { children: ' Личный кабинет' },
        ]}
      />
      <Title weight="bold" tag="h1" textClassName="text-1xl md:text-2xl">
        Личный кабинет
      </Title>
    </Flow>
  )
})
