import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { observer } from 'mobx-react-lite'
import { Button, Icon, Title, Input } from '@platform/ui'

import { SupportFormProps } from './types'

export const SupportForm: FC<SupportFormProps> = observer(({ onCrossClick }) => {
  return (
    <Flow directory="col" space={11} className="w-full w-max-710px">
      <Flow className="justify-between" align="center">
        <Title className="m-0" textClassName="text-1xl md:text-2xl" weight="bold">
          Обращение в техподдержку
        </Title>
        <Button variant="link" onClick={onCrossClick}>
          <Icon.Close size="1xl" />
        </Button>
      </Flow>
      <Flow directory="col" space={5}>
        <Input placeholder="Ваше имя" />
        <Input placeholder="Ваш e-mail или номер телефона" />
        <Input multiline placeholder="Пожалуйста, опишите вашу проблему в деталях, чтобы мы смогли вам помочь." />
        <Button color="primary" className="w-full" size="md">
          Отправить
        </Button>
      </Flow>
    </Flow>
  )
})
