import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Flow } from '@src/components/common/flow'
import { LINKS } from '@src/routes'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { IBreadcrumbItemProps, Steps } from '@platform/ui'

import { createSteps, baseCreateBreadcrumb } from '../../constants'

export const Layout: React.FC = () => {
  const { pathname } = useLocation()
  const currentRoute = pathname.split('/')[2]

  const breadcrumbs: IBreadcrumbItemProps[] = [
    { children: 'Главная', href: LINKS.HOME },
    { children: baseCreateBreadcrumb, href: LINKS.CREATE_PRODUCT },
    { children: createSteps[`/${currentRoute}`] },
  ]

  const activeStepIndex = Object.keys(createSteps).findIndex((item) => item === `/${currentRoute}`)

  return (
    <Flow directory="col" className="content-main py-8" space={10}>
      <Breadcrumbs items={breadcrumbs} />
      <div className="w-3/4">
        <Steps activeIndex={activeStepIndex} labels={Object.values(createSteps)} mode="desktop" />
      </div>
      <Outlet />
    </Flow>
  )
}
