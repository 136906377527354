export const ROUTES = {
  'HOME': {
    INDEX: '/',
  },
  'FOR-BUSINESS': { INDEX: '/for-business' },
  'EXCHANGE': { INDEX: '/promo/exchange' },
  'ABOUT-US': { INDEX: '/about-us' },
  'AUCTION': { INDEX: '/promo/auction' },
  'HELP': {
    INDEX: '/help/*',
    HOME: { INDEX: '/' },
    SECTION: { INDEX: '/:section' },
    TOPIC: { INDEX: '/:section/:topic' },
  },
  'AUTH': {
    INDEX: '/auth/*',
    CODE: { INDEX: '/code' },
    SIGNIN: { INDEX: '/signin' },
    SIGNUP: { INDEX: '/signup' },
    SOCIAL: { INDEX: '/social' },
    VERIFY_PHONE: { INDEX: '/verify/phone' },
  },
  'BY': {
    INDEX: '/by/*',
    CART: { INDEX: '/cart' },
    ORDER: { INDEX: '/order' },
    CONFIRM: { INDEX: '/confirm' },
  },
  'PRODUCT': {
    'INDEX': '/product/*',
    'AUCTION': {
      INDEX: '/auction/:id',
    },
    'SELL': {
      INDEX: '/sell/:id',
    },
    'BUY': {
      INDEX: '/buy/:id',
    },
    'EXCHANGE': {
      INDEX: '/exchange/:id',
    },
    'EXCHANGE-OFFER': {
      INDEX: '/exchange/offer/:id',
    },
    'EDIT': {
      INDEX: '/edit/:id',
    },
  },
  'CREATE': {
    INDEX: '/create/*',
    AUTH: { INDEX: '/auth' },
    PRODUCT: { INDEX: '/product' },
    SERVICE: { INDEX: '/service' },
    MODERATION: { INDEX: '/moderation' },
    PAYMENT: { INDEX: '/payment' },
    PUBLICATION: { INDEX: '/publication' },
  },
  'SEARCH': {
    INDEX: '/search',
  },
  'PROFILE': {
    INDEX: '/profile/*',
    HOME: { INDEX: '/' },
    MESSENGER: { INDEX: '/messenger' },
    SETTINGS: { INDEX: '/settings' },
    NOTIFICATIONS: { INDEX: '/notifications' },
    FAVORITES: { INDEX: '/favorites' },
  },
  'NOT_FOUND': { INDEX: '/*' },
  'CATEGORY': {
    INDEX: '/category/*',
    HOME: { INDEX: '/' },
    SLUG: { INDEX: '/:slug' },
  },
  'MAP': {
    INDEX: '/map',
  },
}

const links = makeLinks(ROUTES)
links.HOME = '/'

export const LINKS = links

function isObject(val: unknown): val is Record<string, any> {
  return typeof val === 'object' && val !== null && !Array.isArray(val)
}

function makeLinks(data: Record<string, any>): any {
  let arr: any[] = []
  const values = Object.entries(data || {}).map((val) => {
    const [name, node] = val
    return { ...node, NAME: name }
  })

  while (values.length) {
    const value = values.pop()
    if (!isObject(value)) {
      return
    }

    const { INDEX, NAME, ...DATA } = value

    if (INDEX && !Object.keys(DATA).length) {
      arr.push({ INDEX, NAME })
    }

    if (isObject(DATA)) {
      const results = Object.entries(DATA).map(([name, node]) => {
        const result: any = {
          ...node,
          NAME: `${NAME}_${name}`,
          INDEX: node?.INDEX && INDEX ? `${INDEX}${node.INDEX}`.replace('*', '/').replace(/\/\/+/gi, '/') : node?.INDEX,
        }

        if (result.INDEX[result.INDEX.length - 1] === '/') {
          result.INDEX = result.INDEX.slice(0, -1)
        }

        return result
      })
      values.unshift(...results)
    }
  }

  return arr.reduce((prev, curr) => {
    const { NAME, INDEX } = curr
    Object.assign(prev, { [NAME]: INDEX })
    return prev
  }, {})
}
