import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { Input } from '@platform/ui'
import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'

const { createProduct } = core.createProductService
const { priceSell, priceOldSell, measure, quantitySell } = createProduct

export const PublishSell: FC = observer(() => {
  const handleChange = useCallback((value: string) => {
    priceSell.changeValue(value)
  }, [])

  const handlePriceOldChange = useCallback((value: string) => {
    priceOldSell.changeValue(value)
  }, [])

  const handleQuantityChange = useCallback((value: string) => {
    quantitySell.changeValue(value)
  }, [])

  return (
    <div className="space-y-11">
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label required={!!priceSell.required} label={priceSell.label} textStyle="font-medium text-black text-sm" />
        }
        errorText={priceSell.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={priceSell.value ?? ''}
            onChange={handleChange}
            className="w-44"
            size="lg"
            isDecimal
            error={!priceSell.isValid}
            notMinus
          />
          <span className="ml-4 text-lg font-medium">₽</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={<Label label={priceOldSell.label} textStyle="font-medium text-black text-sm" />}
        errorText={priceOldSell.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={priceOldSell.value ?? ''}
            onChange={handlePriceOldChange}
            className="w-44"
            size="lg"
            isDecimal
            notMinus
            error={!priceOldSell.isValid}
          />
          <span className="ml-4 text-lg font-medium">₽</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label
            required={!!quantitySell.required}
            label={quantitySell.label}
            textStyle="font-medium text-black text-sm"
          />
        }
        errorText={quantitySell.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={quantitySell.value ?? ''}
            onChange={handleQuantityChange}
            className="w-44"
            size="lg"
            isDecimal
            notMinus
            error={!quantitySell.isValid}
          />
          <span className="ml-4 text-lg font-medium">{(measure.value as FieldSelectOption)?.name || ''}</span>
        </div>
      </FormItem>
    </div>
  )
})
