import { FC, useState, useEffect, useCallback } from 'react'
import { Avatar, Rating, Icon, Spinner, Button } from '@platform/ui'
import { ProductUserDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'

import { UserProps } from './types'

const { productService, userService, layoutService, authService } = core
const { screen } = layoutService
const { authorizedUser } = authService

export const User: FC<UserProps> = ({ productId, onlyAvatar = false }) => {
  const [status, setStatus] = useState<Status>('pending')
  const [user, setUser] = useState<ProductUserDTO | null>(null)
  const [userPhone, setUserPhone] = useState<string | null>(null)

  useEffect(() => {
    setStatus('loading')
    productService
      .fetchProductUser(productId)
      .then((res) => {
        if (res.isSuccess) {
          setUser(res.getValue())
        }
      })
      .then(() => setStatus('loaded'))
  }, [productId])

  const handleShowPhone = useCallback(() => {
    if (user?.id) {
      userService.fetchUserPhoneById(user.id).then((res) => {
        if (res.isSuccess) {
          setUserPhone(res.getValue())
        }
      })
    }
  }, [])

  if (status === 'loading' || !user) {
    return (
      <div className="p-12 flex items-center justify-center">
        <Spinner size="2xl" className="text-primary-500" />
      </div>
    )
  }

  if (onlyAvatar) {
    return (
      <Flow space={6} align="center">
        <Avatar size="xl" src={user.avatarSrc ?? ''} />
        <Flow directory="col" space={2.5}>
          <span className="font-bold text-md">{user.name}</span>
          <div className="h-4">{user.rating > 0 && <Rating value={user.rating} showRating />}</div>
        </Flow>
      </Flow>
    )
  }

  return (
    <Flow
      space={screen.mode === ScreenMode.tablet ? 7 : 4}
      directory={screen.mode === ScreenMode.desktop ? 'row' : 'col'}
      className="justify-between px-6 py-4 md:px-8 md:py-9 border-xs border-neutral-300 rounded-xl"
    >
      <Flow space={6} align="center">
        <Avatar size="xl" src={user.avatarSrc ?? ''} />
        <Flow directory="col" space={2.5}>
          <span className="font-bold text-md">{user.name}</span>
          <div className="h-4">{user.rating > 0 && <Rating value={user.rating} showRating />}</div>
        </Flow>
      </Flow>
      {authorizedUser.user?.id !== user.id && (
        <Flow directory="col" space={2.5}>
          {user.hasPhone && (
            <span className="text-neutral-400">
              Телефон:
              {userPhone ? (
                <span className="text-neutral-900 pl-2">{userPhone}</span>
              ) : (
                <>
                  <span className="text-neutral-900 pl-2">+7</span>
                  <span className="text-primary-500 px-2">
                    [
                    <Button className="underline" color="primary" variant="link" onClick={handleShowPhone}>
                      показать номер
                    </Button>
                    ]
                  </span>
                </>
              )}
            </span>
          )}
          <Button
            variant="link"
            className="text-md font-normal"
            color="primary"
            icon={<Icon.Comment color="primary" />}
            iconPosition="left"
          >
            Написать сообщение
          </Button>
        </Flow>
      )}
      <Flow directory="col" space={2.5}>
        {authorizedUser.user?.id !== user.id && (
          <Button
            variant="link"
            className="text-md font-normal"
            color="primary"
            icon={<Icon.Favorite color="primary" />}
            iconPosition="left"
          >
            Сохранить продавца
          </Button>
        )}
        <Button
          variant="link"
          className="text-md font-normal underline"
          color="primary"
          icon={<Icon.ArrowLong position="right" color="primary" />}
          iconPosition="right"
        >
          Смотреть все объявления
        </Button>
      </Flow>
    </Flow>
  )
}
