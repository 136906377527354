import { FC, useContext } from 'react'
import { Link } from 'react-router-dom'

import { CategoriesContext } from './context'
import { CategoriesProps } from './types'
import { CategoryDTO } from '@platform/core/dist/modules/catalog/infra/dtos'

export const Categories: FC<CategoriesProps> = () => {
  const context = useContext(CategoriesContext)
  const categories = context?.activeSection?.childs

  if (!categories) return null

  const sortCategories: CategoryDTO[][] = []
  for (let i = 0; i < Math.ceil(categories.length / 3); i++) {
    sortCategories[i] = categories.slice(i * 3, i * 3 + 3)
  }

  const basePath = `/category`

  return (
    <div className="px-4 xl:px-10 py-1 w-full">
      <span className="font-bold text-xl leading-6">{context?.activeSection.name}</span>
      <div className="flex w-full mt-10 gap-x-10 xl:gap-x-20">
        {sortCategories.map((item, key) => (
          <div key={key} className="flex flex-col gap-y-10">
            {item.map(({ id: categoryId, name: categoryName, code: categoryCode, childs }) => {
              return (
                <div key={categoryId} className="flex flex-col w-full">
                  <Link
                    to={`${basePath}/${categoryCode}--${categoryId}`}
                    className="text-black font-bold text-md leading-4.8 whitespace-nowrap"
                  >
                    {categoryName}
                  </Link>
                  {!!childs.length && (
                    <ul className="space-y-2 mt-2">
                      {childs.map(({ id, name, code }) => (
                        <li key={id}>
                          <Link
                            to={`${basePath}/${code}--${id}`}
                            className="text-black text-md leading-4.8 whitespace-nowrap"
                          >
                            {name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
