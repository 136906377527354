import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, Button, Rating, Icon } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { UserMenuItem } from './user-menu-item'
import { menuConfig } from './constants'
import { UserMenuProps } from './types'

const { authService, cartService } = core
const { authorizedUser } = authService

export const UserMenu: FC<UserMenuProps> = observer(({ onClickCloseMenu }) => {
  const handleToSwapAccount = useNavigationApp({ to: LINKS.AUTH_SIGNIN })

  const navigate = useNavigate()
  const params = useParams()

  const handleLogout = useCallback(() => {
    authService
      .logout()
      .then(() => cartService.removeCart())
      .then(() => {
        navigate(LINKS.AUTH_SIGNIN)
      })
  }, [])

  if (!authorizedUser.user) return null

  const handleClick = useCallback(
    (path: string) => {
      onClickCloseMenu()
      navigate(path)
    },
    [onClickCloseMenu]
  )

  return (
    <Flow directory="col" space={5} className="shadow-lg rounded-md p-8 pb-20 w-full lg:w-345px">
      <Avatar size="2xl" src={authorizedUser.user.image} type="square" />
      <Flow directory="col" space={2}>
        <span className="font-semibold text-lg">{authorizedUser.user.name}</span>
        <div className="h-4">
          {authorizedUser.user.rating > 0 && <Rating value={authorizedUser.user.rating} showRating />}
        </div>
      </Flow>
      <Flow directory="col" align="start" className="pt-6" space={4}>
        <Flow directory="col" space={'18px'} align="start" className="pb-4 border-b-xs border-neutral-300">
          {menuConfig.map((item, key) => (
            <UserMenuItem
              key={key}
              {...item}
              isActive={params['*'] === (item.path.split('/')[2] ?? '')}
              onClick={() => handleClick(item.path)}
            />
          ))}
        </Flow>
        <Flow directory="col" space={'18px'} align="start">
          <Flow space={4} align="center">
            <span className="text-neutral-500 text-lg">
              <Icon.Swap />
            </span>
            <Button onClick={handleToSwapAccount} variant="link" color="primary" className="text-lg font-normal">
              Сменить аккаунт
            </Button>
          </Flow>
          <Flow space={4} align="center">
            <span className="text-neutral-500 text-lg">
              <Icon.Exit />
            </span>
            <Button onClick={handleLogout} variant="link" color="primary" className="text-lg font-normal">
              Выход
            </Button>
          </Flow>
        </Flow>
      </Flow>
    </Flow>
  )
})
