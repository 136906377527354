import { FC, useCallback, useEffect, useState } from 'react'
import { SliderRange, Range } from '@platform/ui'

import { validateSearchParam } from '../helper'
import { FilterSearchParam } from '../types'
import { PriceFilterProps } from './types'

const { PRICE } = FilterSearchParam

export const PriceSliderFilter: FC<PriceFilterProps> = ({
  getSearchParam,
  setSearchParam,
  minRange,
  maxRange,
  hasSearchParam,
  searchParams,
}) => {
  let defaultValue: Range = {
    min: minRange,
    max: maxRange,
  }
  if (validateSearchParam(PRICE, getSearchParam(PRICE))) {
    const [min, max] = (getSearchParam(PRICE) as string).split(',')
    if (Number(min) >= minRange && Number(max) <= maxRange) {
      defaultValue = {
        min: Number(min),
        max: Number(max),
      }
    }
  }
  const [state, setState] = useState<Range>(defaultValue)

  const handleChange = useCallback(
    (value: Range) => {
      setState(value)
      setSearchParam(PRICE, [value.min, value.max].join(','))
    },
    [setSearchParam]
  )

  useEffect(() => {
    setState({
      min: minRange,
      max: maxRange,
    })
  }, [minRange, maxRange])

  useEffect(() => {
    if (hasSearchParam(PRICE)) {
      const [min, max] = (getSearchParam(PRICE) as string).split(',')
      setState({
        min: Number(min),
        max: Number(max),
      })
    } else {
      setState({
        min: minRange,
        max: maxRange,
      })
    }
  }, [searchParams])

  return <SliderRange value={state} min={minRange} max={maxRange} title="Цена товара" onChange={handleChange} />
}
