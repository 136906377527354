import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Icon } from '@platform/ui'

import { AddressProps } from './types'

export const Address: FC<AddressProps> = ({ address }) => {
  return (
    <Flow directory="col" space={3}>
      <span className="text-sm md:text-md text-black font-medium leading-md md:leading-lg">Адрес склада</span>
      <Flow space={'7px'} align="center">
        <span>
          <Icon.Location size="md" color="primary" />
        </span>
        <span className="text-xs md:text-sm text-black leading-sm md:leading-md">{address}</span>
      </Flow>
    </Flow>
  )
}
