import React from 'react'
import cn from 'classnames'

import { FlowProps } from './types'

export const Flow: React.FC<FlowProps> = (props) => {
  const { directory = 'row', full, space = 0, align = 'initial', children, className } = props
  const axis = directory === 'row' ? 'x' : 'y'

  return (
    <div
      className={cn(className, 'flex', `flex-${directory}`, {
        [`space-${axis}-${space}`]: true,
        [`items-${align}`]: true,
        ['w-full']: Boolean(full),
      })}
    >
      {children}
    </div>
  )
}
