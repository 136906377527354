import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import NotFound from '../not-found'
import { Category } from './category'

const { CATEGORY } = ROUTES

const CategoryPages = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route index element={<Category />} />
        <Route path={CATEGORY.SLUG.INDEX} element={<Category />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

export default CategoryPages
