import { FC, useMemo, useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { core, ScreenMode } from '@src/core'
import { Button, Grid, GridCell } from '@platform/ui'
import { Flow } from '@src/components/common/flow'

import { Region } from '@platform/core/dist/models'
import { Loading } from '@src/components/common/loading'

const { layoutService, regionService } = core

export const RegionList: FC = observer(() => {
  const [list, setList] = useState<Region[]>([])
  const [status, setStatus] = useState<Status>('pending')
  const [isShowFull, setIsShowFull] = useState<boolean>(false)

  useEffect(() => {
    setStatus('loading')
    regionService.fetchAllRegions().then((res) => {
      if (res.isSuccess) {
        setStatus('loaded')
        setList(res.getValue())
      }
      if (res.isFailure) setStatus('failed')
    })
  }, [])

  const handleClick = useCallback(() => setIsShowFull(true), [isShowFull])
  const mode = layoutService.screen.mode
  const width = useMemo(() => {
    if (mode === ScreenMode.mobile) return 12
    if (mode === ScreenMode.tablet) return 4
    return 3
  }, [mode])

  return (
    <div className="max-w-full">
      {status === 'loading' && <Loading />}
      {status === 'loaded' && (
        <>
          <div className={cn('overflow-hidden', { 'h-80': !isShowFull, 'h-full': isShowFull })}>
            <Grid columnsNumber={12}>
              {list.map((item) => {
                return (
                  <GridCell key={item.id} width={width} height={1}>
                    <Link to={`/map?center=${item.latitude},${item.longitude}`}>
                      <span className="hover:underline text-black font-medium">{`${item.name} (${item.count})`}</span>
                    </Link>
                  </GridCell>
                )
              })}
            </Grid>
          </div>
          {!isShowFull && (
            <Flow align="center" className="justify-center pt-8">
              <Button
                variant="outline"
                color="black"
                className="w-full md:w-auto md:px-40"
                textClassName="text-xs md:text-sm"
                onClick={handleClick}
              >
                Показать все регионы
              </Button>
            </Flow>
          )}
        </>
      )}
    </div>
  )
})
