import { FC, useMemo } from 'react'
import { ProgressBar } from '@platform/ui'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Loading } from '@src/components/common/loading'
import { parseMilliseconds } from '@src/utils/helpers'

const { auctionService } = core
const { auction } = auctionService

export const AuctionProgress: FC = observer(() => {
  const progressPercent = useMemo(() => {
    const start = auction.startDate.getTime()
    const end = auction.endDate.getTime()
    const allTime = end - start

    return Math.round(((allTime - auction.timeLeft) * 100) / allTime)
  }, [auction.startDate, auction.endDate, auction.timeLeft])

  if (auction.statusFetchBits === 'loading') {
    return <Loading />
  }

  return (
    <ProgressBar progressColor="positive" progressColorNumber={300} className="w-full h-48px" procent={progressPercent}>
      <span className="text-xs">
        Аукцион идёт,
        <span className="font-medium text-md md:text:lg">{` ${parseMilliseconds(auction.timeLeft)} `}</span>
        до завершения
      </span>
    </ProgressBar>
  )
})
