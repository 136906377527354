import { FC, useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Flow } from '@src/components/common/flow'
import { observer } from 'mobx-react-lite'
import { Button, Spinner, Icon, Carousel, Tabs } from '@platform/ui'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { core, ScreenMode } from '@src/core'
import NotFound from '@src/pages/not-found'
import { Title } from '@src/components/common/title'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { Section, SectionH2 } from '@src/components/common/section'
import { Address } from '@src/components/common/address'
import { Map, Placemark } from '@src/components/common/ymaps'

import { Rating } from '../rating'
import { Breadcrumbs } from '../breadcrumbs'
import { TagList } from '../../components/tag-list'
import { AuctionTabs } from '../../types'
import { Properties } from '../../components/properties'
import { Details } from '../../components/details'
import { User } from '../../container/user'
import { UserProducts } from '../../container/user-products'
import { RecommendedProducts } from '../../container/recommended-products'
import { Bets } from '../bets'
import { Extra } from '../../components/extra'
import { Comments } from '../comments'
import { AuctionProgress } from '../auction-progress'

const { productService, layoutService } = core
const { product } = productService
const { screen } = layoutService

export const ProductLayout: FC = observer(() => {
  const params = useParams()
  const productId = Number(params.id)
  const nodeRates = useRef<HTMLDivElement>(null)
  const nodeDescription = useRef<HTMLDivElement>(null)
  const [isNotFound, setIsNotFound] = useState(false)

  const [tabSelected, setTabSelected] = useState<AuctionTabs>(AuctionTabs.seller)

  const [handleToDescription] = useScrollIntoView({ ref: nodeDescription })

  useEffect(() => {
    productService.fetchProduct(productId).then((resultOrError) => {
      if (resultOrError.isFailure) {
        setIsNotFound(true)
      }
    })
  }, [productId])

  useEffect(() => {
    return () => {
      productService.resetProduct()
    }
  }, [])

  const handleClickFavorite = useCallback(() => {
    if (product.data) {
      if (!product.data.isFavorite) {
        productService.addCurrentProductToFavorite(product.data.id)
      } else {
        productService.deleteCurrentProductFromFavorites(product.data.id)
      }
    }
  }, [])

  const { deliveryList, paymentList } = useMemo(
    () => ({
      deliveryList: product.data?.deliveryList.map((item) => item.name) || [],
      paymentList: product.data?.paymentList.map((item) => item.name) || [],
    }),
    [product.data]
  )

  if (isNotFound) {
    return <NotFound />
  }

  if (product.isLoading) {
    return (
      <Flow className="content-main flex-1 justify-center" align="center">
        <Spinner size="2xl" className="text-primary-500" />
      </Flow>
    )
  }

  if (!product.data) return null

  if (isNotFound || params['*']?.split('/')[0] !== product.data.format) {
    return <NotFound />
  }

  return (
    <Flow className="content-main py-8" directory="col" space={4}>
      <Flow className="justify-between" align="center">
        {screen.mode === ScreenMode.desktop && (
          <Breadcrumbs categoryId={product.data.categoryId} productTitle={product.data.name} />
        )}
        {screen.mode !== ScreenMode.desktop && <TagList format={product.data.format} />}
      </Flow>
      <Title size={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}>{product.data.name}</Title>
      <Flow>
        {product.data.format === ProductTypeDTO.sell && <Rating productId={product.data.id} className="mr-6" />}
        <Button
          icon={product.data.isFavorite ? <Icon.FavoriteFull color="negative" /> : <Icon.Favorite color="primary" />}
          variant="link"
          color={'primary'}
          className=" font-normal text-sm"
          iconPosition="left"
          onClick={handleClickFavorite}
        >
          {product.data.isFavorite ? 'Больше не отслеживать' : 'Отслеживать'}
        </Button>
      </Flow>
      <Section>
        <Flow full align="start" directory={screen.mode === ScreenMode.desktop ? 'row' : 'col'} space={5}>
          <Carousel
            data={product.data.images.map((item) => ({ view: item.src, nav: item.src }))}
            mode="mobile"
            className={screen.mode === ScreenMode.desktop ? 'max-w-710px' : 'w-full'}
          />
          {screen.mode === ScreenMode.mobile && (
            <Button variant="link" color="primary" onClick={handleToDescription}>
              Перейти к описанию
            </Button>
          )}
          <Flow space={5} align="start" full>
            {screen.mode !== ScreenMode.mobile && (
              <Details
                className="w-full max-w-full lg:max-w-345px min-w-250px"
                deliveryList={deliveryList}
                paymentList={paymentList}
                format={product.data.format}
                onClickDescription={handleToDescription}
                properties={[...product.data.attributes]
                  .slice(0, 5)
                  .map(({ id, valueText, name, measure, description }) => ({
                    value: valueText,
                    id,
                    name,
                    measure,
                    description,
                  }))}
                address={product.data.location.address}
              />
            )}
            <Flow directory="col" className="p-7 w-full flex-static md:w-345px rounded-xl shadow-lg bg-white" space={3}>
              <Outlet
                context={{
                  onClickFavorite: handleClickFavorite,
                }}
              />
            </Flow>
          </Flow>
        </Flow>
      </Section>
      <Flow directory="col" space={screen.mode === ScreenMode.mobile ? '54px' : '88px'}>
        <Section ref={nodeRates}>
          {product.data.format === ProductTypeDTO.auction ? (
            <Tabs
              className="space-y-4 items-start w-full"
              navTextColor="neutral-300"
              navTextActiveColor="neutral-900"
              value={tabSelected}
              onChange={setTabSelected}
            >
              <Tabs.NavList className="space-x-12 pb-4 border-b border-neutral-300">
                <Tabs.Button index={AuctionTabs.seller} className="text-xl">
                  О продавце
                </Tabs.Button>
                <Tabs.Button index={AuctionTabs.rates} className="text-xl">
                  Ставки
                </Tabs.Button>
              </Tabs.NavList>
              <Tabs.Body className="w-full lg:w-3/4">
                <Tabs.Content index={AuctionTabs.seller}>
                  <User productId={product.data.id} />
                </Tabs.Content>
                <Tabs.Content index={AuctionTabs.rates}>
                  <Flow directory="col" space={5}>
                    <AuctionProgress />
                    <div className="overflow-x-scroll overflow-y-hidden scrollbar">
                      <Bets />
                    </div>
                  </Flow>
                </Tabs.Content>
              </Tabs.Body>
            </Tabs>
          ) : (
            <div className="w-full lg:w-3/4">
              <User productId={product.data.id} />
            </div>
          )}
        </Section>
        <SectionH2 title="Похожие рекомендуемые товары">
          <RecommendedProducts search={product.data.name} />
        </SectionH2>
        <SectionH2 title="Объявления продавца">
          <UserProducts userId={product.data.userId} />
        </SectionH2>
        <SectionH2 ref={nodeDescription} title="Описание">
          <div
            className="flex flex-col space-y-4 text-xs md:text-md lg:w-3/4"
            dangerouslySetInnerHTML={{ __html: product.data.description }}
          />
        </SectionH2>
        {screen.mode === ScreenMode.mobile && (
          <Extra deliveryList={deliveryList} paymentList={paymentList} direction="col" />
        )}
        <SectionH2 title="Характеристики">
          <Properties
            className="lg:w-3/4"
            data={product.data.attributes.map(({ id, valueText, name, measure, description }) => ({
              value: valueText,
              id,
              name,
              measure,
              description,
            }))}
          />
        </SectionH2>
        <SectionH2 title="Местоположение">
          <Flow directory="col" className="space-y-17px md:space-y-19px">
            <Address address={product.data.location.address} />
            <div className="lg:w-3/4">
              <Map
                state={{
                  center: [product.data.location.lat, product.data.location.lon],
                  zoom: 16,
                  behaviors: ['disable("scrollZoom")', 'disable("drag")'],
                }}
                height={451}
              >
                <Placemark geometry={[product.data.location.lat, product.data.location.lon]} />
              </Map>
            </div>
          </Flow>
        </SectionH2>
        {product.data.format === ProductTypeDTO.sell && (
          <SectionH2 title="Отзывы">
            <Comments productId={product.data.id} />
          </SectionH2>
        )}
      </Flow>
    </Flow>
  )
})
