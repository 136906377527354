import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { ProductTypeDTOName } from '@platform/core/dist/modules/product/infra/dtos'
import { Tag, Icon } from '@platform/ui'
import { badgeProductMap } from '@src/constants'

import { TagListProps } from './types'

export const TagList: FC<TagListProps> = ({ format, vip, discount, className }) => {
  return (
    <div className={className}>
      <Flow className="flex-wrap gap-2" align="center">
        <Tag size="md" color={badgeProductMap[format].color} shape="round" colorNumber={500}>
          {ProductTypeDTOName[format]}
        </Tag>
        {!!discount && (
          <Tag size="md" color="negative" shape="round" colorNumber={500}>
            {`-${discount}%`}
          </Tag>
        )}
        {vip && (
          <Tag size="md" color="negative" shape="round" colorNumber={500}>
            <Icon.CrownFull color="yellow" />
          </Tag>
        )}
      </Flow>
    </div>
  )
}
