import { FC } from 'react'
import { Info as InfoUI } from '@platform/ui'

import { InfoProps } from './types'

export const Info: FC<InfoProps> = ({ children }) => {
  return (
    <InfoUI size="2xl" type="info" color="secondary" position="left" className="items-center bg-primary-100 rounded-xl">
      <span className="text-xl text-black">{children}</span>
    </InfoUI>
  )
}
