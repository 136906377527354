import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, Link } from 'react-router-dom'
import { Button, Icon } from '@platform/ui'

import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { LINKS } from '@src/routes'
import { core } from '@src/core'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { Info } from './components/info'
import { ProductDetails } from './container/product-details'

const { createProduct } = core.createProductService

export const Moderation: FC = observer(() => {
  const navigate = useNavigate()

  const handleToProfile = useNavigationApp({ to: LINKS.PROFILE_HOME })
  const handleToProfileNotifications = useNavigationApp({ to: LINKS.PROFILE_NOTIFICATIONS })

  useEffect(() => {
    if (!createProduct.id) {
      navigate(LINKS.CREATE_PRODUCT)
      return
    }

    core.createProductService.fetchCreatedProduct(createProduct.id)
  }, [])

  return (
    <Flow directory="col">
      <SectionH2 className="py-6" title="Объявление отправлено на модерацию">
        <Flow directory="col" space={16}>
          <div className="w-3/4">
            <Info>
              Объявление после успешного прохождения проверки будет автоматически размещено на портале. Статус
              объялвения будет доступен в&nbsp;
              <Link target="_blank" to={LINKS.PROFILE_HOME}>
                <span className="underline text-primary-500">личном кабинете</span>
              </Link>
              . Так же уведомление о статусе объявления поступят на электронную почту.
            </Info>
          </div>
          <ProductDetails withCancelBtn />
          <Flow directory="col" space={3.5} className="w-345px">
            <Link target="_blank" to={LINKS.PROFILE_HOME}>
              <Button size="md" color="primary" className="w-full">
                Перейти в личный кабинет
              </Button>
            </Link>
            <Button
              size="md"
              variant="link"
              color="primary"
              className="underline font-normal justify-center"
              onClick={handleToProfileNotifications}
              iconPosition="right"
              icon={<Icon.ArrowLong position="right" />}
            >
              Настроить уведомления
            </Button>
          </Flow>
        </Flow>
      </SectionH2>
    </Flow>
  )
})
