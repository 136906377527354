import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Slider } from '@platform/ui'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FieldSliderInterface } from '@platform/core/dist/modules/product/domain/fields/models'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldSLider: FC<Detail> = observer(({ id }) => {
  const { changeValue, label, required, value, min, max, errorText, description } = createProduct.fields.find(
    (item) => item.id === id
  ) as FieldSliderInterface

  return (
    <div>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={changeValue}
        title={<Label required={required} label={label} size="sm" tooltipText={description} />}
      />
      <div className="mt-2 text-invalid">{errorText}</div>
    </div>
  )
})
