import { createContext, useContext } from 'react'
import { useSearchParamsAppReturn } from '@src/hooks/use-search-params-app'

export interface FilterParamsContextProps {
  searchParams: URLSearchParams
  deleteSearchParam: useSearchParamsAppReturn['deleteSearchParam']
  getSearchParam: useSearchParamsAppReturn['getSearchParam']
  hasSearchParam: useSearchParamsAppReturn['hasSearchParam']
  setSearchParam: useSearchParamsAppReturn['setSearchParam']
}

export const FilterParamsContext = createContext<FilterParamsContextProps | null>(null)

export const FilterParamsProvider = FilterParamsContext.Provider

export const useFilterParamsContext = (): FilterParamsContextProps => {
  const context = useContext(FilterParamsContext)
  if (!context) {
    throw new Error('Not found: FilterParamsContext')
  }

  return context
}
