import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { FileUpload, Icon, Input, InputWithButton, Button } from '@platform/ui'
import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { FormItem } from '@src/components/common/form-item'
import { Label } from '@src/components/common/label'
import { core } from '@src/core'

import { Badge } from '../../components/badge'
import { Socail } from '../social'

const { userService, authService } = core
const { userProfile } = userService
const { authorizedUser } = authService
const { phone, email, photo } = userProfile

export const Profile: FC = observer(() => {
  const handleChangePhoto = useCallback((files: File[]) => {
    photo.changeValue(files)
    userService.editProfile({
      image: files[0],
    })
  }, [])

  const handleEmailBlur = useCallback(() => {
    const isValid = email.validate()
    if (isValid) {
      userService.editProfile({
        profile: {
          email: email.value,
        },
      })
    }
  }, [email.value])

  const handlePhoneBlur = useCallback(() => {
    const isValid = phone.validate()
    if (isValid) {
      userService.editProfile({
        profile: {
          phone: phone.value,
        },
      })
    }
  }, [phone.value])

  if (!authorizedUser.user) return null

  return (
    <Flow directory="col" space={12}>
      <SectionH2 title={photo.label}>
        <FileUpload
          files={photo.value}
          multiple={false}
          onChange={handleChangePhoto}
          accept={{
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
          }}
          maxCount={1}
          className="h-64 w-full md:w-100"
        >
          {authorizedUser.user.image && !photo.value?.length ? (
            <img src={authorizedUser.user.image} className="object-cover max-w-full max-h-full" />
          ) : (
            <Flow directory="col" align="center" space={6}>
              <div>
                <Icon.Image size="3xl" className="text-neutral-900" />
              </div>
              <Button className="m-4" variant="outline" color="black">
                Добавить фото профиля
              </Button>
              <div className="text-sm text-center text-neutral-400">
                Вы можете загрузить фото профиля, которое будут видеть ваши покупатели.
              </div>
            </Flow>
          )}
        </FileUpload>
      </SectionH2>
      <SectionH2 title="Контактная информация">
        <Flow directory="col" space={5}>
          <Flow space={3} className="my-5">
            <span className="pt-x">
              <Icon.CheckShield color="positive" size="sm" />
            </span>
            <span className="font-medium">
              Чтобы обезопасить ваш настоящий номер, мы показываем вместо него подменный, а звонки переводим вам.&nbsp;
              <Link to="/">
                <span className="underline text-primary-500">Подробнее..</span>
              </Link>
            </span>
          </Flow>
          <FormItem
            className="flex-col md:flex-row justify-between"
            label={<Label label={String(phone.label)} textStyle="font-medium text-lg" />}
            errorText={phone.errorText}
          >
            <InputWithButton
              onBlur={handlePhoneBlur}
              className="w-full md:w-60"
              input={<Input.Phone value={phone.value} onChangeValue={phone.changeValue} />}
            />
          </FormItem>
          <FormItem
            className="flex-col md:flex-row justify-between"
            label={<Label label={String(email.label)} textStyle="font-medium text-lg" />}
            errorText={email.errorText}
          >
            <InputWithButton
              onBlur={handleEmailBlur}
              className="w-full md:w-60"
              input={<Input value={email.value} onChangeValue={email.changeValue} />}
            />
          </FormItem>
        </Flow>
      </SectionH2>
      {/* <SectionH2 title="Соцсети и сервисы">
        <div className="flex flex-wrap flex-col gap-5 md:flex-row">
          <Socail />
        </div>
      </SectionH2> */}
      {/* <SectionH2 title="Значки">
        <div className="py-5 flex-col md:flex-row gap-x-0 md:gap-x-5 gap-y-4 md:gap-y-0 ">
          <Badge isActive>Телефон подтвержден</Badge>
          <Badge>Почта не подтверждена</Badge>
        </div>
      </SectionH2> */}
    </Flow>
  )
})
