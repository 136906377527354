import { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { IBreadcrumbItemProps } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { Breadcrumbs as BreadcrumbsComponent } from '@src/components/common/breadcrumbs'

const categoryService = core.categoryService

export const Breadcrumbs: FC = observer(() => {
  const { slug } = useParams()
  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const breadcrumbs = useMemo(() => {
    const breadcrumbsList: IBreadcrumbItemProps[] = [
      { children: 'Главная', href: LINKS.HOME },
      {
        children: 'Все категории',
        href: LINKS.CATEGORY_HOME,
      },
    ]

    if (slug) {
      const [categoryCode, categoryId] = (slug as string).split('--')
      const categoryParentsList = categoryService.categories.getListParentsById(Number(categoryId))

      breadcrumbsList.push(
        ...categoryParentsList.map((item) => ({
          children: item.name,
          href: `/category/${item.code}--${item.id}`,
        }))
      )
    }
    return breadcrumbsList
  }, [slug, categoryService.categories.list])

  if (categoryService.isEmpty) return null

  return <BreadcrumbsComponent items={breadcrumbs} />
})
