import { useMemo, useEffect, useCallback, useState, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Icon, Input, MenuTree, MenuTreeNode, Option } from '@platform/ui'
import { categoryToMenuTree } from '@src/utils/category'
import { Title } from '@src/components/common/title'
import { isObject } from '@src/utils/helpers'
import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'

import { CreateProductContext } from '../context'
import { Loading } from '@src/components/common/loading'

const { categoryService, createProductService } = core
const { createProduct } = createProductService
const { categories } = categoryService
const { category } = createProduct

export const Category = observer(() => {
  const [stateAutocomplete, setAutocomplete] = useState<Option | string>('')
  const [visibleMenuCategory, setVisibleMenuCategory] = useState(true)
  const context = useContext(CreateProductContext)

  useEffect(() => {
    categoryService.fetchTreeCategories()
    if (context?.isEdit) {
      setVisibleMenuCategory(false)
      core.createProductService.fetchCategoryAttributes(true)
      core.createProductService.fetchProductDeliveryList(true)
    }
  }, [])

  const handleChangeAutocomplete = useCallback((option: Option) => {
    let value = String(option)
    let visible = true

    if (isObject(option)) {
      value = String(option.value)
      visible = !option.isLast
      setAutocomplete(option.label || '')
      if (option.isLast) {
        category.changeValue(value)
        core.createProductService.fetchCategoryAttributes()
        core.createProductService.fetchProductDeliveryList()
      }
    }

    setVisibleMenuCategory(visible)
  }, [])

  const handleClickMenuTree = useCallback((node: MenuTreeNode, endNode: boolean) => {
    setAutocomplete('')

    if (endNode) {
      category.changeValue(node.id)
      core.createProductService.fetchCategoryAttributes()
      core.createProductService.fetchProductDeliveryList()
      setVisibleMenuCategory(false)
    }
  }, [])

  const handleEdit = () => setVisibleMenuCategory(true)
  const handleClose = () => setVisibleMenuCategory(false)

  const dataTree = useMemo(() => categoryToMenuTree(categories.list), [categories.list])

  if (categories.isLoading) return <Loading />

  return (
    <>
      <SectionH2 title="В какой категории будет размещено объявление?">
        <Flow directory="col" space={4}>
          {!context?.isEdit && (
            <>
              <Input.Autocomplete
                placeholder="Поиск категории"
                size="lg"
                value={stateAutocomplete}
                options={categories.listAutocomplite}
                onChange={handleChangeAutocomplete}
              />
              <Title className="flex items-center space-x-2 text-lg md:text-1xl font-medium">
                <span>{category.label}</span>
                {!visibleMenuCategory ? (
                  <Icon.PencilFull className="cursor-pointer" onClick={handleEdit} />
                ) : (
                  <Icon.Close className="cursor-pointer" onClick={handleClose} />
                )}
              </Title>
            </>
          )}
          <MenuTree
            data={dataTree}
            value={createProduct.category.value}
            visibile={visibleMenuCategory}
            onClick={handleClickMenuTree}
          />
        </Flow>
      </SectionH2>
    </>
  )
})

Category.displayName = 'Category'
