import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { CardProduct, GridCell, Spinner } from '@platform/ui'
import { Button, Grid } from '@platform/ui'
import { badgeProductMap } from '@src/constants'
import { Flow } from '@src/components/common/flow'
import { core, ScreenMode } from '@src/core'
import { ProductTypeDTOName, ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { ProductListProps } from './types'

const { productService, cartService, layoutService } = core
const { screen } = layoutService

export const ProductList: FC<ProductListProps> = observer(
  ({ products, loadMore, requestStatus, isLastPage, loadMoreWrapperClass, forceFull }) => {
    const navigate = useNavigate()
    const handleLoadMore = useCallback(() => loadMore?.(), [loadMore])

    const handleClickFavorite = useCallback((metaData: Record<string, unknown>, favoriteState: boolean) => {
      if (favoriteState) {
        productService.addProductToFavorite(metaData.id as number)
      } else {
        productService.deleteProductFromFavorites(metaData.id as number)
      }
    }, [])

    const handleClickCard = useCallback(
      (metaData: Record<string, unknown>) => navigate(`/product/${metaData.type}/${metaData.id}`),
      [navigate]
    )

    const handleClickAddCart = useCallback(
      async (metaData: Record<string, unknown>, setAction: React.Dispatch<React.SetStateAction<boolean>>) => {
        setAction(true)
        await cartService.clickAddCartItem(metaData.id)
        setAction(false)
      },
      []
    )

    const cellResponsive = useMemo(() => {
      if (forceFull) return
      return { md: 'col-span-4', lg: 'col-span-3' }
    }, [forceFull])

    return (
      <Flow directory="col" space={10} full>
        <Grid columnsNumber={12} className="sm:gap-3 md:gap-5">
          {products.map((item) => (
            <GridCell key={item.id} width={12} height={1} responsive={cellResponsive}>
              <CardProduct
                variant={screen.mode === ScreenMode.mobile ? 'little' : 'big'}
                className="w-full"
                metaData={{ id: item.id, type: item.type }}
                onClick={handleClickCard}
                src={item.images}
                title={item.name}
                rating={item.rating.percentRank}
                ratingCount={item.rating.countRank}
                price={item.price}
                oldPrice={item.oldPrice}
                sellerName={item.userName}
                isFavorite={item.isFavorite}
                onClickFavorite={handleClickFavorite}
                onClickBasketButton={handleClickAddCart}
                priceDescription={item.measure && `за 1 ${item.measure}`}
                sale={item.sale}
                typeMark={item.type !== ProductTypeDTO.sell}
                typeMarkColor={badgeProductMap[item.type].color}
                typeMarkColorNumber={badgeProductMap[item.type].number}
                typeMarkText={ProductTypeDTOName[item.type]}
              />
            </GridCell>
          ))}
        </Grid>
        {requestStatus === 'loading' && (
          <div className="py-56 flex items-center justify-center">
            <Spinner size="2xl" className="text-primary-500" />
          </div>
        )}
        {requestStatus === 'loaded' && !!loadMore && !isLastPage && (
          <div className={cn(loadMoreWrapperClass, 'flex items-center justify-center w-full')}>
            <Button
              className="w-full max-w-710px"
              variant="outline"
              textClassName="text-xs md:text-sm"
              color="black"
              onClick={handleLoadMore}
            >
              Показать еще
            </Button>
          </div>
        )}
      </Flow>
    )
  }
)
