import { FC } from 'react'
import { Dropdown, Button, Icon } from '@platform/ui'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { LINKS } from '@src/routes'
import { Avatar } from '@platform/ui'

import { UserBarProps } from './types'

export const UserBar: FC<UserBarProps> = ({ onLogout }) => {
  const handleToAds = useNavigationApp({ to: LINKS.PROFILE_HOME })
  const handleToMessage = useNavigationApp({ to: LINKS.PROFILE_MESSENGER })
  const handleToFavorite = useNavigationApp({ to: LINKS.PROFILE_FAVORITES })
  const handleToNotification = useNavigationApp({ to: LINKS.PROFILE_NOTIFICATIONS })
  const handleToSettings = useNavigationApp({ to: LINKS.PROFILE_SETTINGS })
  const handleToSwapAccount = useNavigationApp({ to: LINKS.AUTH_SIGNIN })

  return (
    <div className="flex space-x-3">
      <Dropdown
        icon={
          <Avatar color="positive" size="xs">
            <Icon.User />
          </Avatar>
        }
        iconPosition="left"
        label="личный кабинет"
        color="white"
        variant="link"
        size="md"
        textClassName='font-medium'
        popperContentClassName="pt-5"
      >
        <div className="w-60 px-6 bg-white border-xs shadow-md rounded-md border-neutral-300">
          <div className="py-4 space-y-3">
            <Button onClick={handleToAds} icon={<Icon.Ad />} variant="link">
              Объявления
            </Button>
            {/* <Button onClick={handleToMessage} icon={<Icon.Comment />} variant="link">
              Сообщения
            </Button> */}
            <Button onClick={handleToFavorite} icon={<Icon.Favorite />} variant="link">
              Закладки
            </Button>
            <Button onClick={handleToNotification} icon={<Icon.Notification />} variant="link">
              Уведомоления
            </Button>
            <Button onClick={handleToSettings} icon={<Icon.Settings />} variant="link">
              Настройки
            </Button>
          </div>
          <div className="border-t-xs border-neutral-300 py-4 space-y-3">
            <Button icon={<Icon.Swap />} onClick={handleToSwapAccount} variant="link">
              Сменить аккаунт
            </Button>
            <Button icon={<Icon.Exit />} onClick={onLogout} variant="link">
              Выход
            </Button>
          </div>
        </div>
      </Dropdown>
    </div>
  )
}
