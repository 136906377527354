type FormatFunction = (prev: string, curr: number, str: string) => FormatFunction | string

/**
 *
 * @param amount сумма
 * @param currency валюта
 * @returns сумма в формате денег
 */
export const formatMoney = (amount: string | number, currency?: string | null): string => {
  const amountNumber = typeof amount !== 'number' ? parseFloat(amount.replace(/,/, '.')) : amount
  if (isNaN(amountNumber)) {
    console.error(`${amount} не число`)
    return ''
  }

  const sign: string = amountNumber < 0 ? '-' : ''

  const [left, right] = Math.abs(amountNumber).toFixed(2).split('.').splice(0, 2)

  const format: FormatFunction = (prev, curr, strReversed) => {
    if (curr === strReversed.length) {
      return prev
    }
    const newPrev = (curr + 1) % 3 === 0 ? ` ${strReversed[curr]}${prev}` : `${strReversed[curr]}${prev}`
    return format(newPrev, curr + 1, strReversed)
  }

  const lefFormatted = (format('', 0, left.split('').reverse().join('')) as string).trim()

  return `${sign}${lefFormatted},${right}${currency ? ` ${currency}` : ''}`
}
