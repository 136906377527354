import { FC, useCallback, useState, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Input, Button, notification } from '@platform/ui'
import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Title } from '@platform/ui'

const { userSubscriptionsService, layoutService } = core
const { screen } = layoutService

export const EmailSubscribe: FC = observer(() => {
  const [value, setValue] = useState<string>('')
  const [status, setStatus] = useState<Status>('pending')

  const handleSubscribe = useCallback(() => {
    setStatus('loading')

    userSubscriptionsService
      .SubscribeOnEmailDistribution(value)
      .then((res) => {
        setStatus('loaded')
        if (res.isSuccess) {
          setValue('')
          notification.success({ message: 'Вы успешно подписались на нашу рассылку' })
          return
        }

        notification.error({
          message: res.getError().toString(),
        })
      })
      .catch(() => {
        setStatus('loaded')
      })
  }, [value])

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  return (
    <div className="flex flex-col items-center shadow-lg pt-7 pb-9 md:pt-46px md:pb-88px rounded-xl justify-center px-4 md:px-34px lg:px-0 w-full">
      <Title
        tag="h2"
        weight="medium"
        colorNumber={900}
        color="neutral"
        size={screen.mode === ScreenMode.mobile ? 'lg' : '1xl'}
      >
        Выгода с нами
      </Title>
      <span className="font-medium text-sm md:text-md leading-md md:leading-4.8 text-center text-black mt-3.5 md:mt-4">
        Подпишитесь и получайте промокоды, акции и подборки товаров на свою почту.
      </span>
      <div className="relative w-full lg:w-1/2 mt-8">
        <Input.Group size={screen.mode === ScreenMode.mobile ? 'xl' : '2xl'}>
          <Input color="primary" placeholder="Введите e-mail" value={value} onChange={handleEmailChange} />
          <Button color="primary" onClick={handleSubscribe} loading={status === 'loading'}>
            Подписаться
          </Button>
        </Input.Group>
      </div>

      <span className="text-sm px-0 md:px-16 lg:px-0 text-center mt-3.5 md:mt-4">
        Нажимая «Подписаться» вы соглашаетесь с&nbsp;
        <Link to={'/'}>
          <span className="text-primary-500 underline">Условиями использования сайта</span>&nbsp;
        </Link>
        и&nbsp;
        <Link to={'/'}>
          <span className="text-primary-500 underline">Политикой обработки персональных данных.</span>&nbsp;
        </Link>
      </span>
    </div>
  )
})
