import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Accordion, AccordionGroup, AccordionItem, Checkbox } from '@platform/ui'

import { core } from '@src/core'

import { FilterSearchParam } from '../types'
import { CategoryFilterProps } from './types'

const { CATEGORIES } = FilterSearchParam
const { categoryService } = core

const DEFAULT_CATEGORY_ID = 1

export const CategoryFilter: FC<CategoryFilterProps> = observer(({ setSearchParam, deleteSearchParam }) => {
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const categories = useMemo(() => {
    if (categoryService.isEmpty) return []
    return [...categoryService.categories.findById(DEFAULT_CATEGORY_ID).childs]
  }, [categoryService.categories.list])

  useEffect(() => {
    if (!selected.length) {
      deleteSearchParam(CATEGORIES)
      return
    }
    setSearchParam(CATEGORIES, selected.join(','))
  }, [selected])

  const handleChange = useCallback((checked: boolean, value: string) => {
    setSelected((state) => {
      let newSelected = [...state]
      if (checked) {
        newSelected.push(value)
      } else {
        newSelected = newSelected.filter((item) => item !== value)
      }

      return newSelected
    })
  }, [])

  return (
    <Accordion>
      {categories.map((item) => {
        if (item.childs.length) {
          return (
            <AccordionGroup
              key={item.id}
              title={<span className="cursor-pointer font-medium text-lg">{item.name}</span>}
            >
              {item.childs.map((childItem) => (
                <AccordionItem
                  key={childItem.id}
                  title={
                    <Checkbox
                      onChangeChecked={handleChange}
                      value={String(childItem.id)}
                      isChecked={selected.includes(String(childItem.id))}
                    >
                      <span className="text-lg">{childItem.name}</span>
                    </Checkbox>
                  }
                />
              ))}
            </AccordionGroup>
          )
        }
        return (
          <AccordionItem
            key={item.id}
            title={<span className="cursor-pointer font-medium text-lg">{item.name}</span>}
          />
        )
      })}
    </Accordion>
  )
})
