import { useCallback, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSearchParamsApp = (isControlled?: boolean) => {
  const [browserSearchParams, setBrowserSearchParams] = useSearchParams()
  const [localSearchParams, setLocalSearchParams] = useState<URLSearchParams>(browserSearchParams)

  useEffect(() => {
    setLocalSearchParams(browserSearchParams)
  }, [browserSearchParams])

  const setSearchParam = useCallback(
    (key: string, value: string) => {
      if (!isControlled) {
        browserSearchParams.set(key, value)
        setBrowserSearchParams(browserSearchParams, { replace: true })
      } else {
        const newSearchParams = new URLSearchParams(localSearchParams)
        newSearchParams.set(key, value)
        setLocalSearchParams(newSearchParams)
      }
    },
    [browserSearchParams, localSearchParams, isControlled]
  )

  const deleteSearchParam = useCallback(
    (key: string) => {
      if (!isControlled) {
        browserSearchParams.delete(key)
        setBrowserSearchParams(browserSearchParams, { replace: true })
      } else {
        const newSearchParams = new URLSearchParams(localSearchParams)
        localSearchParams.delete(key)
        setLocalSearchParams(newSearchParams)
      }
    },
    [browserSearchParams, localSearchParams, isControlled]
  )

  const hasSearchParam = useCallback(
    (key: string) => (!isControlled ? browserSearchParams.has(key) : localSearchParams.has(key)),
    [browserSearchParams, localSearchParams, isControlled]
  )

  const getSearchParam = useCallback(
    (key: string) => (!isControlled ? browserSearchParams.get(key) : localSearchParams.get(key)),
    [browserSearchParams, localSearchParams, isControlled]
  )

  const clearBrowserSearchParams = useCallback(() => {
    setBrowserSearchParams(new URLSearchParams(), { replace: true })
  }, [])

  const clearLocalSearchParams = useCallback(() => {
    setLocalSearchParams(new URLSearchParams())
  }, [])

  const setLocalParamsInBrowser = useCallback(() => {
    setBrowserSearchParams(localSearchParams, { replace: true })
  }, [localSearchParams])

  const getAllParams = () => {
    const searchParams = !isControlled ? browserSearchParams : localSearchParams

    const result: Record<string, string> = {}
    searchParams.forEach((value, key) => {
      result[key] = value
    })
    return result
  }

  return {
    getAllParams,
    setSearchParam,
    deleteSearchParam,
    hasSearchParam,
    getSearchParam,
    clearBrowserSearchParams,
    clearLocalSearchParams,
    setLocalParamsInGlobal: setLocalParamsInBrowser,
    searchParams: !isControlled ? browserSearchParams : localSearchParams,
  }
}

export type useSearchParamsAppReturn = ReturnType<typeof useSearchParamsApp>
