import { BrowserRouter as ReactBrowserRouter } from 'react-router-dom'
import { Application } from '@src/application'

import '@src/styles/base.css'
import '@src/styles/components.css'
import '@src/styles/utilities.css'

export class BrowserRouter {
  public render() {
    return (
      <ReactBrowserRouter>
        <Application />
      </ReactBrowserRouter>
    )
  }
}

export default BrowserRouter
