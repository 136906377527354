export const faq = [
  'Как зарегистрироваться?',
  'Как создать объявление?',
  'Как участвовать в аукционе?',
  'Комиссионный сбор с успешных сделок',
]

export const news = [
  'Продлевайте действие продвижений автоматически',
  'Автоматическое создание объявления на грузоперевозку',
  'Уникальный дизайн своего интернет-магазина',
  'Требования к цене товара или услуги',
]

export const helpList = [
  {
    title: 'Аукцион',
    list: [
      'Как создать аукцион?',
      'Если победитель торгов отказался покупать',
      'Кто может участвовать в аукционе?',
      'Комиссия за участие в аукционе',
      'Как сделать ставку?',
      'Как работает автоматическая ставка?',
    ],
  },
  {
    title: 'Обмен',
    list: ['Как создать объявление типа обмен?', 'Как предложить обмен?'],
  },
  {
    title: 'Покупка',
    list: ['Как создать объявление типа покупка?', 'Как предложить обмен?'],
  },
  {
    title: 'Продажа',
    list: ['Регистрация и вход', 'Восстановление доступа'],
  },
  {
    title: 'Личный кабинет',
    list: ['Как создать объявление типа продажа?'],
  },
  {
    title: 'Безопасная сделка',
    list: ['Безопасная сделка для продавца', 'Безопасная сделка для покупателя'],
  },
  {
    title: 'Работа с объявлениями',
    list: ['Как создать объявление?', 'Как редактировать объявление?'],
  },
  {
    title: 'Услуги продвижения',
    list: ['Все рекламные возможности', 'Подключение и оплата услуг'],
  },
  {
    title: 'Корзина',
    list: ['Как добавить товар в корзину?'],
  },
  {
    title: 'Оплата',
    list: ['Способы оплаты', 'Ошибка при оплате'],
  },
]
