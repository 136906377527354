export enum AuctionTabs {
  seller = 1,
  rates = 2,
}

export type PropertyNode = {
  id: number | string
  name: string
  value: string
  measure: string | null
  description?: string
}

export type ProductContext = {
  onClickFavorite: () => void
}
