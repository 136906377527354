import { FC, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Input, Tag, Option } from '@platform/ui'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'
import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { isObject } from '@src/utils/helpers'
import { SuggestionCategoryDto } from '@platform/core/dist/modules/catalog/infra/dtos'

const { categories } = core.categoryService
const { createProduct } = core.createProductService
const { priceExchange, categoriesExchange, quantityExchange, detailsExchange, measure } = createProduct

export const PublishExchange: FC = observer(() => {
  const allCategories = categories.listAutocomplite.filter((item) => item.isLast)

  const [stateAutocomplete, setAutocomplete] = useState<string>('')
  const [availableСategories, setAvailableCategories] = useState(
    allCategories.filter((item) => !categoriesExchange.value?.includes(item.value))
  )
  const [selectedCategories, setSelectedCategories] = useState(
    allCategories.filter((item) => categoriesExchange.value?.includes(item.value))
  )

  const handlePriceChange = useCallback((value: string) => {
    priceExchange.changeValue(value)
  }, [])

  const handleQuantityChange = useCallback((value: string) => {
    quantityExchange.changeValue(value)
  }, [])

  const handleChangeAutocomplete = useCallback((option: Option) => {
    if (isObject(option)) {
      setAutocomplete(option.label || '')
      setAvailableCategories((categories) => categories.filter((item) => item.value !== option.value))
      setSelectedCategories((categories) => [...categories, option as SuggestionCategoryDto])
      categoriesExchange.changeValue([...(categoriesExchange.value || []), option.value])
    }
  }, [])

  const deleteSelectedCategory = useCallback((category: SuggestionCategoryDto) => {
    const categoryIndex = allCategories.findIndex((item) => item.value === category.value)
    setAvailableCategories((categories) => {
      const newCategories = [...categories]
      newCategories.splice(categoryIndex, 0, category)
      return newCategories
    })
    setSelectedCategories((categories) => categories.filter((item) => item.value !== category.value))
    categoriesExchange.changeValue((categoriesExchange.value || []).filter((item) => item !== category.value))
  }, [])

  const handleDetailsChange = useCallback((value: string) => {
    detailsExchange.changeValue(value)
  }, [])

  return (
    <div className="space-y-11">
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label
            required={!!priceExchange.required}
            label={priceExchange.label}
            textStyle="font-medium text-black text-sm"
          />
        }
        errorText={priceExchange.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={priceExchange.value ?? ''}
            onChange={handlePriceChange}
            className="w-44"
            size="lg"
            isDecimal
            error={!priceExchange.isValid}
          />
          <span className="ml-4 text-lg font-medium">₽</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label
            required={!!quantityExchange.required}
            label={quantityExchange.label}
            textStyle="font-medium text-black text-sm"
          />
        }
        errorText={quantityExchange.errorText}
      >
        <div className="flex items-center">
          <Input.Number
            value={quantityExchange.value ?? ''}
            onChange={handleQuantityChange}
            className="w-44"
            size="lg"
            isDecimal
            notMinus
            error={!quantityExchange.isValid}
          />
          <span className="ml-4 text-lg font-medium">{(measure.value as FieldSelectOption)?.name || ''}</span>
        </div>
      </FormItem>
      <div className="space-y-5">
        <FormItem
          labelClass="w-80"
          contentClass="w-full"
          label={
            <Label
              required={!!categoriesExchange.required}
              label={categoriesExchange.label}
              textStyle="font-medium text-black text-sm"
            />
          }
          errorText={categoriesExchange.errorText}
        ></FormItem>
        <Input.Autocomplete
          placeholder="Поиск категории"
          size="lg"
          value={stateAutocomplete}
          options={availableСategories}
          onChange={handleChangeAutocomplete}
        />
        <div className="flex items-center gap-2 flex-wrap w-full">
          {selectedCategories.map((item, index) => (
            <Tag
              key={index}
              color="primary"
              colorNumber={100}
              shape="round"
              className="roundex-xl"
              size="sm"
              closable
              onClose={() => deleteSelectedCategory(item)}
            >
              <span className="text-black">{item.name}</span>
            </Tag>
          ))}
        </div>
        <FormItem
          labelClass="w-80"
          contentClass="w-full"
          label={
            <Label
              required={!!detailsExchange.required}
              label={detailsExchange.label}
              textStyle="font-medium text-black text-sm"
            />
          }
          errorText={detailsExchange.errorText}
        ></FormItem>
        <Input
          className="w-md p-3.5"
          placeholder="Опишите товар, на который вы готовы обменять свой в деталях, и дополнительные условия обмена."
          multiline
          onChangeValue={handleDetailsChange}
          value={detailsExchange.value}
          rows={12}
          error={!detailsExchange.isValid}
        />
      </div>
    </div>
  )
})
