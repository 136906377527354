import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CartOffer, Info, Icon } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { ContextByPage } from '@src/pages/by/context'

const cartService = core.cartService
const authServce = core.authService

type Items = {
  userId: string
  totalPrice: number
  totalPriceFormat: string
}

export interface TotalProps {}

export const Totals = observer((props: TotalProps) => {
  const { totalPrice, groupItems = {} } = cartService.state
  const isAuthorized = authServce.isAuthorized
  const navigate = useNavigate()
  const location = useLocation()

  const context = React.useContext(ContextByPage)
  if (!context) {
    throw new Error('Context not found')
  }

  const userItems = Object.entries(groupItems).reduce<Items[]>((prev, curr) => {
    const [userId, { totalPrice, totalPriceFormat }] = curr
    prev.push({ userId, totalPrice, totalPriceFormat })
    return prev
  }, [])

  const handleClick = React.useCallback(() => {
    if (!isAuthorized) {
      navigate([LINKS.AUTH_SIGNIN, `redirect=${location.pathname}`].join('?'))
      return
    }

    const { steps, setStep, currentStep } = context

    const cloneSteps = [...steps]
    const currentIndex = cloneSteps.findIndex((x) => x === currentStep)
    const nextStep = cloneSteps.splice(currentIndex + 1).shift() || '0'

    setStep(nextStep)
  }, [isAuthorized, location, context, navigate])

  return (
    <CartOffer
      onClick={handleClick}
      price={totalPrice}
      items={userItems.map((item, i) => ({
        label: `Заказ №${i + 1}`,
        price: item.totalPriceFormat,
      }))}
    >
      <Info type="info" position="left" icon={Icon.Info} descClassName="text-neutral-900">
        Условия доставки можно будет настроить в деталях заказа. Для этого нажмите продолжить!
      </Info>
    </CartOffer>
  )
})
