import React from 'react'
import { observer } from 'mobx-react-lite'
import { CardOrder } from '@platform/ui'
import { QuantityType } from '@platform/core/dist/api/v1/cart'
import { CartItem } from '@platform/core/dist/modules/order/domain/CartItem'

import { core } from '@src/core'

const cartService = core.cartService

export interface ProductProps {
  product: CartItem
}

export const Product = observer(({ product }: ProductProps) => {
  const { id, entityId, image, title, price, rating, quantity, status, isFavorite, quantityLimit, limitQuantity } =
    product

  const handleRemove = React.useCallback(() => cartService.clickRemoveCartItem(id), [id])
  const handleFavorite = React.useCallback(() => cartService.toggleFavorite(id), [id])
  const handleUpdate = React.useCallback(
    (_, newQuantity: number) => {
      const type = quantity > newQuantity ? QuantityType.minus : QuantityType.plus
      const count = type === QuantityType.minus ? quantity - newQuantity : newQuantity - quantity
      cartService.clickUpdateCartItem({ entityId, quantity: count, quantityType: type })
    },
    [entityId, quantity]
  )

  return (
    <CardOrder
      key={id}
      src={image}
      title={title}
      isLoading={status === 'loading'}
      isFavorite={isFavorite}
      price={price}
      rating={rating}
      count={quantity}
      maxCount={limitQuantity}
      onBlur={handleUpdate}
      onUpArrow={handleUpdate}
      onDownArrow={handleUpdate}
      onClickRemove={handleRemove}
      onClickFavorite={handleFavorite}
    />
  )
})
