import React, { useCallback } from 'react'

import { core } from '@src/core'
import { Button, Icon } from '@platform/ui'
import { Flow } from '@src/components/common/flow'

import { SocialBlockProps } from './types'

export const SocialBlock: React.FC<SocialBlockProps> = ({ title }) => {
  const handleVkontakte = useCallback(() => {
    core.authService.authVk()
  }, [])

  const handleOdnoklasniki = useCallback(() => {
    core.authService.authOk()
  }, [])

  const handleMailRu = useCallback(() => {
    core.authService.authMailRU()
  }, [])

  const handleGoogle = useCallback(() => {
    core.authService.authGoogle()
  }, [])

  const handleYandex = useCallback(() => {
    core.authService.authYandex()
  }, [])

  const handleMicrosoft = useCallback(() => {
    core.authService.authMicrosoft()
  }, [])

  return (
    <Flow directory="col" className="pt-14px md:pt-7 border-t-xs border-neutral-300" space={3.5}>
      <span className="text-xs md:text-sm text-neutral-400">{title}</span>
      <Flow className="justify-between">
        <Button size="md" className="p-0" icon={<Icon.Vk />} variant="outline" onClick={handleVkontakte}></Button>
        <Button
          size="md"
          className="p-0"
          icon={<Icon.Microsoft />}
          variant="outline"
          onClick={handleMicrosoft}
        ></Button>
        <Button size="md" className="p-0" icon={<Icon.Ok />} variant="outline" onClick={handleOdnoklasniki}></Button>
        <Button size="md" className="p-0" icon={<Icon.MailRu />} variant="outline" onClick={handleMailRu}></Button>
        <Button size="md" className="p-0" icon={<Icon.Google />} variant="outline" onClick={handleGoogle}></Button>
        <Button size="md" className="p-0" icon={<Icon.Yandex />} variant="outline" onClick={handleYandex}></Button>
      </Flow>
    </Flow>
  )
}
