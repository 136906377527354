import { FC, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Flow } from '@src/components/common/flow'
import { Button, Icon } from '@platform/ui'
import { ProductList } from '@src/components/desktop/product-list'
import { SearchParamsFilters } from '../../components/search-params-filters'

import { UseLocationProducts } from '../../hooks/use-location-products'
import { MapSidebarProps } from './types'

export const MapSidebar: FC<MapSidebarProps> = observer(({ activeLocation, onBackCallback, params }) => {
  const searchParams = useMemo(() => {
    return { size: 10, ...params }
  }, [params])

  const { products, status, isLast, resetState, loadNextPage } = UseLocationProducts({
    params: searchParams,
    hash: activeLocation?.hash || null,
    count: activeLocation?.count || null,
  })

  const handleBackClick = useCallback(() => {
    resetState()
    onBackCallback()
  }, [onBackCallback, resetState])

  return (
    <Flow
      className="bg-white rounded-xl py-7 px-8 h-530px w-345px overflow-y-scroll overflow-x-hidden scrollbar"
      directory="col"
      space={7}
    >
      {!activeLocation && <SearchParamsFilters />}
      {!!activeLocation && (
        <Flow directory="col" space={7} align="start">
          <Button
            variant="link"
            size="xl"
            color="primary"
            icon={<Icon.ArrowLong position="left" />}
            onClick={handleBackClick}
          >
            Назад к фильтрам
          </Button>
          <ProductList
            loadMoreWrapperClass="py-4"
            products={products}
            requestStatus={status}
            loadMore={loadNextPage}
            isLastPage={isLast}
            forceFull
          />
        </Flow>
      )}
    </Flow>
  )
})
