import { Flow } from '@src/components/common/flow'
import { Button } from '@platform/ui'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { PersonalControlProps } from './types'

export const PersonalControl: FC<PersonalControlProps> = ({ productId }) => {
  const navigate = useNavigate()

  const handleToEditProduct = useCallback(() => {
    navigate(`/product/edit/${productId}`)
  }, [])

  return (
    <Flow directory="col" space={3}>
      {/* <Button size="xl" color="primary" disabled textClassName="text-xs md:text-sm font-semibold">
        Ускорить продажу
      </Button> */}
      <Button
        size="xl"
        variant="outline"
        onClick={handleToEditProduct}
        textClassName="text-xs md:text-sm font-semibold"
      >
        Редактировать объявление
      </Button>
    </Flow>
  )
}
