import { FilterSearchParam } from './types'
import { SortParam } from './product-sort'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { isStringOnlyDigits } from '@src/utils/helpers'
import { ProductTag } from '@platform/core/dist/models'

const { SORT, PRICE, EXTRA, EXCHANGE_CATEGORY, PRODUCT_TYPE } = FilterSearchParam

export const validateSearchParam = (param: string, value: Nullable<string>): boolean => {
  if (!value) {
    return false
  }

  switch (param) {
    case SORT:
      return [SortParam.NEW, SortParam.OLD, SortParam.PRICE_ASC, SortParam.PRICE_DESС].includes(value as any)
    case PRICE:
      const [min, max] = value.split(',')
      return isStringOnlyDigits(min) && isStringOnlyDigits(max)
    case PRODUCT_TYPE:
      return [ProductTypeDTO.auction, ProductTypeDTO.buy, ProductTypeDTO.exchange, ProductTypeDTO.sell].includes(
        value as any
      )
    case EXCHANGE_CATEGORY:
      return value.split(',').some(isStringOnlyDigits)
    case EXTRA:
      const values = value.split(',')
      return values.some((item) => [ProductTag.DISCOUNT, ProductTag.VIP].includes(item as any))
    default:
      break
  }

  return false
}
