import React, { Suspense } from 'react'
import { observer, enableStaticRendering } from 'mobx-react-lite'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import { Layout } from '@src/container/layout'
import { useScreenMode } from '@src/hooks/use-screen-mode'
import { Error } from '@src/components/common/error'

import ByPage from './pages/by'
import HomePage from './pages/home'
import AuthPage from './pages/auth'
import CreatePage from './pages/create'
import SearchPage from './pages/search'
import ProductPage from './pages/product'
import ProfilePage from './pages/profile'
import CategoryPage from './pages/category'
import NotFound from './pages/not-found'
import MapPage from './pages/map'
import HelpPages from './pages/help'

const AboutUs = React.lazy(() => import('./pages/static/about'))
const ForBusiness = React.lazy(() => import('./pages/static/for-business'))
const PromoAuction = React.lazy(() => import('./pages/static/promo/auction'))
const PromoExchange = React.lazy(() => import('./pages/static/promo/exchange'))

export const Application = observer(() => {
  useScreenMode()

  return (
    <React.StrictMode>
      <Suspense fallback={'loading...'}>
        <ErrorBoundary FallbackComponent={Error}>
          <HelmetProvider>
            <Routes>
              <Route element={<Layout />}>
                <Route path={ROUTES.HOME.INDEX} element={<HomePage />} />
                <Route path={ROUTES['ABOUT-US'].INDEX} element={<AboutUs />} />
                <Route path={ROUTES['AUCTION'].INDEX} element={<PromoAuction />} />
                <Route path={ROUTES['EXCHANGE'].INDEX} element={<PromoExchange />} />
                <Route path={ROUTES['FOR-BUSINESS'].INDEX} element={<ForBusiness />} />

                <Route path={ROUTES.AUTH.INDEX} element={<AuthPage />} />
                <Route path={ROUTES.BY.INDEX} element={<ByPage />} />
                <Route path={ROUTES.PROFILE.INDEX} element={<ProfilePage />} />
                <Route path={ROUTES.CREATE.INDEX} element={<CreatePage />} />
                <Route path={ROUTES.PRODUCT.INDEX} element={<ProductPage />} />
                <Route element={<SearchPage />} />
                <Route path={ROUTES.CATEGORY.INDEX} element={<CategoryPage />} />
                <Route path={ROUTES.MAP.INDEX} element={<MapPage />} />
                <Route path={ROUTES.HELP.INDEX} element={<HelpPages />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </HelmetProvider>
        </ErrorBoundary>
      </Suspense>
    </React.StrictMode>
  )
})

enableStaticRendering(process.env.APP_ENV === 'server')
