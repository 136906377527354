import React from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { CardOrder } from '@platform/ui'

import { Product } from './product'

const cartService = core.cartService

export interface ProductsProps {
  userId: string
}

export const Products = observer((props: ProductsProps) => {
  const { userId } = props
  const { items, groupItems } = cartService.state
  const { entityIds } = groupItems[userId]

  const hashItemMap = React.useMemo(() => {
    const map = new Map()
    items.forEach((item) => map.set(item.entityId, item))
    return map
  }, [items])

  return (
    <>
      {entityIds.map((entityId) => {
        const product = hashItemMap.get(entityId)
        return <Product key={product.id} product={product} />
      })}
    </>
  )
})
