import { FC } from 'react'
import { NavBar } from '@src/components/common/nav-bar'

import { HeaderWrapperProps } from './types'

export const HeaderWrapper: FC<HeaderWrapperProps> = ({ children }) => {
  return (
    <header className="text-white z-header">
      <div className="bg-primary-500">
        <div className="content-main py-2">{children}</div>
      </div>
      <div className="bg-neutral-100">
        <div className="content-main py-1.5">
          <NavBar />
        </div>
      </div>
    </header>
  )
}
