import { FC } from 'react'
import { ProductList } from '@src/components/desktop/product-list'
import { useFilteredProducts } from '@src/hooks/use-filtered-products'

import { UserProductsProps } from './types'

export const UserProducts: FC<UserProductsProps> = ({ userId }) => {
  const { requestStatus, products } = useFilteredProducts({
    params: { userId, size: 4 },
  })

  return <ProductList products={products} requestStatus={requestStatus} />
}
