import { FC } from 'react'
import {
  ProductTagFilter,
  CategoryFilter,
  DeliveryFilter,
  PaymentMethodFilter,
  UserFilter,
  AddressFilter,
  PriceInputFilter,
} from '@src/search-params-settings'
import { Flow } from '@src/components/common/flow'
import { useMapFilterParamsContext } from '../../context'

export const SearchParamsFilters: FC = () => {
  const context = useMapFilterParamsContext()

  return (
    <Flow directory="col" space={7} align="start">
      <div className="pb-7 border-b-xs border-neutral-300 w-full">
        <AddressFilter {...context} />
      </div>
      <PriceInputFilter {...context} />
      <UserFilter {...context} />
      <CategoryFilter {...context} />
      <DeliveryFilter {...context} />
      <PaymentMethodFilter {...context} />
      <ProductTagFilter {...context} />
    </Flow>
  )
}
