import { FC, useEffect, useRef, useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { FileUpload, Button, Icon } from '@platform/ui'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { Section } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'

import { CreateProductContext } from '../context'

const { createProduct } = core.createProductService
const { file } = createProduct

export const ImageUpload: FC = observer(() => {
  const nodeFile = useRef<HTMLDivElement>(null)
  const [handleToFile] = useScrollIntoView({ ref: nodeFile })
  const context = useContext(CreateProductContext)

  useEffect(() => {
    context?.addFields([{ key: file.key, handleScroll: handleToFile, orderMarkup: 1 }])
  }, [])

  return (
    <Section>
      <FormItem
        className="flex-col space-y-8"
        label={<Label required={file.required} label={file.label} textStyle="text-lg md:text-1xl font-medium" />}
        errorText={file.errorText ?? ''}
      >
        <div ref={nodeFile}>
          <FileUpload
            files={file.value}
            onChange={file.changeValue}
            isInvalid={!file.isValid}
            accept={{
              'image/jpeg': [],
              'image/jpg': [],
              'image/png': [],
            }}
            className="h-80 w-sm"
            wrappClassName="max-w-max"
          >
            <Flow directory="col" align="center" space={6}>
              <div>
                <Icon.Image size="3xl" className="text-neutral-900" />
              </div>
              <Button className="m-4" variant="outline" color="black">
                Добавить изображения
              </Button>
              <div className="text-sm text-center text-neutral-400">
                Вы можете добавить до 12 фотографий. Мы не принимаем фотографии с текстом или дополнительными рамками.
              </div>
            </Flow>
          </FileUpload>
        </div>
      </FormItem>
    </Section>
  )
})
