import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Title } from '@platform/ui'

import { core, ScreenMode } from '@src/core'
import { Loading } from '@src/components/common/loading'

const { categoryService, layoutService } = core
const { screen } = layoutService

export const Banner: FC = observer(() => {
  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  if (categoryService.isLoading) return <Loading />

  return (
    <Title color="neutral" colorNumber={900} size={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'} weight="semibold">
      <h1 className="lg:flex lg:flex-col">
        <span>
          Всероссийский маркетплейс, где можно найти{' '}
          <Link to={'/category?sorting=new'}>
            <span className="text-primary-500 underline">все,что нужно.</span>
          </Link>
        </span>
        <span>Быстро, безопасно и прозрачно.</span>
      </h1>
    </Title>
  )
})
