import { FC, useCallback, useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { core } from '@src/core'
import { Input, Counter } from '@platform/ui'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { Flow } from '@src/components/common/flow'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'

import { CreateProductContext } from '../context'

const { createProduct } = core.createProductService
const { priceAuction, priceStepAuction, lotCountAuction, lotSizeAuction, measure } = createProduct

export const PublishAuction: FC = observer(() => {
  const context = useContext(CreateProductContext)

  const handlePriceChange = useCallback((value: string) => {
    priceAuction.changeValue(value)
  }, [])

  const handleLotCountChange = useCallback((value: string) => {
    lotCountAuction.changeValue(value)
  }, [])

  const handlelotSizeChange = useCallback((value: string) => {
    lotSizeAuction.changeValue(value)
  }, [])

  const handleBlurPrice = useCallback((value: string, numValue?: number) => {
    if (!numValue) return
    createProduct.changePriceStepValues(numValue)
  }, [])

  const handleCounterUp = useCallback(() => {
    priceStepAuction.changeIndex(priceStepAuction.index + 1)
  }, [priceStepAuction.index])

  const handleCounterDown = useCallback(() => {
    priceStepAuction.changeIndex(priceStepAuction.index - 1)
  }, [priceStepAuction.index])

  return (
    <div className="space-y-11">
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        errorText={priceAuction.errorText}
        label={
          <Label
            required={!!priceAuction.required}
            label={priceAuction.label}
            textStyle="font-medium text-black text-sm"
          />
        }
      >
        <div className="flex items-center">
          <Input.Number
            disabled={context?.isEdit}
            value={String(priceAuction.value ?? '')}
            onChange={handlePriceChange}
            onBlur={handleBlurPrice}
            className="w-44"
            size="lg"
            error={!priceAuction.isValid}
            isDecimal
            notMinus
          />
          <span className="ml-4 text-lg font-medium">₽</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        errorText={lotSizeAuction.errorText}
        label={
          <Label
            required={!!lotSizeAuction.required}
            label={lotSizeAuction.label}
            textStyle="font-medium text-black text-sm"
          />
        }
      >
        <div className="flex items-center">
          <Input.Number
            disabled={context?.isEdit}
            value={String(lotSizeAuction.value ?? '')}
            onChange={handlelotSizeChange}
            className="w-44"
            size="lg"
            error={!lotSizeAuction.isValid}
            notMinus
          />
          <span className="ml-4 text-lg font-medium">{(measure.value as FieldSelectOption)?.name || ''}</span>
        </div>
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        errorText={lotCountAuction.errorText}
        label={
          <Label
            required={!!lotCountAuction.required}
            label={lotCountAuction.label}
            textStyle="font-medium text-black text-sm"
          />
        }
      >
        <Input.Number
          disabled={context?.isEdit}
          value={String(lotCountAuction.value ?? '')}
          onChange={handleLotCountChange}
          className="w-44"
          size="lg"
          error={!lotCountAuction.isValid}
          notMinus
        />
      </FormItem>
      <FormItem
        labelClass="w-80"
        contentClass="w-full"
        label={
          <Label
            label={priceStepAuction.label}
            hint="Максимальный шаг цены не должен 
            превышать 5% стартовой цены."
            textStyle="font-medium text-black text-sm"
          />
        }
      >
        {context?.isEdit ? (
          <span className="font-medium text-xl">{priceStepAuction.value}</span>
        ) : (
          <Flow align="center" space={3}>
            <Flow directory="col" space={0.5} align="center">
              <span>{priceStepAuction.listValues.at(0)}</span>
              <span className="text-xss font-semibold text-neutral-400">мин</span>
            </Flow>
            <Counter
              onClickUp={handleCounterUp}
              onClickDown={handleCounterDown}
              shape="round"
              disabled={context?.isEdit}
            >
              <span className="px-3 text-center">{priceStepAuction.value}</span>
            </Counter>
            <Flow directory="col" space={0.5} align="center">
              <span>{priceStepAuction.listValues.at(-1)}</span>
              <span className="text-xss font-semibold text-neutral-400">макс</span>
            </Flow>
          </Flow>
        )}
      </FormItem>
    </div>
  )
})
