import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { LINKS } from '@src/routes'
import { Title } from '@platform/ui'
import { Flow, Directory, Align } from '@src/components/common/flow'
import { CategoryBlock } from './components/category-block'
import { Grid, GridCell } from '@platform/ui'

import { useScreenModeValue } from '@src/hooks'

import { faq, news, helpList } from './mocks'

export const Home: FC = observer(() => {
  const width = useScreenModeValue({
    desktop: 2,
    tablet: 4,
    mobile: 8,
  })

  const directory = useScreenModeValue<Directory>({
    desktop: 'row',
    tablet: 'row',
    mobile: 'col',
  })

  const align = useScreenModeValue<Align>({
    desktop: 'center',
    tablet: 'center',
    mobile: 'start',
  })

  return (
    <Flow directory="col" space={'88px'}>
      <Flow directory="col" className="pt-7 content-main" space={7}>
        <Breadcrumbs items={[{ children: 'Главная', href: LINKS.HOME }, { children: 'Помощь' }]} />
        <Title weight="bold" tag="h1" textClassName="text-1xl md:text-2xl">
          Помощь
        </Title>
      </Flow>
      <div className="w-full bg-primary-500 py-9">
        <Flow full directory={directory} className="content-main" align={align} space={5}>
          <CategoryBlock color="white" title="Частые вопросы" topicList={faq} />
          <div className="bg-white rounded-xl px-8 py-7">
            <CategoryBlock color="black" title="Новости" topicList={news} />
          </div>
        </Flow>
      </div>
      <Flow directory="col" className="content-main">
        <Grid className="gap-x-5 gap-y-20">
          {helpList.map((item, key) => (
            <GridCell key={key} width={width}>
              <CategoryBlock title={item.title} topicList={item.list} color="black"></CategoryBlock>
            </GridCell>
          ))}
        </Grid>
      </Flow>
    </Flow>
  )
})
