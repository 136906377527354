import { FC, useCallback, useEffect, useState } from 'react'
import { Select, SelectOption } from '@platform/ui'
import { isObject } from '@src/utils/helpers'

import { FilterSearchParam } from '../types'
import { ProductSortProps, SortParam } from './types'
import { sortOptions } from './constants'
import { validateSearchParam } from '../helper'

const { SORT } = FilterSearchParam

export const ProductSort: FC<ProductSortProps> = ({ hasSearchParam, getSearchParam, setSearchParam, searchParams }) => {
  let defaultOption = sortOptions[0]
  if (validateSearchParam(SORT, getSearchParam(SORT))) {
    defaultOption = sortOptions.find((item) => item.value === (getSearchParam(SORT) as SortParam)) as SelectOption
  }
  const [state, setState] = useState(defaultOption)

  useEffect(() => {
    if (hasSearchParam(SORT)) {
      const option = sortOptions.find((item) => item.value === (getSearchParam(SORT) as SortParam)) as SelectOption
      setState(option)
    } else {
      setState(sortOptions[0])
    }
  }, [searchParams])

  const handleChange = useCallback(
    (option: SelectOption) => {
      if (isObject(option)) {
        setState(option)
        setSearchParam(SORT, option.value as SortParam)
      }
    },
    [setSearchParam]
  )

  return (
    <Select
      variant="outline"
      className="px-3.5 text-sm font-normal"
      size="md"
      options={sortOptions}
      value={state}
      onChange={handleChange}
    />
  )
}
