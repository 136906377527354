import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox } from '@platform/ui'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FieldCheckboxInterface } from '@platform/core/dist/modules/product/domain/fields/models'
import { FieldCheckboxOption } from '@platform/core/dist/modules/product/domain/fields/models'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldCheckbox: FC<Detail> = observer(({ id }) => {
  const {
    changeChecked,
    label,
    required,
    isMulti,
    value: fieldValue,
    errorText,
    description,
  } = createProduct.fields.find((item) => item.id === id) as FieldCheckboxInterface

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const { checked } = event.target
      changeChecked(value, checked)
    },
    [changeChecked]
  )

  return (
    <div className="pt-2 space-y-2">
      <Label
        required={required}
        label={label}
        size="sm"
        textStyle="font-medium text-black text-sm"
        tooltipText={description}
      />
      {isMulti ? (
        <div className="flex flex-wrap">
          {(fieldValue as FieldCheckboxOption[]).map((item, key) => (
            <Checkbox key={key} defaultChecked={item.checked} onChange={(event) => handleChange(event, item.value)}>
              {item.label}
            </Checkbox>
          ))}
        </div>
      ) : (
        <Checkbox
          defaultChecked={(fieldValue as FieldCheckboxOption).checked}
          onChange={(event) => handleChange(event, (fieldValue as FieldCheckboxOption).value)}
        ></Checkbox>
      )}
      <div className="mt-2 text-invalid">{errorText}</div>
    </div>
  )
})
