import { forwardRef } from 'react'
import { observer } from 'mobx-react-lite'
import { core, ScreenMode } from '@src/core'

import { Section } from './section'
import { SectionHProps } from './types'

const { layoutService } = core
const { screen } = layoutService

export const SectionH1 = observer(
  forwardRef<HTMLDivElement, SectionHProps>((props, ref) => (
    <Section
      {...props}
      ref={ref}
      tag="h1"
      weight="semibold"
      titleContainerClassName="pb-9 md:pb-46px"
      titleClassName="leading-xl md:leading-3xl"
      sizeTitle={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}
    />
  ))
)
