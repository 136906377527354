import { FC, useEffect, useRef, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Input } from '@platform/ui'

import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { Section } from '@src/components/common/section'

import { CreateProductContext } from '../context'

const { createProduct } = core.createProductService
const { description } = createProduct

export const Description: FC = observer(() => {
  const nodeDescription = useRef<HTMLInputElement>(null)
  const [handleToDescription] = useScrollIntoView({ ref: nodeDescription })
  const context = useContext(CreateProductContext)

  useEffect(() => {
    context?.addFields([{ key: description.key, handleScroll: handleToDescription, orderMarkup: 2 }])
  }, [])

  return (
    <Section>
      <FormItem
        className="flex-col space-y-8"
        label={
          <Label
            required={description.required}
            label={description.label}
            textStyle="text-lg md:text-1xl font-medium"
          />
        }
        errorText={description.errorText ?? ''}
      >
        <Input
          className="w-md p-3.5"
          placeholder="Опишите товар, который вы размещаете, и предоставьте полную и точную информацию. Используйте точный и лаконичный формат."
          ref={nodeDescription}
          multiline
          onChangeValue={description.changeValue}
          value={description.value}
          rows={12}
          error={!description.isValid}
        />
      </FormItem>
    </Section>
  )
})
