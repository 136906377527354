import { useCallback, FC, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Button, Input, Switch, ButtonFavorite, Info, Icon } from '@platform/ui'
import { AuctionStateDto } from '@platform/core/dist/modules/auction/infra/dtos/AuctionStateDto'
import { FieldCheckboxOption } from '@platform/core/dist/modules/product/domain/fields/models'

import { Flow } from '@src/components/common/flow'
import { core } from '@src/core'
import { Price } from '@src/container/price'
import { TooltipInfo } from '@src/components/common/tooltip-info'
import { Loading } from '@src/components/common/loading'
import { formatMoney } from '@src/utils/formatMoney'
import { formatTime, formatDate, parseMilliseconds } from '@src/utils/helpers'

import { useProductContext } from './hooks/useProductContext'
import { PersonalControl } from './components/personal-control'

const { productService, auctionService, authService } = core
const { product } = productService
const { createBit, auction } = auctionService
const { authorizedUser } = authService

export const ProductAuction: FC = observer(() => {
  const params = useParams()
  const productId = Number(params['id'])

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const { onClickFavorite } = useProductContext()

  useEffect(() => {
    auctionService.fetchAuctionInfo(productId)
    return () => {
      auctionService.reset()
    }
  }, [productId])

  useEffect(() => {
    if (auction.state === AuctionStateDto.active && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        auctionService.fetchAuctionInfo(productId)
        auctionService.fetchBitList(productId)
      }, 10000)
    }
  }, [auction.state])

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [])

  const handleLotsChange = useCallback((value: string, numValue: number) => {
    createBit.lotCountField.changeValue(value)
    createBit.lotCountFieldNumber.changeValue(numValue)
  }, [])

  const handlePriceChange = useCallback((value: string, numValue: number) => {
    createBit.priceField.changeValue(value)
    createBit.priceFieldNumber.changeValue(numValue)
  }, [])

  const handleClickCreateBit = useCallback(() => {
    if (product.data) {
      auctionService.makeBit(product.data.id)
    }
  }, [])

  const handleChangeAutoBit = useCallback(
    (checked: boolean, value: string) => createBit.isAutoBitField.changeChecked(value, checked),
    []
  )

  const currentPrice = useMemo(() => {
    const { currentBitPrice, startBitPrice } = auction
    if (currentBitPrice) {
      return parseFloat(currentBitPrice)
    }
    return Number(startBitPrice)
  }, [auction.currentBitPrice, auction.startBitPrice])

  if (!product.data) return null

  if (auction.statusFetchInfo === 'loading') {
    return <Loading />
  }

  return (
    <>
      <Price
        value={currentPrice}
        measure={product.data.measure.name}
        description={auction.currentBitPrice ? 'Текущая цена за 1' : 'Стартовая цена за 1'}
      />
      <Flow className="justify-between font-semibold">
        <Flow directory="col" space={1} align="center">
          <Flow className="text-lg" align="center" space={1}>
            <span> {`${auction.lotCount} `}</span>
            <span className="text-sm">{`(${Number(auction.lotCount) * Number(auction.lotSize)} ${
              product.data.measure.name
            })`}</span>
          </Flow>
          <span className="text-xss text-neutral-400">кол-во лотов</span>
        </Flow>
        <Flow directory="col" space={1} align="center">
          <span className="text-lg">{`${auction.lotSize} ${product.data.measure.name}`}</span>
          <span className="text-xss text-neutral-400">размер лота</span>
        </Flow>
        <Flow directory="col" space={1} align="center">
          <span className="text-lg">{auction.priceStep}</span>
          <span className="text-xss text-neutral-400">шаг цены</span>
        </Flow>
      </Flow>

      {authorizedUser.user?.id === product.data.userId && <PersonalControl productId={product.data.id} />}

      {authorizedUser.user?.id !== product.data.userId && auction.state === AuctionStateDto.active && (
        <>
          <Flow directory="col" space={1.5}>
            <span className="text-xs">{`Количество лотов : `}</span>
            <Input.Number
              size="sm"
              min={1}
              max={Number(auction.lotCount)}
              arrowRight
              value={createBit.lotCountField.value}
              onClickDownArrow={handleLotsChange}
              onClickUpArrow={handleLotsChange}
              notMinus
              readOnly
            />
          </Flow>
          <Flow directory="col" space={1.5}>
            <span className="text-xs">
              {(createBit.isAutoBitField.value as FieldCheckboxOption).checked
                ? `Ваша макс. цена за ${product.data.measure.name} (в руб)`
                : `Ваша цена за ${product.data.measure.name} (в руб.):`}
            </span>
            <Input.Number
              size="sm"
              arrowRight
              step={Number(auction.priceStep)}
              min={currentPrice + Number(auction.priceStep)}
              defaultValue={currentPrice + Number(auction.priceStep)}
              value={createBit.priceField.value}
              onClickDownArrow={handlePriceChange}
              onClickUpArrow={handlePriceChange}
              isDecimal
              notMinus
              readOnly
            />
          </Flow>
          <Switch
            isChecked={(createBit.isAutoBitField.value as FieldCheckboxOption).checked}
            onChangeChecked={handleChangeAutoBit}
            value={(createBit.isAutoBitField.value as FieldCheckboxOption).value}
          >
            <Flow space={2} align="center">
              <span> Автоматическая ставка</span>
              <TooltipInfo text="" labelText="бот сам ставит" />
            </Flow>
          </Switch>
          {!!createBit.priceFieldNumber.value && !!createBit.lotCountFieldNumber.value && (
            <Flow directory="col" space={1.5}>
              <span className="text-neutral-400">
                {(createBit.isAutoBitField.value as FieldCheckboxOption).checked ? 'Итого макс.:' : 'Итого:'}
              </span>
              <span className="text-1xl font-semibold">
                {formatMoney(
                  createBit.lotCountFieldNumber.value * createBit.priceFieldNumber.value * Number(auction.lotSize),
                  '₽'
                )}
              </span>
            </Flow>
          )}
          <Flow className="justify-between" full align="center">
            <Button
              size="xl"
              color="primary"
              className="w-5/6"
              textClassName="text-xs md:text-sm font-semibold"
              loading={auction.statusCreateBit === 'loading'}
              onClick={handleClickCreateBit}
            >
              Сделать ставку
            </Button>
            <ButtonFavorite isActive={product.data.isFavorite} onClick={onClickFavorite} />
          </Flow>
          <Flow space={3} align="start">
            <Icon.Time color="black" size="1xl" />
            <Flow directory="col" space={2}>
              <span>
                <span className="font-medium text-lg">{`${parseMilliseconds(auction.timeLeft)} `}</span>
                до завершения
              </span>
              <span className="text-neutral-400">{`(${formatDate(auction.endDate)} в ${formatTime(
                auction.endDate
              )})`}</span>
            </Flow>
          </Flow>
        </>
      )}
      {authorizedUser.user?.id !== product.data.userId && auction.state === AuctionStateDto.closed && (
        <Info
          size="2xl"
          type="info"
          color="secondary"
          position="left"
          className="items-center bg-primary-100 rounded-xl"
        >
          <span className="text-md text-black">Аукцион завершился.</span>
        </Info>
      )}
      {authorizedUser.user?.id !== product.data.userId && auction.state === AuctionStateDto.notActive && (
        <Flow directory="col" space={3}>
          <Info
            size="2xl"
            type="info"
            color="secondary"
            position="left"
            className="items-center bg-primary-100 rounded-xl"
          >
            <span className="text-md text-black">
              Аукцион ещё не начался. Сделать ставку можно будет только после старта.
            </span>
          </Info>
          <Button
            icon={product.data.isFavorite ? <Icon.FavoriteFull color="negative" /> : <Icon.Favorite color="primary" />}
            variant="link"
            color={'primary'}
            className=" font-normal text-sm"
            iconPosition="left"
            onClick={onClickFavorite}
          >
            {product.data.isFavorite ? 'Больше не отслеживать' : 'Отслеживать'}
          </Button>
        </Flow>
      )}
    </>
  )
})
