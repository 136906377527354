import { createContext, useContext } from 'react'

export interface AppLayoutContextProps {
  mainNode: React.RefObject<HTMLElement>
}

export const AppLayoutContext = createContext<AppLayoutContextProps | null>(null)

export const AppLayoutProvider = AppLayoutContext.Provider

export const useAppLayoutContext = (): AppLayoutContextProps => {
  const context = useContext(AppLayoutContext)
  if (!context) {
    throw new Error('Not found: AppLayoutContext')
  }

  return context
}
