import React, { useState, useEffect } from 'react'
import { Comment } from '@src/components/common/comment'
import { core } from '@src/core'
import { ProductCommentDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { Loading } from '@src/components/common/loading/loading'
import { CommentsProps } from './types'

const { productService } = core

export const Comments: React.FC<CommentsProps> = (props) => {
  const { className, productId } = props
  const [status, setStatus] = useState<Status>('pending')
  const [comments, setComments] = useState<ProductCommentDTO[]>([])

  useEffect(() => {
    setComments([])
    setStatus('loading')

    productService
      .fetchProductComments(productId)
      .then((res) => {
        if (res.isSuccess) {
          const data = res.getValue()
          setComments(data.splice(0, 2))
        }
      })
      .then(() => setStatus('loaded'))
  }, [])

  return (
    <div className={className}>
      {status === 'loading' && <Loading />}
      {status === 'loaded' && (
        <div className="w-full flex flex-col space-y-12">
          {comments.length ? (
            <>
              {comments.map((comment) => (
                <Comment key={comment.id} {...comment} />
              ))}
              <button className="w-full border-xs py-2">Посмотреть все отзывы</button>
            </>
          ) : (
            'Здесь пока нет отзывов.'
          )}
        </div>
      )}
    </div>
  )
}
