import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SectionH1 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { core } from '@src/core'

import { useProfileOutletContext } from './hooks/useProfileContext'
import { Notification } from './components/notification'

const { notificationsService } = core
const { userNotifications } = notificationsService

export const Notifications: FC = observer(() => {
  const { backCallback } = useProfileOutletContext()

  useEffect(() => {
    notificationsService.fetchNotifications({
      size: 20,
    })
  }, [])

  return (
    <SectionH1 title="Уведомления" titleClassName="ml-5" onBackClick={backCallback}>
      <Flow directory="col" space={11}>
        {userNotifications.list.map(({ id, message, timeLeft }) => (
          <Notification key={id} message={message} timeAt={timeLeft} title={'Заголовок'} />
        ))}
      </Flow>
    </SectionH1>
  )
})
