import { ParamsPaginationSortColumn, ParamsPaginationSort } from '@platform/core/dist/api/v1/types'
import { ProductFilterSortDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { SelectOption } from '@platform/ui'

import { SortParam } from './types'

export const sortOptions: SelectOption[] = [
  { name: 'Сначала новые', value: SortParam.NEW },
  { name: 'Сначала старые', value: SortParam.OLD },
  { name: 'По убыванию цены', value: SortParam.PRICE_DESС },
  { name: 'По возрастанию цены', value: SortParam.PRICE_ASC },
]

export const sortParamMap: Record<SortParam, ProductFilterSortDTO> = {
  [SortParam.NEW]: {
    sort: ParamsPaginationSort.Desc,
    sortColumn: ParamsPaginationSortColumn.CreateDate,
  },
  [SortParam.OLD]: {
    sort: ParamsPaginationSort.Asc,
    sortColumn: ParamsPaginationSortColumn.CreateDate,
  },
  [SortParam.PRICE_DESС]: {
    sort: ParamsPaginationSort.Desc,
    sortColumn: ParamsPaginationSortColumn.Price,
  },
  [SortParam.PRICE_ASC]: {
    sort: ParamsPaginationSort.Asc,
    sortColumn: ParamsPaginationSortColumn.Price,
  },
}
