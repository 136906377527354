import React from 'react'
import { observer } from 'mobx-react-lite'

import { ConditionsDelivery } from './conditions-delivery'
import { ConditionsPayment } from './conditions-payment'

export interface ConditionsProps {
  userId: string
}

export const Conditions = observer((props: ConditionsProps) => {
  return (
    <div className="space-y-5 sm:pb-5">
      <ConditionsDelivery />
      <ConditionsPayment />
    </div>
  )
})
