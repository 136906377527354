import { FC, useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Select, SelectOption, Input, Option, Tag } from '@platform/ui'
import { productTypeOptions } from '@platform/core/dist/modules/product/infra/dtos'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { core } from '@src/core'
import { isObject } from '@src/utils/helpers'
import { SuggestionCategoryDto } from '@platform/core/dist/modules/catalog/infra/dtos'
import { Flow } from '@src/components/common/flow'
import { Title } from '@src/components/common/title'

import { ProductTypeFilterProps } from './types'
import { FilterSearchParam } from '../types'
import { validateSearchParam } from '../helper'
import { productTypeFilterOptions, productTypeFilterDefaultOption } from './constants'

const { PRODUCT_TYPE, EXCHANGE_CATEGORY } = FilterSearchParam
const { categoryService } = core
const { categories } = categoryService

export const ProductTypeFilter: FC<ProductTypeFilterProps> = observer(
  ({ getSearchParam, hasSearchParam, setSearchParam, deleteSearchParam, searchParams }) => {
    const allCategories = categories.listAutocomplite.filter((item) => item.isLast)

    let defaultType: SelectOption = productTypeFilterDefaultOption
    if (validateSearchParam(PRODUCT_TYPE, getSearchParam(PRODUCT_TYPE))) {
      defaultType = productTypeOptions.find(
        (item) => item.value === (getSearchParam(PRODUCT_TYPE) as ProductTypeDTO)
      ) as SelectOption
    }

    let defaultSelectedIdsCategories: string[] = []
    if (validateSearchParam(EXCHANGE_CATEGORY, getSearchParam(EXCHANGE_CATEGORY))) {
      defaultSelectedIdsCategories = (getSearchParam(EXCHANGE_CATEGORY) as string).split(',')
    }

    const [typeState, setTypeState] = useState<SelectOption>(defaultType)
    const [stateAutocomplete, setAutocomplete] = useState<string>('')
    const [availableСategories, setAvailableCategories] = useState(
      allCategories.filter((item) => !defaultSelectedIdsCategories.includes(item.value))
    )
    const [selectedCategories, setSelectedCategories] = useState(
      allCategories.filter((item) => defaultSelectedIdsCategories.includes(item.value))
    )

    useEffect(() => {
      if (hasSearchParam(PRODUCT_TYPE)) {
        const option = productTypeOptions.find(
          (item) => item.value === (getSearchParam(PRODUCT_TYPE) as string)
        ) as SelectOption
        setTypeState(option)
      } else {
        setTypeState(productTypeFilterDefaultOption)
      }

      if (hasSearchParam(EXCHANGE_CATEGORY)) {
        const values = (getSearchParam(EXCHANGE_CATEGORY) as string).split(',')
        setAvailableCategories(allCategories.filter((item) => !values.includes(item.value)))
        setSelectedCategories(allCategories.filter((item) => values.includes(item.value)))
      } else {
        setAvailableCategories(allCategories)
        setSelectedCategories([])
      }
    }, [searchParams])

    const handleTypeChange = useCallback(
      (option: SelectOption) => {
        if (isObject(option)) {
          setTypeState(option)
          if (option.value === productTypeFilterDefaultOption.value) {
            deleteSearchParam(PRODUCT_TYPE)
            return
          }
          setSearchParam(PRODUCT_TYPE, option.value as string)
        }
      },
      [setSearchParam]
    )

    const handleChangeAutocomplete = useCallback(
      (option: Option) => {
        if (isObject(option)) {
          setAutocomplete(option.label || '')

          const newSelected = [...selectedCategories, option as SuggestionCategoryDto]
          setSelectedCategories(newSelected)
          if (newSelected.length) {
            setSearchParam(EXCHANGE_CATEGORY, newSelected.map((item) => item.value).join(','))
          } else {
            deleteSearchParam(EXCHANGE_CATEGORY)
          }

          setAvailableCategories((categories) => categories.filter((item) => item.value !== option.value))
        }
      },
      [setSearchParam, deleteSearchParam]
    )

    const deleteSelectedCategory = useCallback(
      (category: SuggestionCategoryDto) => {
        const newSelected = selectedCategories.filter((item) => item.value !== category.value)
        setSelectedCategories(newSelected)
        if (newSelected.length) {
          setSearchParam(EXCHANGE_CATEGORY, newSelected.map((item) => item.value).join(','))
        } else {
          deleteSearchParam(EXCHANGE_CATEGORY)
        }

        const categoryIndex = allCategories.findIndex((item) => item.value === category.value)
        setAvailableCategories((categories) => {
          const newCategories = [...categories]
          newCategories.splice(categoryIndex, 0, category)
          return newCategories
        })
      },
      [setSearchParam, deleteSearchParam]
    )

    if (categoryService.isEmpty) return null

    return (
      <Flow directory="col" space={3.5}>
        <Title size="md" weight="medium">
          Формат объявления
        </Title>
        <Select
          variant="outline"
          options={productTypeFilterOptions}
          value={typeState}
          size="md"
          onChange={handleTypeChange}
          className="w-full font-normal text-sm"
        />
        {getSearchParam(PRODUCT_TYPE) === ProductTypeDTO['exchange'] && (
          <>
            <Input.Autocomplete
              placeholder="Найти категорию"
              value={stateAutocomplete}
              size="lg"
              options={availableСategories}
              onChange={handleChangeAutocomplete}
            />
            <Flow align="center" full className="flex-wrap gap-2">
              {selectedCategories.map((item, index) => (
                <Tag
                  key={index}
                  className="opacity-40 hover:opacity-100"
                  color="primary"
                  shape="round"
                  size="sm"
                  closable
                  onClose={() => deleteSelectedCategory(item)}
                >
                  {item.name}
                </Tag>
              ))}
            </Flow>
          </>
        )}
      </Flow>
    )
  }
)
