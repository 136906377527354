import { FC, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { SectionH1 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { core, ScreenMode } from '@src/core'
import { Swiper, SwiperSlide, Tabs } from '@platform/ui'
import { CardPersonalProduct } from '@platform/ui'
import { useProfileProducts } from '@src/hooks/use-profile-products'
import { Loading } from '@src/components/common/loading'

import { useProfileOutletContext } from './hooks/useProfileContext'
import { StateTabs, TypeTabs } from './types'
import { productStateTabMap, productTypeTabMap, productStateTabsMap } from './constants'

const { layoutService } = core
const { screen } = layoutService

const { productService } = core

export const Ads: FC = observer(() => {
  const navigate = useNavigate()

  const [stateTabSelected, setStateTabSelected] = useState<StateTabs>(StateTabs.active)
  const [typeTabSelected, setTypeTabSelected] = useState<TypeTabs>(TypeTabs.all)

  const { backCallback } = useProfileOutletContext()

  const handleChangeStatusTab = useCallback((index: number) => setStateTabSelected(index), [])
  const handleChangeTypeTab = useCallback((index: number) => setTypeTabSelected(index), [])

  const { products, requestStatus, deleteStatus } = useProfileProducts({
    params: {
      type: productTypeTabMap[typeTabSelected],
      state: productStateTabMap[stateTabSelected],
      size: 20,
    },
  })

  const handleEdit = useCallback((meta: any) => {
    navigate(`/product/edit/${meta.id}`)
  }, [])

  const handleClick = useCallback((meta: any) => {
    navigate(`/product/${meta.type}/${meta.id}`)
  }, [])

  const handleDelete = useCallback(async (meta: any) => {
    productService.deleteProduct(meta.id)
  }, [])

  return (
    <SectionH1 title="Объявления" titleClassName="ml-5" onBackClick={backCallback}>
      <Tabs
        navTextColor="neutral-400"
        navTextActiveColor="neutral-900"
        value={stateTabSelected}
        onChange={handleChangeStatusTab}
        className="items-start"
      >
        {screen.mode === ScreenMode.mobile ? (
          <Swiper className="pb-4 px-5 border-b border-neutral-400" arrowClassname="top-0" arrowVariant="link">
            {productStateTabsMap.map((item, key) => (
              <SwiperSlide className="w-auto mr-10">
                <Tabs.Button key={key} index={item.index} className="text-lg font-medium">
                  {item.title}
                </Tabs.Button>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Tabs.NavList className="gap-x-10 lg:gap-x-7 xl:gap-x-10 pb-4 px-5 border-b border-neutral-400">
            {productStateTabsMap.map((item, key) => (
              <Tabs.Button key={key} index={item.index} className="text-lg font-medium">
                {item.title}
              </Tabs.Button>
            ))}
          </Tabs.NavList>
        )}
      </Tabs>
      <Tabs
        navTextColor="neutral-400"
        navTextActiveColor="neutral-900 font-medium"
        value={typeTabSelected}
        onChange={handleChangeTypeTab}
        className="mb-46px md:mb-58px"
      >
        <Tabs.NavList className="flex-wrap gap-x-4 mt-4 px-5">
          <Flow align="center">
            <span>Показывать объявления:</span>
            <Tabs.Button index={TypeTabs.all} className="pl-4">
              Все
            </Tabs.Button>
          </Flow>
          <Tabs.Button index={TypeTabs.buy}>Покупка</Tabs.Button>
          <Tabs.Button index={TypeTabs.sell}>Продажа</Tabs.Button>
          <Tabs.Button index={TypeTabs.exchange}>Обмен</Tabs.Button>
          <Tabs.Button index={TypeTabs.auction}>Аукцион</Tabs.Button>
        </Tabs.NavList>
      </Tabs>
      <Flow directory="col" space={9} full>
        {requestStatus === 'loading' && <Loading />}
        {requestStatus === 'loaded' &&
          products.map((item) => (
            <CardPersonalProduct
              onClick={handleClick}
              onClickEditBtn={handleEdit}
              isDisabledDeleteBtn={stateTabSelected === StateTabs.archive}
              onClickDeleteBtn={handleDelete}
              callCount={item.countCall}
              likeCount={item.countFavorite}
              viewCount={item.countVisitors}
              metaData={{ id: item.id, type: item.type }}
              key={item.id}
              title={item.name}
              src={item.images}
              rating={item.rating.percentRank}
              price={item.price || ''}
              oldPrice={item.oldPrice}
              deleteBtnLoading={deleteStatus === 'loading'}
              priceDescription={item.measure && `за 1 ${item.measure}`}
              isDisabledPromoteBtn={true}
            />
          ))}
      </Flow>
    </SectionH1>
  )
})
