import { FC } from 'react'
import cn from 'classnames'
import { FieldProps } from './types'
import { TooltipInfo } from '../tooltip-info'

export const Field: FC<FieldProps> = (props) => {
  const { label, value, className, labelClassName, valueClassName, description } = props

  return (
    <div className={cn(className, 'w-full flex justify-between')}>
      <span className="flex space-x-1">
        <span className={cn('text-neutral-400 text-xs md:text-md', labelClassName)}>{label}</span>
        <span className="w-3">{!!description && <TooltipInfo labelText={description} />}</span>
      </span>
      <span className="flex-1 border-b-xs border-dashed text-neutral-400 mx-1.5" />
      <span className={cn('text-neutral-900 text-xs md:text-md', valueClassName)}>{value}</span>
    </div>
  )
}
