import { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Tag } from '@platform/ui'

import { Flow } from '@src/components/common/flow'
import { core } from '@src/core'

import { CategoriesExchangeProps } from './types'

const { categoryService } = core
const { categories } = categoryService

export const CategoriesExchange: FC<CategoriesExchangeProps> = observer(({ product }) => {
  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const categoriesList = useMemo(() => {
    if (product.exchangeCategories && !categoryService.isEmpty) {
      return product.exchangeCategories.map((item) => categories.findById(item).name)
    }
    return []
  }, [categories.list, product])

  return (
    <Flow className="flex-wrap gap-2.5">
      {categoriesList.map((item, key) => (
        <Tag
          key={key}
          size="sm"
          color="primary"
          shape="round"
          colorNumber={100}
          className="text-black rounded-xl p-1.5"
        >
          {item}
        </Tag>
      ))}
    </Flow>
  )
})
