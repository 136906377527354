import React from 'react'
import { Title } from '@platform/ui'

export const ConditionsPayment: React.FC = (props) => {
  return (
    <div>
      <Title size="lg" colorNumber={900}>
        Способ оплаты
      </Title>
    </div>
  )
}
