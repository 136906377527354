import { FC } from 'react'
import NotFound from '@src/pages/not-found'
import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import { Home } from './home'
import { Category } from './category'
import { Topic } from './topic'
import { Layout } from './container/layout'

const { HELP } = ROUTES

const HelpPages: FC = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={HELP.SECTION.INDEX} element={<Category />} />
          <Route path={HELP.TOPIC.INDEX} element={<Topic />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

HelpPages.displayName = 'HelpPages'

export default HelpPages
