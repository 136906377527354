import { useState, useEffect, useCallback } from 'react'
import { ParamsFilteredProducts } from '@platform/core/dist/api/v1/product'
import { RequestFilteredProducts } from '@platform/core/dist/api/v1/product'
import { ProductSimplifiedDTO } from '@platform/core/dist/modules/product/infra/dtos/ProductSimplifiedDTO'

import { core } from '@src/core'

const { productService } = core

export interface UseFilteredProductsProps {
  params?: Omit<ParamsFilteredProducts, 'page'>
  attributesParams?: RequestFilteredProducts
}

export interface UseFilteredProductsState {
  status: Status
  products: ProductSimplifiedDTO[]
  isLast: boolean
  page: number
  totalPages: number
}

const defaultState: UseFilteredProductsState = {
  status: 'pending',
  products: [],
  isLast: false,
  page: 0,
  totalPages: 0,
}

export const useFilteredProducts = ({ params = {}, attributesParams }: UseFilteredProductsProps) => {
  const [state, setState] = useState<UseFilteredProductsState>(defaultState)

  useEffect(() => {
    setState((state) => ({
      ...state,
      ...{
        status: 'loading',
      },
    }))

    productService
      .fetchFilteredProducts({ ...params, page: state.page }, attributesParams)
      .then((res) => {
        if (res.isSuccess) {
          const pagination = res.getValue()
          setState((state) => ({
            ...state,
            ...{
              products: state.page === 0 ? pagination.content : [...state.products, ...pagination.content],
              isLast: pagination.last,
              totalPages: pagination.totalPages,
            },
          }))
        }
      })
      .then(() =>
        setState((state) => ({
          ...state,
          ...{
            status: 'loaded',
          },
        }))
      )
      .catch(() =>
        setState((state) => ({
          ...state,
          ...{
            status: 'failed',
          },
        }))
      )
  }, [
    params.searchCategory,
    params.searchStartPrice,
    params.searchEndPrice,
    params.searchLocation,
    params.size,
    params.sort,
    params.sortColumn,
    params.userId,
    ...(params.tag ?? []),
    ...(params.searchExchangeCategory ?? []),
    params.typeFlyer,
    attributesParams,
    state.page,
  ])

  const loadNextPage = useCallback(
    () =>
      setState((state) => ({
        ...state,
        ...{
          page: state.page + 1,
        },
      })),
    [state.page]
  )

  const handleSetPage = useCallback((page: number) => {
    setState((state) => ({
      ...state,
      ...{
        products: [],
        page,
      },
    }))
  }, [])

  return {
    requestStatus: state.status,
    products: state.products,
    isLastPage: state.isLast,
    page: state.page,
    setPage: handleSetPage,
    loadNextPage,
    totalPages: state.totalPages,
  }
}
