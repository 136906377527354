import React, { useCallback } from 'react'

export interface UseScrollProps {
  ref: React.RefObject<HTMLDivElement>
  callback?: () => void
}

export const useScrollIntoView = (props: UseScrollProps) => {
  const { ref, callback } = props

  const handleTo = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView()
      callback?.()
    }
  }, [ref.current, callback])

  return [handleTo]
}
