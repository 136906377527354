import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Title, Spinner, notification } from '@platform/ui'

import { core } from '@src/core'
import NotFound from '@src/pages/not-found'
import { Flow } from '@src/components/common/flow'
import { ProductFormation } from '@src/container/product-formation'

import { Brief } from './components/brief'

const { createProductService, authService } = core
const { createdProduct, createProduct } = createProductService
const { authorizedUser } = authService

export const Edit: FC = observer(() => {
  const params = useParams()
  const productId = Number(params.id)
  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    return () => {
      createProduct.reset()
      createProductService.resetCreatedProduct()
    }
  }, [])

  useEffect(() => {
    createProductService.fetchCreatedProductForEdit(productId).then((resultOrError) => {
      if (resultOrError.isFailure) {
        notification.error({
          message: resultOrError.getError().toString(),
        })
        setIsNotFound(true)
      }
    })
  }, [productId])

  useEffect(() => {
    if (createdProduct.data && authorizedUser.user?.id !== createdProduct.data.userId) {
      setIsNotFound(true)
    }
  }, [createdProduct.data])

  if (isNotFound) {
    return <NotFound />
  }

  if (createdProduct.isLoading) {
    return (
      <Flow className="content-main flex-1 justify-center" align="center">
        <Spinner size="2xl" className="text-primary-500" />
      </Flow>
    )
  }

  if (!createdProduct.data) return null

  return (
    <Flow className="content-main py-8" directory="col">
      <Title size="2xl" textClassName="font-semibold">
        <h1>Редактирование объявления</h1>
      </Title>
      <Brief product={createdProduct.data} />
      <ProductFormation isEdit />
    </Flow>
  )
})
