import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SectionH1 } from '@src/components/common/section'
import { ProductList } from '@src/components/desktop/product-list'
import { useFavoritesProducts } from '@src/hooks/use-favorites-products'

import { useProfileOutletContext } from './hooks/useProfileContext'

export const Favorites: FC = observer(() => {
  const { products, requestStatus, loadNextPage, isLastPage } = useFavoritesProducts({
    params: {
      size: 9,
    },
  })

  const { backCallback } = useProfileOutletContext()

  return (
    <SectionH1 title="Закладки" titleClassName="ml-5" onBackClick={backCallback}>
      <ProductList products={products} requestStatus={requestStatus} loadMore={loadNextPage} isLastPage={isLastPage} />
    </SectionH1>
  )
})
