import { FC, useRef, useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { SectionH2 } from '@src/components/common/section'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { TYPE_RADIO, TYPE_DATE, TYPE_SLIDER, TYPE_CHECKBOX, TYPE_SELECT } from '@platform/core'
import { CreateProductContext } from '../context'
import { FieldSLider, FieldCheckbox, FieldDate, FieldRadio, FieldText, FieldSelect } from './fields'

const { createProduct } = core.createProductService

export const DeatailedInfo: FC = observer(() => {
  const nodeDetaildInfo = useRef<HTMLDivElement>(null)
  const [handleToDetailedInfo] = useScrollIntoView({ ref: nodeDetaildInfo })
  const context = useContext(CreateProductContext)

  useEffect(() => {
    context?.updateFieldsDetaileds(
      createProduct.fields.map((item) => ({ key: item.key, handleScroll: handleToDetailedInfo, orderMarkup: 5 }))
    )
  }, [createProduct.fields])

  return (
    <SectionH2 ref={nodeDetaildInfo} title="Детальная информация">
      <div className="grid grid-cols-4 gap-x-5 gap-y-7 py-4">
        {createProduct.fields.map(({ id, type }) => {
          if (typeof id === 'undefined') {
            return null
          }

          switch (type) {
            case TYPE_SLIDER:
              return <FieldSLider key={id} id={id} />
            case TYPE_DATE:
              return <FieldDate key={id} id={id} />
            case TYPE_CHECKBOX:
              return <FieldCheckbox key={id} id={id} />
            case TYPE_RADIO:
              return <FieldRadio key={id} id={id} />
            case TYPE_SELECT:
              return <FieldSelect key={id} id={id} />
            default:
              return <FieldText key={id} id={id} />
          }
        })}
      </div>
    </SectionH2>
  )
})
