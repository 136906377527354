import { core } from '@src/core'

export const autocompliteAddres = (query: string) => {
  return core.autocompliteService.address({ query }).then((resultOrError) => {
    if (resultOrError.isSuccess) {
      return resultOrError.getValue()
    }
    return []
  })
}
