import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Radio } from '@platform/ui'
import { FieldRadioInterface } from '@platform/core/dist/modules/product/domain/fields/models'

import { Label } from '@src/components/common/label'
import { core } from '@src/core'

import { Detail } from '../types'

const { createProduct } = core.createProductService

export const FieldRadio: FC<Detail> = observer(({ id }) => {
  const { label, required, value, changeValue, options, errorText, description } = createProduct.fields.find(
    (item) => item.id === id
  ) as FieldRadioInterface

  const handleChange = useCallback(
    (value: string | number) => {
      changeValue(String(value))
    },
    [changeValue]
  )

  return (
    <div className="pt-2 space-y-2">
      <Label
        required={required}
        label={label}
        size="sm"
        textStyle="font-medium text-black text-sm"
        tooltipText={description}
      />
      <Radio.Group defaultValue={value} onChange={handleChange} className="flex-wrap flex gap-1">
        {options.map(({ value, label }, key) => (
          <Radio key={key} value={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
      <div className="mt-2 text-invalid">{errorText}</div>
    </div>
  )
})
