import { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Accordion, AccordionGroup, AccordionItem, Button, Icon } from '@platform/ui'
import { core } from '@src/core'
import { LINKS } from '@src/routes'

import { CategoryTitle } from './category-title'
import { CategoryFilterProps } from './types'
import { Flow } from '@src/components/common/flow'

const { categoryService } = core
const { categories } = categoryService

export const CategoryFilter: FC<CategoryFilterProps> = observer(({ categoryId }) => {
  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const navigate = useNavigate()

  const previusLinks = useMemo(() => {
    if (!categoryId) return []

    const result = [{ title: 'Все категории', to: LINKS.CATEGORY_HOME }]
    const parentsList = categories.getListParentsById(categoryId)

    if (parentsList.length) {
      parentsList.pop()
      result.push(
        ...parentsList.map((item) => ({
          title: item.name,
          to: `/category/${item.code}--${item.id}`,
        }))
      )
    }

    return result
  }, [categoryId, categories.list])

  const categoryList = useMemo(() => {
    if (categoryId) {
      const category = categories.findById(categoryId)
      return [category]
    }

    return categories.getTreeById(null)
  }, [categoryId, categories.list])

  if (categoryService.isEmpty) return null

  return (
    <Flow directory="col" align="start" space={2}>
      {previusLinks.map((item, index) => (
        <Button
          key={index}
          variant="link"
          color="primary"
          icon={<Icon.ArrowLong position="left" />}
          onClick={() => navigate(item.to)}
        >
          {item.title}
        </Button>
      ))}
      <Accordion initialActiveCollapse={categoryList.length === 1 ? 0 : undefined}>
        {categoryList.map((item) => {
          if (item.childs.length) {
            return (
              <AccordionGroup
                key={item.id}
                title={<CategoryTitle href={`/category/${item.code}--${item.id}`} name={item.name} />}
              >
                {item.childs.map((childItem) => (
                  <AccordionItem
                    key={childItem.id}
                    title={
                      <CategoryTitle href={`/category/${childItem.code}--${childItem.id}`} name={childItem.name} />
                    }
                  />
                ))}
              </AccordionGroup>
            )
          }
          return (
            <AccordionItem
              key={item.id}
              title={<CategoryTitle href={`/category/${item.code}--${item.id}`} name={item.name} />}
            />
          )
        })}
      </Accordion>
    </Flow>
  )
})
