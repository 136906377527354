import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Flow, Align } from '@src/components/common/flow'
import { Button, Title } from '@platform/ui'
import { useScreenModeValue } from '@src/hooks'

export const Comment: FC = observer(() => {
  const align = useScreenModeValue<Align>({
    desktop: 'start',
    tablet: 'start',
    mobile: 'center',
  })

  return (
    <Flow directory="col" space={7} align={align}>
      <Flow directory="col" align={align}>
        <Title weight="medium" className="text-lg md:text-1xl">
          Была ли вам полезна данная статья?
        </Title>
        <span>Ваш отзыв поможет нам стать лучше.</span>
      </Flow>
      <Flow space={3}>
        <Button className="px-6" color="positive">
          Да
        </Button>
        <Button className="px-6" color="negative">
          Нет
        </Button>
      </Flow>
    </Flow>
  )
})
