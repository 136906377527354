import { createContext, useContext } from 'react'
import { useSearchParamsAppReturn } from '@src/hooks/use-search-params-app'

export interface MapFilterParamsContextProps {
  searchParams: useSearchParamsAppReturn['searchParams']
  deleteSearchParam: useSearchParamsAppReturn['deleteSearchParam']
  getSearchParam: useSearchParamsAppReturn['getSearchParam']
  hasSearchParam: useSearchParamsAppReturn['hasSearchParam']
  setSearchParam: useSearchParamsAppReturn['setSearchParam']
}

export const MapFilterParamsContext = createContext<MapFilterParamsContextProps | null>(null)

export const MapFilterParamsProvider = MapFilterParamsContext.Provider

export const useMapFilterParamsContext = (): MapFilterParamsContextProps => {
  const context = useContext(MapFilterParamsContext)
  if (!context) {
    throw new Error('Not found: MapFilterParamsProvider')
  }

  return context
}
