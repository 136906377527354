import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Spinner, Swiper, SwiperSlide } from '@platform/ui'
import { CategoryPopular } from '@platform/core/dist/models/category/CategoryNode'

import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'

const { categoryService, layoutService } = core
const { screen } = layoutService

export const PopularCategories: FC = observer(() => {
  const [categories, setCategories] = useState<CategoryPopular[]>([])
  const [status, setStatus] = useState<Status>('pending')

  useEffect(() => {
    setStatus('loading')
    categoryService
      .fetchPopularCategories()
      .then((res) => {
        if (res.isSuccess) {
          setCategories(res.getValue())
        }
      })
      .then(() => setStatus('loaded'))
  }, [])

  return (
    <>
      {status === 'loading' && (
        <div className="py-20 flex items-center justify-center w-full">
          <Spinner size="2xl" className="text-primary-500" />
        </div>
      )}
      {!!categories.length && (
        <Swiper arrowPosition={screen.mode === ScreenMode.mobile ? 'bottom' : 'inner'}>
          {categories.map((category) => (
            <SwiperSlide key={category.id} className="w-32 h-39 md:w-163px md:h-49.5 rounded-lg mr-2 md:mr-4">
              <div
                className="w-full h-full rounded-lg overflow-hidden"
                style={{
                  backgroundImage: `linear-gradient(180deg, ${category.startColor}, ${category.endColor})`,
                }}
              >
                <Link to={`/category/${category.code}--${category.id}`}>
                  <Flow directory="col" space={2} className="h-full w-full justify-between">
                    <span className="text-white text-sm md:text-md font-medium leading-md md:leading-lg pl-3.5 pt-2.5">
                      {category.name}
                    </span>
                    <span className="pt-22px">
                      <img src={'/remote/file/' + category.image.hashImage} alt={category.name} />
                    </span>
                  </Flow>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
})
