import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { Category } from '@platform/core/dist/modules/catalog/domain/category'
import { Swiper, SwiperSlide, Button, Icon } from '@platform/ui'

import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Title } from '@src/components/common/title'
import { Loading } from '@src/components/common/loading'

import { CategorySwiperProps } from './types'

const { categoryService, layoutService } = core
const { categories } = categoryService
const { screen } = layoutService

const MAX_SLIDER_ROWS = 3

export const CategorySwiper: FC<CategorySwiperProps> = observer(
  ({ withNavigation = true, maxSlideRowCount: slideRowCount, initialCategory, customTitle }) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(initialCategory || null)

    useEffect(() => {
      if (typeof initialCategory !== 'undefined') {
        setActiveCategory(initialCategory)
      }
    }, [initialCategory])

    const navigate = useNavigate()

    useEffect(() => {
      categoryService.fetchTreeCategories()
    }, [])

    const handleCategoryClick = (category: Category) => {
      if (category.childs.length && withNavigation) {
        setActiveCategory(category)
        return
      }
      navigate(`/category/${category.code}--${category.id}`)
    }

    const slideRows = useMemo(() => {
      if (slideRowCount) return slideRowCount
      if (screen.mode === ScreenMode.desktop && activeCategory && activeCategory.childs.length < 9) {
        return MAX_SLIDER_ROWS - 1
      }
      return MAX_SLIDER_ROWS
    }, [slideRowCount, screen.mode, activeCategory])

    const categoriesList = useMemo(() => {
      let rawList = [...categories.list]
      if (activeCategory) {
        rawList = [...activeCategory.childs]
      }

      let items: Category[] = []
      const resultList: Category[][] = []
      while (rawList.length) {
        const item = rawList.shift() as Category
        items.push(item)

        if (!rawList.length && items.length !== slideRows) {
          resultList.push([...items])
        }

        if (items.length === slideRows) {
          resultList.push([...items])
          items = []
        }
      }

      return resultList
    }, [activeCategory, categories.list])

    const parentsCategory = useMemo(() => {
      if (!activeCategory) return []
      const parentsList = categories.getListParentsById(Number(activeCategory.id))
      if (parentsList.length === 1) return []
      parentsList.pop()
      return parentsList
    }, [activeCategory])

    if (categoryService.isLoading) {
      return <Loading />
    }

    return (
      <Flow directory="col" space={activeCategory ? 3.5 : 8}>
        {withNavigation && (
          <Flow directory="col">
            <Title size={screen.mode === ScreenMode.mobile ? 'lg' : '1xl'}>
              {!!customTitle ? customTitle : activeCategory ? activeCategory.name : 'Все категории'}
            </Title>

            <Flow directory="col" align="start">
              {!!activeCategory && (
                <Button
                  className="mt-1.5"
                  variant="link"
                  color="primary"
                  icon={<Icon.ArrowLong position="left" />}
                  onClick={() => setActiveCategory(null)}
                >
                  Все категории
                </Button>
              )}
              {!!parentsCategory.length &&
                parentsCategory.map((item) => (
                  <Button
                    className="mt-1.5"
                    key={item.id}
                    variant="link"
                    color="primary"
                    icon={<Icon.ArrowLong position="left" />}
                    onClick={() => setActiveCategory(categories.findById(item.id))}
                  >
                    {item.name}
                  </Button>
                ))}
            </Flow>
          </Flow>
        )}
        {!!categoriesList.length && (
          <Swiper
            arrowPosition={screen.mode === ScreenMode.desktop ? 'inner' : 'bottom'}
            key={activeCategory ? activeCategory.id : null}
          >
            {categoriesList.map((categories, wrappIndex) => {
              return (
                <SwiperSlide key={wrappIndex} className="min-h-20 min-w-304px max-w-380px">
                  <div className="flex flex-col">
                    {categories.map((category, rowIndex) => (
                      <div
                        key={`${wrappIndex}-${rowIndex}`}
                        onClick={() => handleCategoryClick(category)}
                        className={cn(
                          'flex justify-between h-96px cursor-pointer overflow-hidden border-r-xs border-b-xs border-neutral-300',
                          {
                            'rounded-tl-xl': wrappIndex == 0 && rowIndex == 0,
                            'rounded-bl-xl': wrappIndex == 0 && rowIndex == slideRows - 1,
                            'border-l-xs': wrappIndex == 0,
                            'border-t-xs': rowIndex == 0,
                            'border-b-xs': rowIndex != 0,
                            'rounded-tr-xl': wrappIndex === categoriesList.length - 1 && rowIndex == 0,
                            'rounded-br-xl': wrappIndex === categoriesList.length - 1 && rowIndex == slideRows - 1,
                          }
                        )}
                      >
                        <span className="font-medium text-sm md:text-md leading-md md:leading-lg w-1/2 inline-block p-2.5 text-black">
                          {category.name}
                        </span>
                        <span className="w-1/2">
                          {category.image && <img src={category.image} alt={category.name} />}
                        </span>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </Flow>
    )
  }
)
