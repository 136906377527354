import { FC } from 'react'
import { Spinner } from '@platform/ui'

export const Loading: FC = () => {
  return (
    <div className="p-12 flex items-center justify-center">
      <Spinner size="2xl" className="text-primary-500" />
    </div>
  )
}
