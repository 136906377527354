import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { CategoryFilter } from '@src/container/category-filter'
import { ProductTagFilter, ProductTypeFilter, PriceSliderFilter } from '@src/search-params-settings'
import { Range } from '@platform/ui'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'
import { FilterSearchParam } from '@src/search-params-settings'

import { SearchParamsFiltersProps } from './types'
import { useFilterParamsContext } from '../../context'

const { PRODUCT_TYPE } = FilterSearchParam

const { productService } = core
const { filteredProducts } = productService

export const SearchParamsFilters: FC<SearchParamsFiltersProps> = observer(({ className }) => {
  const context = useFilterParamsContext()

  const { slug } = useParams()
  let categoryId: Nullable<number> = null
  if (typeof slug === 'string') {
    const [, id] = (slug as string).split('--')
    categoryId = Number(id)
  }

  return (
    <Flow directory="col" space={8} full className={className}>
      <Flow directory="col" align="start" space={8}>
        <CategoryFilter categoryId={categoryId} />
        <Flow directory="col" space={10} full className="pr-8">
          {context.getSearchParam(PRODUCT_TYPE) !== ProductTypeDTO.exchange &&
            context.getSearchParam(PRODUCT_TYPE) !== ProductTypeDTO.auction &&
            filteredProducts.categoryPriceRange && (
              <PriceSliderFilter
                {...context}
                minRange={filteredProducts.categoryPriceRange[0]}
                maxRange={filteredProducts.categoryPriceRange[1]}
              />
            )}
          <ProductTypeFilter {...context} />
          <ProductTagFilter {...context} />
        </Flow>
      </Flow>
    </Flow>
  )
})
