import { FC } from 'react'
import { Flow } from '@src/components/common/flow'

import { SocialBlock } from '../social-block'
import { LoginFormProps } from './types'

export const LoginForm: FC<LoginFormProps> = ({ title, children, onSubmit, hideSocial = false }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <Flow className="justify-center" align="center">
      <div className="rounded-lg py-7 px-8 w-full md:w-530px lg:w-345px bg-white shadow-lg shadow-dark-500/50">
        <h2 className="text-2xl font-medium leading-10">{title}</h2>
        <form className="py-6 md:py-46px" onSubmit={handleSubmit}>
          {children}
        </form>
        {!hideSocial && <SocialBlock title="Войти быстро через:" />}
      </div>
    </Flow>
  )
}

export default LoginForm
