import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ButtonFavorite, Button } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { core } from '@src/core'
import { Price } from '@src/container/price'

import { useProductContext } from './hooks/useProductContext'
import { PersonalControl } from './components/personal-control'

const { productService, authService } = core
const { product } = productService
const { authorizedUser } = authService

export const ProductSell: FC = observer(() => {
  const { onClickFavorite } = useProductContext()

  if (!product.data) return null

  return (
    <>
      <Price
        value={product.data.price as number}
        oldPrice={product.data.oldPrice}
        measure={product.data.measure.name}
        description="Цена за 1 "
      />
      {authorizedUser.user?.id === product.data.userId && <PersonalControl productId={product.data.id} />}
      {authorizedUser.user?.id !== product.data.userId && (
        <>
          {product.data.quantity && (
            <Flow className="font-semibold" space={1.5} align="center">
              <span>В наличии:</span>
              <span className="text-lg">{`${product.data.quantity} ${product.data.measure.name}`}</span>
            </Flow>
          )}
          <Flow className="justify-between" align="center">
            <Button size="xl" color="primary" className="w-5/6" textClassName="text-xs md:text-sm font-semibold">
              Добавить в корзину
            </Button>
            <ButtonFavorite isActive={product.data.isFavorite} onClick={onClickFavorite} />
          </Flow>
          <Button size="xl" className="w-full" variant="outline" textClassName="text-xs md:text-sm font-semibold">
            Написать сообщение
          </Button>
        </>
      )}
    </>
  )
})
