import { FC, useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Input, Option } from '@platform/ui'
import { isObject } from '@src/utils/helpers'

import { UserFilterProps } from './types'
import { FilterSearchParam } from '../types'

const { autocompliteService } = core

const autocompliteUsers = (query: string) =>
  autocompliteService.user(query).then((resultOrError) => {
    if (resultOrError.isSuccess) {
      return resultOrError.getValue()
    }
    return []
  })

export const UserFilter: FC<UserFilterProps> = observer(({ setSearchParam, deleteSearchParam }) => {
  const [value, setValue] = useState('')

  const handleChange = useCallback(
    (option: Option) => {
      if (isObject(option)) {
        setSearchParam(FilterSearchParam.USER, String(option.id))
      }
    },
    [setSearchParam]
  )

  useEffect(() => {
    if (!value) deleteSearchParam(FilterSearchParam.USER)
  }, [value])

  return (
    <Input.Autocomplete
      placeholder="Найти компанию"
      size="lg"
      getOptions={autocompliteUsers}
      onChangeValue={setValue}
      onChange={handleChange}
    />
  )
})
