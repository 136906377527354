import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { FC, useCallback, useEffect } from 'react'
import { core } from '@src/core'
import { Section, SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { Field } from '@src/components/common/field'
import { Button, Spinner } from '@platform/ui'
import { LINKS } from '@src/routes'
import { formatMoney } from '@src/utils/formatMoney'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { ProductDetails } from './container/product-details'
import { Tariffs } from './container/tariffs'
import { Upgrade } from './components/upgrade'

import * as mocks from './mock'
const { createProduct, createdProduct } = core.createProductService
const { tariffs } = core.userSubscriptionsService

export const Service: FC = observer(() => {
  const navigate = useNavigate()
  const handleToPayment = useCallback(() => {}, [])
  const handleToModeration = useNavigationApp({ to: LINKS.CREATE_MODERATION })

  useEffect(() => {
    if (!createProduct.id) {
      navigate(LINKS.CREATE_PRODUCT)
      return
    }

    core.createProductService.fetchCreatedProduct(createProduct.id)
  }, [])

  const handleUpgradesChange = useCallback((id: number) => {
    createProduct.setServiceIds(id)
  }, [])

  if (createdProduct.isLoading || !createdProduct.data) {
    return (
      <div className="flex justify-center">
        <Spinner size="2xl" className="text-primary-500" />
      </div>
    )
  }

  const chosenTarriff = tariffs.list.find((item) => item.id === createProduct.tariffId)

  const chosenServices = mocks.UPGRADES.filter((item) => createProduct.servicesIds.includes(item.id))

  let total = chosenServices.reduce((prev, next) => prev + next.price, 0)

  if (chosenTarriff) {
    total += chosenTarriff.price
  }

  return (
    <Flow directory="col">
      <Section className="py-6">
        <ProductDetails />
      </Section>
      <div className="w-3/4">
        <SectionH2 className="py-6" title="Тарифный план">
          <Tariffs />
        </SectionH2>
        <SectionH2 className="py-6" title="Увеличьте эффективность вашего объявления">
          <Flow directory="col" space={5}>
            {mocks.UPGRADES.map((item) => (
              <Upgrade
                key={item.id}
                onChange={handleUpgradesChange}
                selected={createProduct.servicesIds.includes(item.id)}
                {...item}
              />
            ))}
          </Flow>
        </SectionH2>
        <SectionH2 className="py-6" title="Выбранные опции">
          <Flow directory="col" space={2}>
            {!!chosenTarriff && (
              <Field
                labelClassName="text-neutral-900"
                className="text-md"
                label={`Тарифный план “${chosenTarriff.name}“`}
                value={formatMoney(chosenTarriff.price, '₽')}
              />
            )}
            {chosenServices.map((item) => (
              <Field
                key={item.id}
                labelClassName="text-neutral-900"
                className="text-md"
                label={item.name}
                value={formatMoney(item.price, '₽')}
              />
            ))}
            <Field
              labelClassName="text-neutral-900"
              className="text-md"
              label="Итого к оплате"
              value={formatMoney(total, '₽')}
            />
          </Flow>
        </SectionH2>
        <Flow align="start" space={4}>
          <Button size="md" color="positive" className="w-345px" onClick={handleToPayment}>
            Оплатить
          </Button>
          <Button size="md" variant="outline" color="black" className="w-345px" onClick={handleToModeration}>
            Разместить бесплатно
          </Button>
        </Flow>
      </div>
    </Flow>
  )
})
