import { useEffect, useCallback } from 'react'
import { core } from '@src/core'

import { ParamsUserProfile } from '@platform/core/dist/modules/product/application/user-profile'

const { productService } = core

const { userProfileProducts } = productService

export interface useProfileProductsProps {
  params: ParamsUserProfile
}

export const useProfileProducts = ({ params }: useProfileProductsProps) => {
  useEffect(() => {
    productService.fetchUserProfileProducts(params)
  }, [params?.size, params?.sort, params?.sortColumn, params.state, params.type])

  const loadNextPage = useCallback(() => {
    userProfileProducts.setState({ page: userProfileProducts.page + 1 })
    productService.fetchUserProfileProducts(params)
  }, [])

  return {
    products: userProfileProducts.list,
    requestStatus: userProfileProducts.status,
    deleteStatus: userProfileProducts.statusDeleteProduct,
    isLastPage: userProfileProducts.isLast,
    page: userProfileProducts.page,
    loadNextPage,
    totalPages: userProfileProducts.totalPages,
  }
}
