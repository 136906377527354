import { createContext } from 'react'

type ContextByPageData = {
  currentStep: string
  steps: string[]
  setStep: (step: string) => void
}

export const ContextByPage = createContext<ContextByPageData | null>(null)

export const ProviderByPage = ContextByPage.Provider
