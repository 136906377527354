import React from 'react'

export interface IContactProps {}

export const Contact = (props: IContactProps) => {
  return (
    <div className="relative rounded-lg overflow-hidden bg-white shadow-xl">
      <div className="bg-white px-4 py-5 sm:p-6">Contact text</div>
    </div>
  )
}
