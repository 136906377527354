import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Button, Icon, Avatar } from '@platform/ui'
import { ProductDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Loading } from '@src/components/common/loading'
import { formatMoney } from '@src/utils/formatMoney'

const { auctionService, productService, authService } = core
const { auction } = auctionService
const { product } = productService
const { authorizedUser } = authService

export const Bets: FC = observer(() => {
  const [isShowAll, setIsShowAll] = useState(false)
  const params = useParams()
  const productId = Number(params['id'])

  useEffect(() => {
    auctionService.fetchBitList(productId)
  }, [])

  const list = useMemo(() => {
    if (!auction.allBits) return []

    const bets = [...auction.allBits]
    if (!isShowAll) {
      return bets.slice(0, 6)
    }

    return bets
  }, [isShowAll, auction.allBits])

  const handleClick = useCallback(() => setIsShowAll(true), [])

  if (auction.statusFetchBits === 'loading') {
    return <Loading />
  }

  if (!product.data) return null

  return (
    <Flow directory="col" space={3.5} className="font-medium w-2xl lg:w-full">
      <Flow className="justify-between" full align="center">
        <span className="w-4/12">Имя участника</span>
        <span className="w-2/12">Цена за {product.data.measure.name}</span>
        <span className="w-2/12">Кол-во лотов</span>
        <span className="w-4/12">Комментарий</span>
      </Flow>
      {list.map((item) => (
        <Flow key={item.id} className="justify-between rounded-xl bg-primary-100 px-3 py-2" full align="center">
          <Flow space={2} align="center" className="w-4/12">
            <Avatar size="sm" src={item.userAvatar}></Avatar>
            <span className="font-normal italic">{authorizedUser.user?.id === item.userId ? 'Вы' : item.userName}</span>
          </Flow>
          <span className="w-2/12">{formatMoney(item.price, '₽')}</span>
          <span className="w-2/12">{`${item.lotCount} (${item.lotCount * Number(auction.lotSize)} ${
            (product.data as ProductDTO).measure.name
          })`}</span>
          <span className="w-4/12 flex justify-between">
            <span>{item.state}</span>
            <span className="text-xs text-primary-500 text-right">{item.createTime}</span>
          </span>
        </Flow>
      ))}
      {!isShowAll && (
        <Button
          color="primary"
          variant="link"
          icon={<Icon.ChevronRight />}
          iconPosition="right"
          onClick={handleClick}
          textClassName="text-xs md:text-md font-normal"
        >
          Показать все ставки
        </Button>
      )}
    </Flow>
  )
})
