import { ProductTag } from '@platform/core/dist/models'

export const extraConfig = [
  {
    label: 'Только со скидками',
    value: ProductTag.DISCOUNT,
  },
  {
    label: 'Только VIP объявления',
    value: ProductTag.VIP,
  },
]
