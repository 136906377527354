import { useEffect, useMemo, useState, FC, useCallback, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Icon, Input, Button, Dropdown, Option, Drawer } from '@platform/ui'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { core, ScreenMode } from '@src/core'
import { TreeCategories } from '@src/components/common/tree-categories'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'
import { CategoryNavigation } from '../category-navigation'
import { isObject } from '@src/utils/helpers'
import { SuggestionProductDTO } from '@platform/core/dist/modules/autocomplite/infra/dtos/product'
import { useAppLayoutContext } from '../layout'

const { categoryService, autocompliteService, layoutService } = core
const { screen } = layoutService

const isServer = process.env.APP_ENV === 'server'

const autocompliteProduct = (query: string) =>
  autocompliteService.product(query).then((resultOrError) => {
    if (resultOrError.isSuccess) {
      return resultOrError.getValue()
    }
    return []
  })

export const SearchForm: FC = observer(() => {
  const [searchValue, setSearchValue] = useState<Option>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()

  const [modalState, setModalState] = useState<boolean>(false)

  const openModal = useCallback(() => setModalState(true), [])

  const closeModal = useCallback(() => setModalState(false), [])

  const context = useAppLayoutContext()

  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const handleSearchValueChange = useCallback((value: Option) => {
    if (isObject(value)) {
      const product = value as SuggestionProductDTO
      navigate(`/product/${product.type}/${product.id}`)
    }
  }, [])

  const handleFindButtonClick = useCallback(() => {
    if (!searchValue) {
      inputRef.current?.focus()
    }
  }, [inputRef.current, searchValue])

  const categories = useMemo<any>(() => {
    if (categoryService.isEmpty) return []
    return categoryService.categories.list
  }, [categoryService.categories.list])

  const handleToMap = useNavigationApp({ to: LINKS.MAP })

  return (
    <form className="w-full">
      <div className="w-full flex items-stretch">
        {screen.mode === ScreenMode.desktop && (
          <Dropdown
            color="primary"
            icon={<Icon.Category size="xl" />}
            iconPosition="left"
            label="Категории"
            size="2xl"
            popperContentClassName="border-none w-full min-h-48 bg-white shadow-md"
            className="rounded-r-none pl-8 pr-38px"
          >
            <TreeCategories tree={categories} />
          </Dropdown>
        )}
        {screen.mode === ScreenMode.tablet && (
          <Button
            size="2xl"
            icon={<Icon.Category size="xl" />}
            iconPosition="left"
            color="primary"
            className="rounded-r-none pl-8 pr-38px"
            onClick={openModal}
          >
            Категории
          </Button>
        )}
        {screen.mode === ScreenMode.mobile && (
          <Button size="xl" color="primary" className="rounded-r-none px-3.5" onClick={openModal}>
            <Icon.Category />
          </Button>
        )}

        <Input.Group className="ml-3 flex items-stretch">
          <Input.Autocomplete
            inputRef={inputRef}
            size={screen.mode === ScreenMode.mobile ? 'xl' : '2xl'}
            value={searchValue}
            className="rounded-l-none"
            placeholder="Поиск товаров и услуг.."
            getOptions={autocompliteProduct}
            onChange={handleSearchValueChange}
            getWrapperOption={(option) => {
              if (isObject(option))
                return (
                  <Flow space={2} align="center">
                    <img className="rounded-lg w-38px h-28px" src={option.image as string}></img>
                    <span>{option.value}</span>
                  </Flow>
                )
            }}
          />
          {screen.mode !== ScreenMode.mobile ? (
            <Button
              color="positive"
              textClassName="font-bold"
              size="2xl"
              icon={<Icon.Search color="white" />}
              onClick={handleFindButtonClick}
            >
              Поиск
            </Button>
          ) : (
            <Button className="px-18px rounded-r-xl" color="positive" size="xl" onClick={handleFindButtonClick}>
              <Icon.Search color="white" />
            </Button>
          )}
        </Input.Group>
      </div>
      <div className="w-full flex justify-end items-center mt-3">
        <Button
          onClick={handleToMap}
          size="md"
          className="font-semibold"
          color="primary"
          variant="link"
          icon={<Icon.Location color="primary" />}
          iconPosition="right"
        >
          Искать на карте
        </Button>
      </div>
      {!isServer && !!context.mainNode.current && (
        <Drawer isOpen={modalState} onClose={closeModal} width="100%" containerRef={context.mainNode}>
          <Flow directory="col" space={7} className="w-full h-full overflow-y-scroll overflow-x-hidden scrollbar p-2">
            <Flow className="justify-end">
              <Button variant="link" className="text-xss" onClick={closeModal}>
                <Icon.Close size="xl" />
              </Button>
            </Flow>
            <CategoryNavigation />
          </Flow>
        </Drawer>
      )}
    </form>
  )
})
