import React from 'react'
import cn from 'classnames'

import { Field } from '@src/components/common/field'
import { PropertiesProps } from './types'

export const Properties: React.FC<PropertiesProps> = (props) => {
  const { className, data } = props

  return (
    <div className={cn(className, 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-18px gap-x-12 text-md')}>
      {data.map(({ id, name, value, measure, description }) => {
        return (
          <Field
            description={description}
            valueClassName="font-medium"
            key={id}
            label={name}
            value={`${value}${measure ?? ''}`}
          />
        )
      })}
    </div>
  )
}
