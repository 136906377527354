import React from 'react'
import { observer } from 'mobx-react-lite'
import { Loading } from '@platform/ui'
import { core } from '@src/core'

import { Users as OrderUsers } from './container/users'
import { Products as OrderProducts } from './container/products'

const cartService = core.cartService
export interface CartProps {
  isOneClickCheckout: boolean
}

export const Cart = observer((props: CartProps) => {
  const { status } = cartService.state

  React.useEffect(() => {
    cartService.getCart()
  }, [])

  if (status === 'loading') {
    return (
      <div className="flex relative justify-center items-start my-20">
        <Loading isLoading size={'3xl'} className="text-primary-500" />
      </div>
    )
  }

  return <OrderUsers>{({ userId }) => <OrderProducts userId={userId} />}</OrderUsers>
})

Cart.displayName = 'Cart'
