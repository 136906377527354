import { useMemo } from 'react'
import { core, ScreenMode } from '@src/core'

const { layoutService } = core
const { screen } = layoutService

interface IuseScreenMode<T> {
  [ScreenMode.desktop]: T
  [ScreenMode.tablet]: T
  [ScreenMode.mobile]: T
}

export const useScreenModeValue = <T>({ desktop, tablet, mobile }: IuseScreenMode<T>): T => {
  const value = useMemo(() => {
    if (screen.mode === ScreenMode.desktop) return desktop
    if (screen.mode === ScreenMode.tablet) return tablet
    return mobile
  }, [screen.mode])

  return value
}
