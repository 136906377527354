import { FC, useState, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, Modal, Title } from '@platform/ui'

import { core, ScreenMode } from '@src/core'
import { useScreenModeValue } from '@src/hooks'
import { Section } from '@src/components/common/section'
import { Flow, Directory, Align } from '@src/components/common/flow'

import formImg from '../../images/form.png'
import { SupportForm } from '../support-form'

const isServer = process.env.APP_ENV === 'server'

const { layoutService } = core
const { screen } = layoutService

export const Layout: FC = observer(() => {
  const [modalState, setModalState] = useState(false)

  const openModal = useCallback(() => setModalState(true), [])
  const closeModal = useCallback(() => setModalState(false), [])

  const wrapperDirectory = useScreenModeValue<Directory>({
    desktop: 'row',
    tablet: 'row',
    mobile: 'col',
  })

  const align = useScreenModeValue<Align>({
    desktop: 'start',
    tablet: 'start',
    mobile: 'center',
  })

  return (
    <Flow directory="col" full>
      <Outlet />
      <Flow directory="col" className="content-main mt-144px" space={10}>
        <Section className="border-t-xs border-neutral-300 pt-14">
          <Flow directory={wrapperDirectory} space={7} align={align}>
            <img src={formImg} className="w-160px h-160px" />
            <Flow directory="col" space={7}>
              <Flow directory="col" align={align}>
                <Title weight="medium" className="text-lg md:text-1xl">
                  Не нашли ответ на свой вопрос?
                </Title>
                <span>Задайте вопрос через форму</span>
              </Flow>
              <Button
                className="w-full max-w-345px"
                textClassName="font-semibold text-xs md:text-sm"
                color="primary"
                onClick={openModal}
              >
                Обратиться в техподдержку
              </Button>
            </Flow>
          </Flow>
        </Section>
        {screen.mode === ScreenMode.desktop && (
          <Flow align="end" className="justify-end h-full">
            <Button shape="circle" color="primary" onClick={openModal} className="w-12 h-12">
              ?
            </Button>
          </Flow>
        )}
        {!isServer && (
          <Modal isOpen={modalState} onClose={closeModal}>
            <Modal.Header className="bg-white" />
            <Modal.Body className="bg-white">
              <SupportForm onCrossClick={closeModal} />
            </Modal.Body>
            <Modal.Footer className="bg-white" />
          </Modal>
        )}
      </Flow>
    </Flow>
  )
})
