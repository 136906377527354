import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { CardOffer } from '@platform/ui'
import { ProductTypeDTO, ProductTypeDTOName } from '@platform/core/dist/modules/product/infra/dtos'
import { badgeProductMap } from '@src/constants'

import { CategoriesExchange } from '../../container/categories-exchange'
import { User } from '../../container/user'

import { BriefProps } from './types'

export const Brief: FC<BriefProps> = ({ product }) => {
  return (
    <Flow className="justify-between border-xs border-neutral-300 px-8 py-9 rounded-xl">
      <CardOffer
        src={product.images[0].src}
        title={product.name}
        price={product.price || undefined}
        oldPrice={product.oldPrice}
        badgeColor={badgeProductMap[product.format].color}
        badgeColorNumber={badgeProductMap[product.format].number}
        badgeText={ProductTypeDTOName[product.format]}
      />
      {product.format === ProductTypeDTO.exchange && (
        <Flow space={4} align="start" className="max-w-345px">
          <span className="font-medium text-md flex-static">Обмен на:</span>
          <CategoriesExchange product={product} />
        </Flow>
      )}
      <User onlyAvatar productId={product.id} />
    </Flow>
  )
}
