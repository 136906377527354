import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { SectionH2 } from '@src/components/common/section'
import { LINKS, ROUTES } from '@src/routes'
import { Code } from '@src/container/auth/code'
import { Social } from '@src/container/auth/social'
import { SingIn } from '@src/container/auth/sign-in'
import { SingUp } from '@src/container/auth/sign-up'
import { VerifyPhone } from '@src/container/auth/verify-phone'
import NotFound from '@src/pages/not-found'

import { Info } from './components/info'

const { AUTH } = ROUTES
const defaultLinkPath = '/create'

export const Auth: FC = () => {
  return (
    <SectionH2 className="py-6" title="Авторизация на сайте">
      <div className="w-3/4 mb-20">
        <Info>Для размещения объявления на сайте необходимо авторизоваться или зарегистрироваться на сайте.</Info>
      </div>
      <Routes>
        <Route index element={<SingIn defaultLinkPath={defaultLinkPath} nextPageLink={LINKS.CREATE_PRODUCT} />} />
        <Route path={AUTH.CODE.INDEX} element={<Code defaultLinkPath={defaultLinkPath} />} />
        <Route
          path={AUTH.SOCIAL.INDEX}
          element={<Social defaultLinkPath={defaultLinkPath} nextPageLink={LINKS.CREATE_PRODUCT} />}
        />
        <Route
          path={AUTH.SIGNIN.INDEX}
          element={<SingIn defaultLinkPath={defaultLinkPath} nextPageLink={LINKS.CREATE_PRODUCT} />}
        />
        <Route path={AUTH.SIGNUP.INDEX} element={<SingUp defaultLinkPath={defaultLinkPath} />} />
        <Route path={AUTH.VERIFY_PHONE.INDEX} element={<VerifyPhone />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SectionH2>
  )
}
