import { LINKS } from '@src/routes'
import { Link } from '@src/types'

export const linkMap: Link[] = [
  {
    name: 'Главная',
    to: LINKS.HOME,
  },
  {
    name: 'О нас',
    to: LINKS['ABOUT-US'],
  },
  {
    name: 'Аукцион',
    to: LINKS.AUCTION,
  },
  {
    name: 'Обмен',
    to: LINKS.EXCHANGE,
  },
  {
    name: 'Для бизнеса',
    to: LINKS['FOR-BUSINESS'],
  },
  // {
  //   name: 'Помощь',
  //   to: LINKS.HELP_HOME,
  // },
]
