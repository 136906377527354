import { FC } from 'react'
import cn from 'classnames'
import { Tag, Icon } from '@platform/ui'
import { Title } from '@src/components/common/title'
import { Flow } from '@src/components/common/flow'

import { TariffProps } from './types'

export const Tariff: FC<TariffProps> = ({ active, price, details, name, popular, onClick }) => {
  return (
    <div onClick={onClick}>
      <Flow
        directory="col"
        className={cn('shadow-md w-80 h-72 p-6 cursor-pointer rounded-md', {
          'border border-active border-sm': active,
        })}
      >
        <Flow full align="center" className="justify-between border-b border-neutral-400 pb-3">
          <Title size="md" weight="semibold">
            {name}
          </Title>
          {popular && (
            <Tag size="md" color="negative" shape="round" className="font-bold">
              Популярный
            </Tag>
          )}
        </Flow>
        <Flow directory="col" space={2} className="pt-4">
          {details.map((item, key) => (
            <span key={key} className="flex gap-x-2 items-center text-neutral-600">
              <Icon.CheckCircle color="transparent" />
              {item}
            </span>
          ))}
        </Flow>
        <div className="flex flex-1 items-end justify-center">
          <span className="font-semibold text-xl">{price} ₽</span>
        </div>
      </Flow>
    </div>
  )
}
