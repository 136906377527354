import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import NotFound from '../not-found'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import { ProductLayout } from './container/product-layout'
import { OfferLayout } from './container/offer-layout'

import { ProductAuction } from './product-auction'
import { ProductSell } from './product-sell'
import { ProductExchange } from './product-exchange'
import { ProductBuy } from './product-buy'

import { OfferExchange } from './offer-exchange'

import { Edit } from './edit'

const { PRODUCT } = ROUTES

const ProductPages = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route element={<ProductLayout />}>
          <Route path={PRODUCT.AUCTION.INDEX} element={<ProductAuction />} />
          <Route path={PRODUCT.SELL.INDEX} element={<ProductSell />} />
          <Route path={PRODUCT.BUY.INDEX} element={<ProductBuy />} />
          <Route path={PRODUCT.EXCHANGE.INDEX} element={<ProductExchange />} />
        </Route>
        <Route element={<OfferLayout />}>
          <Route path={PRODUCT['EXCHANGE-OFFER'].INDEX} element={<OfferExchange />} />
        </Route>
        <Route path={PRODUCT.EDIT.INDEX} element={<Edit />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

export default ProductPages
