import { FC, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { core, ScreenMode } from '@src/core'
import { Swiper, SwiperSlide, Tabs } from '@platform/ui'
import { Profile } from './container/profile'
import { SectionH1 } from '@src/components/common/section'
import { useProfileOutletContext } from './hooks/useProfileContext'

import { SettingsTabs } from './types'
import { settingsTabsMap } from './constants'

const { layoutService } = core
const { screen } = layoutService

export const Settings: FC = observer(() => {
  const [statusTabSelected, setStatusTabSelected] = useState<SettingsTabs>(SettingsTabs.profile)

  const handleChangeStatusTab = useCallback((index: number) => setStatusTabSelected(index), [])

  const { backCallback } = useProfileOutletContext()

  return (
    <SectionH1 title="Настройки" titleClassName="ml-5" onBackClick={backCallback}>
      <Tabs
        navTextColor="neutral-400"
        navTextActiveColor="neutral-900"
        value={statusTabSelected}
        onChange={handleChangeStatusTab}
        className="items-start"
      >
        {screen.mode === ScreenMode.mobile ? (
          <Swiper className="pb-4 px-5 border-b border-neutral-400" arrowClassname="top-0" arrowVariant="link">
            {settingsTabsMap.map((item, key) => (
              <SwiperSlide className="w-auto mr-10">
                <Tabs.Button key={key} index={item.index} className="text-lg font-medium">
                  {item.title}
                </Tabs.Button>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Tabs.NavList className="gap-x-10 pb-4 px-5 border-b border-neutral-400">
            {settingsTabsMap.map((item, key) => (
              <Tabs.Button key={key} index={item.index} className="text-lg font-medium">
                {item.title}
              </Tabs.Button>
            ))}
          </Tabs.NavList>
        )}
        <Tabs.Body className="px-0 md:px-5 w-full xl:w-2/3 mt-46px md:mt-58px">
          <Tabs.Content index={SettingsTabs.profile}>
            <Profile />
          </Tabs.Content>
          <Tabs.Content index={SettingsTabs.organizationData}>
            <></>
          </Tabs.Content>
          <Tabs.Content index={SettingsTabs.notifications}>
            <></>
          </Tabs.Content>
          <Tabs.Content index={SettingsTabs.security}>
            <></>
          </Tabs.Content>
        </Tabs.Body>
      </Tabs>
    </SectionH1>
  )
})
