import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useScreenModeValue } from '@src/hooks'

import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'

import { UserMenu } from '../user-menu'
import { ProfileOutletContext } from '../../types'

const { layoutService } = core
const { screen } = layoutService

export const Layout: React.FC = observer(() => {
  const [isShowMenu, setIsShowMenu] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => setIsShowMenu(false), [pathname])

  const closeMenu = useCallback(() => setIsShowMenu(false), [])

  const context: ProfileOutletContext = useScreenModeValue({
    desktop: {},
    tablet: {
      backCallback: () => setIsShowMenu(true),
    },
    mobile: {
      backCallback: () => setIsShowMenu(true),
    },
  })

  return (
    <Flow className="content-main py-4 md:py-7 lg:py-20" align="start">
      {(isShowMenu || screen.mode === ScreenMode.desktop) && <UserMenu onClickCloseMenu={closeMenu} />}
      {!isShowMenu && (
        <div className="w-full lg:pl-10 xl:pl-24">
          <Outlet context={context} />
        </div>
      )}
    </Flow>
  )
})
