import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Title, List, ListItem } from '@platform/ui'
import cn from 'classnames'

import { CategoryBlockProps } from './types'

export const CategoryBlock: FC<CategoryBlockProps> = ({ title, topicList, color }) => {
  return (
    <Flow directory="col" space={5}>
      <Title weight="medium" color={color} textClassName="text-lg md:text-1xl">
        {title}
      </Title>
      <List space={3.5}>
        {topicList.map((item, key) => (
          <ListItem key={key}>
            <span className={cn('text-sm md:text-md', `text-${color}`)}>{item}</span>
          </ListItem>
        ))}
      </List>
    </Flow>
  )
}
