import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { Flow } from '@src/components/common/flow'
import { core } from '@src/core'
import { useParams } from 'react-router-dom'
import { Section } from '@src/components/common/section'
import { Comment } from './container/comment'
import { LINKS } from '@src/routes'
import { Title } from '@platform/ui'

const { layoutService } = core
const { screen } = layoutService

export const Topic: FC = observer(() => {
  const params = useParams()

  return (
    <Flow directory="col" className="pt-7 content-main" space={7}>
      <Breadcrumbs
        items={[
          { children: 'Главная', href: LINKS.HOME },
          { children: 'Помощь', href: LINKS.HELP_HOME },
          { children: 'Личный кабинет', href: `/help/lichny-kabinet` },
          { children: 'Изменение контактной информации' },
        ]}
      />
      <Title weight="bold" tag="h1" textClassName="text-1xl md:text-2xl">
        Изменение контактной информации
      </Title>
      <Section className="border-t-xs border-neutral-300 pt-14">
        <Comment />
      </Section>
    </Flow>
  )
})
