import { FC, useCallback } from 'react'
import cn from 'classnames'
import { Button } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { UserMenuItemProps } from './types'

export const UserMenuItem: FC<UserMenuItemProps> = ({ icon, isActive, title, onClick }) => {
  const handleClick = useCallback(() => onClick(), [onClick])

  return (
    <Flow space={4} align="center">
      <span className="text-neutral-500 text-lg">{icon}</span>
      <Button
        onClick={handleClick}
        variant="link"
        color={isActive ? 'black' : 'primary'}
        className={cn('text-lg leading-xl', { 'font-normal': !isActive, 'font-medium': isActive })}
      >
        {title}
      </Button>
    </Flow>
  )
}
