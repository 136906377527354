import { FC, useCallback } from 'react'
import { Flow } from '@src/components/common/flow'
import { CardOffer, Radio } from '@platform/ui'
import { badgeProductMap } from '@src/constants'
import { ProductTypeDTOName } from '@platform/core/dist/modules/product/infra/dtos'
import cn from 'classnames'

import { OfferProductProps } from './types'

export const OfferProduct: FC<OfferProductProps> = ({ productType, onChange, data, selected }) => {
  const handleChange = useCallback(() => {
    onChange(data.id)
  }, [onChange, data.id])

  return (
    <div onClick={handleChange} className="p-2">
      <Flow
        className={cn('px-8 py-7 shadow-md max-w-710px w-full rounded-xl cursor-pointer', {
          'border-sm border-primary-500': selected,
        })}
        space={4}
        align="start"
      >
        <Radio value={data.id} isChecked={selected} onChange={handleChange} />
        <CardOffer
          src={data.images[0]}
          title={data.name}
          price={data.price || undefined}
          oldPrice={data.oldPrice}
          badgeColor={badgeProductMap[productType].color}
          badgeColorNumber={badgeProductMap[productType].number}
          badgeText={ProductTypeDTOName[productType]}
        />
      </Flow>
    </div>
  )
}
