import { useEffect } from 'react'

import { core, ScreenMode } from '@src/core'
import { MOBILE_MAX_WIDTH, TABMLE_MAX_WIDTH } from '@src/constants/screen'

const { layoutService } = core
const isServer = process.env.APP_ENV === 'server'

export const useScreenMode = (): void => {
  useEffect(() => {
    if (isServer) {
      return
    }

    const updateScreenMode = () => {
      const { innerWidth: width } = window
      switch (true) {
        case width < MOBILE_MAX_WIDTH:
          layoutService.screen.changeMode(ScreenMode.mobile)
          break
        case width < TABMLE_MAX_WIDTH:
          layoutService.screen.changeMode(ScreenMode.tablet)
          break
        default:
          layoutService.screen.changeMode(ScreenMode.desktop)
      }
    }

    updateScreenMode()
    window.addEventListener('resize', updateScreenMode)
    return () => {
      window.removeEventListener('resize', updateScreenMode)
    }
  }, [])
}
