import { forwardRef } from 'react'
import { observer } from 'mobx-react-lite'
import { core, ScreenMode } from '@src/core'

import { Section } from './section'
import { SectionHProps } from './types'

const { layoutService } = core
const { screen } = layoutService

export const SectionH2 = observer(
  forwardRef<HTMLDivElement, SectionHProps>((props, ref) => (
    <Section
      {...props}
      ref={ref}
      tag="h2"
      weight="medium"
      titleContainerClassName="pb-18px md:pb-8"
      titleClassName="leading-md md:leading-2xl"
      sizeTitle={screen.mode === ScreenMode.mobile ? 'lg' : '1xl'}
    />
  ))
)
