import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { CART_STEPS } from '../constants'

const DEFAULT_STEP = '0'

export type UseStepBy = {
  defaultValue?: string
}

export const useStepBy = (options: UseStepBy = {}) => {
  const { defaultValue = DEFAULT_STEP } = options

  const [currentStep, setCurrentStep] = useState(defaultValue)
  const navigate = useNavigate()
  const location = useLocation()

  const keys = Object.keys(CART_STEPS)
  const labels = Object.values(CART_STEPS)

  useEffect(() => {
    const currentIndex = String(keys.findIndex((k) => k === location.pathname))
    if (currentIndex !== currentStep) {
      setCurrentStep(currentIndex)
    }
  }, [location.pathname])

  const handleSetStep = useCallback((step: string) => navigate(keys[step]), [navigate])

  return {
    steps: Object.keys(keys),
    labels,
    currentStep,
    setCurrentStep: handleSetStep,
  }
}
