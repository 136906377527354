import { useCallback, FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { notification, Button, Input, Icon } from '@platform/ui'
import { LINKS } from '@src/routes'
import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { useNavigationApp } from '@src/hooks'

import { AuthSignInProps } from './types'
import { LoginForm } from './login-form'
import { FormItem } from '@src/components/common/form-item'

const { cartService, authService, userService } = core
const { userProfile } = userService
const { authorizationForm } = authService
const { phone, password, email } = authorizationForm

export const SingIn: FC<AuthSignInProps> = observer(({ defaultLinkPath = '', nextPageLink = LINKS.PROFILE_HOME }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const handleToSignUp = useNavigationApp({ to: defaultLinkPath + LINKS.AUTH_SIGNUP })

  useEffect(() => {
    return () => {
      authorizationForm.reset()
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    if (authService.isLoading) {
      return
    }

    const result = await authService.signIn()

    if (result.isFailure) {
      notification.error({
        message: 'Ошибка при авторизации',
        description: result.getError().toString(),
      })
      return
    }

    const authResult = await authService.authorized()
    if (authResult.isFailure) return

    userProfile.setState(authResult.getValue())

    const cartResult = await cartService.getUserCart()
    if (cartResult.isFailure) return

    const to = searchParams.get('redirect') || nextPageLink
    navigate(to)
  }, [searchParams, navigate])

  return (
    <LoginForm title="Вход" onSubmit={handleSubmit}>
      <Flow directory="col" className="space-y-2.5 md:space-y-18px">
        {/* <Select className="w-15" options={options} value={selected} onChange={handleModeChange} /> */}
        <FormItem withoutLabel errorText={email.errorText}>
          <Input
            size="md"
            color="primary"
            placeholder={email.label}
            onChangeValue={email.changeValue}
            error={!email.isValid}
          />
        </FormItem>
        <FormItem withoutLabel errorText={password.errorText}>
          <Input
            size="md"
            color="primary"
            type="password"
            placeholder={password.label}
            onChangeValue={password.changeValue}
            error={!password.isValid}
          />
        </FormItem>
      </Flow>
      <Button
        color="primary"
        size="md"
        shape="round"
        type="submit"
        loading={authService.isLoading}
        className="w-full mt-14px md:mt-22px"
      >
        Войти
      </Button>
      <Flow align="center" className="space-x-1.5 mt-2.5 md:mt-14px">
        <span>Нет аккаунта?</span>
        <Button
          className="underline font-normal"
          color="primary"
          variant="link"
          onClick={handleToSignUp}
          iconPosition="right"
          size="md"
          icon={<Icon.ArrowLong position="right" />}
        >
          Зарегистрироваться
        </Button>
      </Flow>
    </LoginForm>
  )
})
