import { observer } from 'mobx-react-lite'
import { useParams, Routes, Route } from 'react-router-dom'
import { ROUTES } from '@src/routes'
import { Flow } from '@src/components/common/flow'

import { CartLayout } from './container/layout'
import { Order } from './order'
import { Cart } from './cart'

export interface ByPageProps {}

export const ByPage = observer(() => {
  const params = useParams()
  const isOneClickCheckout = Boolean(params.isOneClickCheckout)

  return (
    <Flow className="content-main mt-10" directory="col" space={4}>
      <Routes>
        <Route element={<CartLayout />}>
          <Route path={ROUTES.BY.CART.INDEX} element={<Cart isOneClickCheckout={isOneClickCheckout} />} />
          <Route path={ROUTES.BY.ORDER.INDEX} element={<Order />} />
        </Route>
        <Route path={ROUTES.BY.CONFIRM.INDEX} element={<div>confirm</div>} />
      </Routes>
    </Flow>
  )
})

ByPage.displayName = 'CartPage'

export default ByPage
