import { FC } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Icon } from '@platform/ui'

import sections from './config'
import { FooterProps } from './types'

export const Footer: FC<FooterProps> = ({ handleToTop, className }) => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={cn(className, 'content-main pt-22 relative')}>
      <div className="pt-9 pb-11 border-t border-neutral-300 opacity-70">
        <div className="flex justify-between flex-col md:flex-row flex-wrap gap-y-10">
          {sections.map((item, key) => {
            const { title, links } = item
            return (
              <div key={key}>
                <h3 className="text-1xl md:text-sm lg:text-1xl font-semibold mb-8">{title}</h3>
                <ul className="flex flex-col gap-y-1 lg:gap-y-3">
                  {links.map((link, key) => (
                    <li key={key}>
                      <Link className="text-black text-md md:text-sm lg:text-md" to={link.to}>
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
        <div className="text-center text-xs lg:text-sm  mt-10">
          <span>
            Copyright © {currentYear}. Все права защищены.&nbsp;
            <Link to="" className="text-black underline">
              Пользовательское соглашение
            </Link>
            ,&nbsp;
            <Link to="" className="text-black underline">
              Конфиденциальность
            </Link>
            ,&nbsp;
            <Link to="" className="text-black underline">
              Файлы cookie
            </Link>
          </span>
        </div>
      </div>
      <div
        className="hidden md:flex flex-col gap-y-2 cursor-pointer items-center w-16 h-14 absolute right-9 bottom-96 mb-7 lg:mb-20"
        onClick={handleToTop}
      >
        <Icon.Arrow position="up" />
        <span>наверх</span>
      </div>
    </footer>
  )
}
