import React, { FC, useCallback, useState, useRef, useContext, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Select, SelectOption, DatePicker, Input } from '@platform/ui'
import { FieldSelectOption } from '@platform/core/dist/modules/product/domain/fields/models'
import { ProductTypeDTO } from '@platform/core/dist/modules/product/infra/dtos'

import { core } from '@src/core'
import { FormItem } from '@src/components/common/form-item'
import { Label } from '@src/components/common/label'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'

import { CreateProductContext } from '../context'
import { PublishAuction } from './publish-auction'
import { PublishSell } from './publish-sell'
import { PublishBuy } from './publish-buy'
import { PublishExchange } from './publish-exchange'

const { createProductService } = core
const { createProduct } = createProductService

const {
  format,
  datePublish,
  measure,
  priceAuction,
  priceStepAuction,
  priceSell,
  priceOldSell,
  priceBuy,
  priceExchange,
  categoriesExchange,
} = createProduct

const priceConfig: Record<string, React.FC> = {
  [ProductTypeDTO.auction]: PublishAuction,
  [ProductTypeDTO.sell]: PublishSell,
  [ProductTypeDTO.buy]: PublishBuy,
  [ProductTypeDTO.exchange]: PublishExchange,
}

export const Publish: FC = observer(() => {
  const [isCalendarShow, setIsCalendarShow] = useState<boolean>(true)
  const publishNode = useRef<HTMLDivElement>(null)
  const context = useContext(CreateProductContext)
  const [handleToPublish] = useScrollIntoView({ ref: publishNode })

  useEffect(() => {
    context?.addFields(
      [
        format,
        datePublish,
        measure,
        priceAuction,
        priceStepAuction,
        priceSell,
        priceOldSell,
        priceBuy,
        priceExchange,
        categoriesExchange,
      ].map((item) => ({
        key: item.key,
        handleScroll: handleToPublish,
        orderMarkup: 6,
      }))
    )
  }, [])

  const handleFormatChange = useCallback((newFormat: SelectOption) => {
    format.changeValue(newFormat as FieldSelectOption)
  }, [])

  const handleMeasureChange = useCallback((measureOption: SelectOption) => {
    measure.changeValue(measureOption as FieldSelectOption)
  }, [])

  const handleChangeDatePublish = useCallback((value: string) => datePublish.changeValue(value), [])

  const selectedFormat = format.value as FieldSelectOption
  const PublishFields = priceConfig[selectedFormat.value as string]

  return (
    <SectionH2 ref={publishNode} title="Параметры публикации">
      <Flow directory="col" space={11}>
        <FormItem
          labelClass="w-80"
          contentClass="w-full"
          label={
            <Label
              label={format.label}
              required={format.required}
              textStyle="font-medium text-black text-sm"
              hint="Укажите способ публикации продукта на портале"
            />
          }
          errorText={format.errorText}
        >
          <Select
            disabled={context?.isEdit}
            variant="outline"
            options={format.options}
            value={format.value as SelectOption}
            onChange={handleFormatChange}
            size="md"
            className="px-3.5 w-36"
          />
          {/* {selectedFormat.value === ProductTypeDTO.auction && !context?.isEdit && (
              <Input.Group>
                <Button
                  variant={!isCalendarShow ? 'solid' : 'outline'}
                  color={!isCalendarShow ? 'primary' : undefined}
                  onClick={() => setIsCalendarShow(false)}
                >
                  Публикация после модерации
                </Button>
                <Button
                  variant={isCalendarShow ? 'solid' : 'outline'}
                  color={isCalendarShow ? 'primary' : undefined}
                  onClick={() => setIsCalendarShow(true)}
                >
                  Публикация в заданный день
                </Button>
              </Input.Group>
            )} */}
        </FormItem>
        {!context?.isEdit && selectedFormat.value === ProductTypeDTO.auction && (
          <FormItem
            labelClass="w-80"
            contentClass="w-full"
            label={
              <Label
                label={datePublish.label}
                required={datePublish.required}
                textStyle="font-medium text-black text-sm"
              />
            }
            errorText={datePublish.errorText}
          >
            <div className="w-44">
              <DatePicker
                size="md"
                minDate={datePublish.minDate}
                format={datePublish.format}
                onChange={handleChangeDatePublish}
              />
            </div>
          </FormItem>
        )}
        <FormItem
          labelClass="w-80"
          contentClass="w-full"
          label={<Label label={measure.label} required={measure.required} textStyle="font-medium text-black text-sm" />}
          errorText={measure.errorText}
        >
          <div className="space-y-8">
            <Select
              disabled={selectedFormat.value === ProductTypeDTO.auction && context?.isEdit}
              variant="outline"
              size="md"
              className="px-3.5"
              options={measure.options}
              value={(measure.value as SelectOption) ?? null}
              onChange={handleMeasureChange}
            />
          </div>
        </FormItem>
        <PublishFields />
      </Flow>
    </SectionH2>
  )
})
