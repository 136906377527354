import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useSearchParams } from 'react-router-dom'
import { models } from '@platform/core'
import { Tag } from '@platform/ui/dist/components/tag'
import { ProductTypeDTOName } from '@platform/core/dist/modules/product/infra/dtos'
import { FilterSearchParam, sortOptions } from '@src/search-params-settings'
import { validateSearchParam } from '@src/search-params-settings/helper'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { Flow } from '@src/components/common/flow'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { SelectedFilter } from './types'
import { CATEGORY } from './constants'

const { PRICE, PRODUCT_TYPE, SORT, EXCHANGE_CATEGORY } = FilterSearchParam

const { categoryService } = core

export const SelectedFilters: FC = observer(() => {
  const { slug } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const handleToCategoryHome = useNavigationApp({ to: LINKS.CATEGORY_HOME })

  const filters = useMemo(() => {
    const result: SelectedFilter[] = []

    if (slug) {
      const [, categoryId] = (slug as string).split('--')
      const category = categoryService.categories.findById(Number(categoryId))
      result.push({ key: CATEGORY, name: category.name })
    }

    searchParams.forEach((value, key) => {
      if (validateSearchParam(key, value)) {
        switch (key) {
          case PRICE:
            const [min, max] = value.split(',')
            result.push({ key, name: `От ${min} до ${max}` })
            break
          case PRODUCT_TYPE:
            result.push({ key, name: ProductTypeDTOName[value] })
            break
          case SORT:
            const option = sortOptions.find((item) => item.value === value)
            if (option) {
              result.push({ key, name: option.name as string })
            }
            break
          default:
            break
        }
      } else {
        searchParams.delete(key)
      }
    })

    return result
  }, [slug, searchParams])

  const handleCloseTag = (filter: SelectedFilter) => {
    switch (filter.key) {
      case CATEGORY:
        handleToCategoryHome()
        break
      case PRICE:
        searchParams.delete(PRICE)
        setSearchParams(searchParams, { replace: true })
        break
      case PRODUCT_TYPE:
        searchParams.delete(PRODUCT_TYPE)
        searchParams.delete(EXCHANGE_CATEGORY)
        setSearchParams(searchParams, { replace: true })
        break
      case SORT:
        searchParams.delete(SORT)
        setSearchParams(searchParams, { replace: true })
        break
      default:
        break
    }
  }

  return (
    <Flow className="flex-wrap gap-2" full>
      {filters.map((item, key) => (
        <Tag
          colorClose="white"
          key={key}
          size="sm"
          color="positive"
          shape="circle"
          colorNumber={500}
          closable
          onClose={() => handleCloseTag(item)}
          className="text-white font-semibold px-2 py-1"
        >
          {item.name}
        </Tag>
      ))}
    </Flow>
  )
})
