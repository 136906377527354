import { ROUTES } from '@src/routes'

const create = ROUTES.CREATE

export const baseCreateBreadcrumb = 'Разместить объявление'

export const createSteps = {
  [create.AUTH.INDEX]: 'Авторизация',
  [create.PRODUCT.INDEX]: 'Создание объявления',
  [create.MODERATION.INDEX]: 'Модерация',
  [create.PUBLICATION.INDEX]: 'Публикация',
}
