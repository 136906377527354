import { FC, useEffect, useMemo, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ProductList } from '@src/components/desktop/product-list'
import { Pagination } from '@src/components/desktop/pagination'
import { core } from '@src/core'
import { ParamsFilteredProducts } from '@platform/core/dist/api/v1/product'
import { FilterSearchParam, sortParamMap } from '@src/search-params-settings'
import { validateSearchParam } from '@src/search-params-settings/helper'
import { ProductTypeFromDtoMap } from '@platform/core/dist/modules/product/infra/dtos'
import { ProductTag } from '@platform/core/dist/models'

const { SORT, PRODUCT_TYPE, EXCHANGE_CATEGORY, EXTRA, PRICE } = FilterSearchParam

const { cartService, productService } = core
const { filteredProducts } = productService

export const ProductFilter: FC = observer(() => {
  const { slug } = useParams()

  const [searchParams] = useSearchParams()

  const handleClickBasketButton = useCallback(cartService.clickAddCartItem, [])

  const categoryId = useMemo(() => {
    if (!slug) return
    const [code, id] = (slug as string).split('--')
    return id
  }, [slug])

  useEffect(() => {
    return () => {
      filteredProducts.reset()
    }
  }, [])

  const params = useMemo(() => {
    const result: ParamsFilteredProducts = {
      size: 15,
      page: filteredProducts.page,
      ...(categoryId && { searchCategory: categoryId }),
    }

    searchParams.forEach((value, key) => {
      if (validateSearchParam(key, value)) {
        switch (key) {
          case PRICE:
            const [min, max] = value.split(',')
            result.searchStartPrice = Number(min)
            result.searchEndPrice = Number(max)
            break
          case SORT:
            result.sort = sortParamMap[value].sort
            result.sortColumn = sortParamMap[value].sortColumn
            break
          case PRODUCT_TYPE:
            result.typeFlyer = ProductTypeFromDtoMap[value]
            break
          case EXCHANGE_CATEGORY:
            result.searchExchangeCategory = value.split(',')
            break
          case EXTRA:
            result.tag = value.split(',') as ProductTag[]
            break
          default:
            break
        }
      }
    })

    return result
  }, [searchParams, categoryId, filteredProducts.page])

  useEffect(() => {
    productService.getFilteredProducts(params)
  }, [params])

  return (
    <div className="w-full">
      <ProductList
        loadMoreWrapperClass="py-20"
        products={filteredProducts.list}
        requestStatus={filteredProducts.status}
        numberColumns={3}
        onClickBasketButton={handleClickBasketButton}
      />
      <Pagination
        currentPage={filteredProducts.page}
        totalPages={filteredProducts.totalPages}
        setPage={(value) => filteredProducts.setState({ page: value })}
      />
    </div>
  )
})
