import React, { useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { CartName, CartNameSkeleton } from '@platform/ui'
import { SmallProduct } from '@platform/ui/dist/components/cart/types'

const { cartService } = core

export interface UserProps {
  isPreview?: boolean
  children: ({ userId: string }) => React.ReactNode
}

export const Users = observer((props: UserProps) => {
  const { users, userIds, usersStatus, items, groupItems } = cartService.state
  const funChildren = props.children

  useEffect(() => {
    cartService.getUsers()
  }, [userIds])

  if (typeof funChildren !== 'function') {
    throw new Error('Children is not Function')
  }

  const hashItemMap = useMemo(() => {
    const map = new Map()
    items.forEach((item) => map.set(item.entityId, item))
    return map
  }, [items])

  const handleRemove = useCallback((_: React.MouseEvent<HTMLButtonElement>, userId: unknown) => {
    const ids = cartService.state.items.filter((i) => String(i.userId) === String(userId)).map((i) => i.id)
    cartService.clickRemoveCartItems(ids)
  }, [])

  const childrens = userIds.map((userId, i) => {
    const orderNumber = i + 1
    const key = `${userId}-${orderNumber}`
    const children = funChildren({ userId })

    if (usersStatus === 'loading') {
      return (
        <div key={key} className="flex flex-col space-y-9">
          <CartNameSkeleton />
          <div className="relative rounded-lg flex flex-col space-y-9 px-4 py-5 sm:p-6">{children}</div>
        </div>
      )
    }

    if (usersStatus === 'loaded' && users[userId]) {
      const user = users[userId]
      let smallProducts = []
      if (props.isPreview) {
        groupItems[userId].entityIds.map<SmallProduct>((entityId) => {
          const product = hashItemMap.get(entityId)
          return { label: String(product.quantity), src: product.image }
        })
      }

      return (
        <div key={key} className="flex flex-col space-y-9">
          <CartName
            title={`Заказ №${orderNumber}`}
            fullName={user.userName}
            avatarSrc={user.imageSrc}
            rating={user.rating}
            onRemove={handleRemove}
            metaData={userId}
            products={smallProducts}
          />
          <div className="relative rounded-lg flex flex-col space-y-9 px-4 py-5 sm:p-6">{children}</div>
        </div>
      )
    }

    return null
  })

  return <>{childrens}</>
})
