export function isObject(val: unknown): val is Record<string, any> {
  return typeof val === 'object' && val !== null && !Array.isArray(val)
}

/**
 * Получить день, месяц, год в формаде dd.mm.yyyy
 * @param date дата в формате Date
 * @returns
 */
export const formatDate = (date: Date): string => {
  return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
}

/**
 * Получить время в формаде hh.mm
 * @param date дата в формате Date
 * @returns
 */
export const formatTime = (date: Date): string => {
  return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
}

/**
 * Получить кол-во секунд, минут, часов, дней
 * @param mls миллисекунды
 * @returns
 */
export const parseMilliseconds = (mls: number): string => {
  const seconds = Math.floor((mls / 1000) % 60)
  const minutes = Math.floor((mls / (1000 * 60)) % 60)
  const hours = Math.floor((mls / (1000 * 60 * 60)) % 24)
  const days = Math.floor((mls / (1000 * 60 * 60 * 24)) % 30)

  let result = `${days}дн ${hours}ч`

  if (!days) {
    result = `${hours}ч ${minutes}мин`
  }

  if (!hours) {
    result = `${minutes}мин ${seconds}с`
  }

  return result
}

export const splitArrayToSubarrays = <T>(array: T[], size: number): T[][] => {
  const subarray: T[][] = []
  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size)
  }
  return subarray
}

export const isStringOnlyDigits = (value: string): boolean => {
  return /^\d+$/.test(value)
}
