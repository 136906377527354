import React from 'react'
import { observer } from 'mobx-react-lite'
import { Loading } from '@platform/ui'

import { core } from '@src/core'

import { Users as OrderUsers } from './container/users'
import { Contact as OrderContact } from './contact'
import { Conditions as OrderConditions } from './container/conditions'

const cartService = core.cartService

export interface OrderProps {}

export const Order = observer((props: OrderProps) => {
  const { status } = cartService.state

  if (status === 'loading') {
    return (
      <div className="flex relative justify-center items-start my-20">
        <Loading isLoading size={'3xl'} className="text-primary-500" />
      </div>
    )
  }

  return (
    <>
      <OrderUsers isPreview>{({ userId }) => <OrderConditions userId={userId} />}</OrderUsers>
      <OrderContact />
    </>
  )
})

Order.displayName = 'Order'
