import { useState, useEffect, FC } from 'react'
import { core } from '@src/core'
import { Rating as CommonRating } from '@src/components/common/rating'
import { ProductRatingDTO } from '@platform/core/dist/modules/product/infra/dtos/ProductRatingDto'
import { Spinner } from '@platform/ui'

import { RatingProps } from './types'

const { productService } = core

export const Rating: FC<RatingProps> = ({ productId, className }) => {
  const [status, setStatus] = useState<Status>('pending')
  const [rank, setRank] = useState<ProductRatingDTO | null>(null)

  useEffect(() => {
    if (productId) {
      setStatus('loading')
      productService
        .fetchProductRating(productId)
        .then((res) => {
          if (res?.isSuccess) {
            setRank(res.getValue())
          }
        })
        .then(() => setStatus('loaded'))
    }
  }, [productId])

  return (
    <>
      {status === 'loading' && <Spinner size="1xl" className="text-primary-500" />}
      {status === 'loaded' && rank && rank.percentRank > 0 && rank.countRank > 0 && (
        <CommonRating className={className} value={rank.percentRank} count={rank.countRank} text="отзывов" />
      )}
    </>
  )
}
