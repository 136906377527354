import { FC, useCallback, useState, useRef, memo } from 'react'
import { Placemark as PlacemarkLib } from '@pbe/react-yandex-maps'
import ymaps from 'yandex-maps'

import { ClusterProps } from './types'

export const Cluster: FC<ClusterProps> = memo(({ onClick, count, hash, ...rest }) => {
  const [ymapsLoad, setYmapsLoad] = useState(false)
  const ymapsRef = useRef<typeof ymaps | null>(null)

  const handleClick = useCallback(
    (event: any) => {
      const object = event.get('target')
      const coords = object.geometry.getCoordinates()
      onClick?.(hash, count, coords)
    },
    [onClick, hash, count]
  )

  const handleLoadYmaps = useCallback((api: typeof ymaps) => {
    ymapsRef.current = api
    setYmapsLoad(true)
  }, [])

  return (
    <PlacemarkLib
      onClick={handleClick}
      onLoad={handleLoadYmaps}
      options={{
        iconShape: {
          type: 'Circle',
          //@ts-ignore
          coordinates: [0, 0],
          radius: 16,
        },
        iconLayout: ymapsLoad
          ? ymapsRef.current?.templateLayoutFactory.createClass(
              `<div class="relative">
                <span class="absolute rounded-full text-white text-md font-medium bg-primary-500 w-7 h-7 -left-3.5 -top-3.5 flex justify-center items-center">
                  <span class="mb-0.5">${count}</span>
                </span>
              </div>`
            )
          : undefined,
      }}
      {...rest}
    />
  )
})
