import { FC, useCallback } from 'react'
import { Checkbox } from '@platform/ui'
import { Title } from '@src/components/common/title'
import { Flow } from '@src/components/common/flow'

import { UpgradeProps } from './types'

export const Upgrade: FC<UpgradeProps> = ({ name, id, description, onChange, selected }) => {
  const handleChange = useCallback(() => {
    onChange(id)
  }, [onChange, id])

  return (
    <div onClick={handleChange}>
      <Flow full space={2} className="shadow-md p-5 rounded-md cursor-pointer" align="start">
        <div className="pt-1">
          <Checkbox isChecked={selected} className="mt-1" onChange={handleChange} />
        </div>
        <Flow directory="col" space={5}>
          <Title size="md" weight="semibold">
            {name}
          </Title>
          <span>{description}</span>
        </Flow>
      </Flow>
    </div>
  )
}
