import { FC, useMemo } from 'react'
import { Breadcrumbs as BreadcrumbsComponent } from '@src/components/common/breadcrumbs'

import { navigationAuth } from '../../constant'
import { useParams } from 'react-router-dom'

export const Breadcrumbs: FC = () => {
  const params = useParams()

  const items = useMemo(() => {
    return [
      { href: '/', children: 'Главная' },
      {
        children: navigationAuth[`/${params['*']}`],
      },
    ]
  }, [params])

  return <BreadcrumbsComponent items={items} />
}
