import cn from 'classnames'
import { FC } from 'react'

import { IFormItem } from './types'

export const FormItem: FC<IFormItem> = ({
  className,
  label,
  children,
  labelClass,
  contentClass,
  errorText,
  withoutLabel = false,
}) => {
  if (withoutLabel) {
    return (
      <div className="flex flex-col">
        {children}
        <div className="mt-1 text-xs text-invalid">{errorText}</div>
      </div>
    )
  }

  return (
    <div className={cn('flex -mx-2', className)}>
      <div className={cn('flex-none px-2', labelClass)}>
        <div>{label}</div>
        <div className="mt-2 text-invalid">{errorText}</div>
      </div>
      <div className={cn('px-2', contentClass)}>{children}</div>
    </div>
  )
}
