import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Button, Icon } from '@platform/ui'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

export const NavMenuBar: FC = ({}) => {
  const handleToHome = useNavigationApp({ to: LINKS.HOME })
  const handleToProfile = useNavigationApp({ to: LINKS.PROFILE_HOME })
  const handleToFavorites = useNavigationApp({ to: LINKS.PROFILE_FAVORITES })
  const handleToCatalog = useNavigationApp({ to: LINKS.CATEGORY_HOME })
  const handleToCreateProduct = useNavigationApp({ to: LINKS.CREATE_PRODUCT })

  return (
    <nav className="flex p-3 justify-between z-header fixed bottom-0 bg-neutral-100 w-full text-neutral-400 text-xss font-medium">
      <Flow directory="col" align="center">
        <Button onClick={handleToHome} color="bg-neutral-100" size="2xl" icon={<Icon.HomeFull />} variant="link" />
        <span>Главная</span>
      </Flow>
      <Flow directory="col" align="center">
        <Button
          onClick={handleToCatalog}
          color="bg-neutral-100"
          size="2xl"
          icon={<Icon.CategoryTab />}
          variant="link"
        />
        <span>Каталог</span>
      </Flow>
      <Flow directory="col" align="center">
        <Button
          onClick={handleToCreateProduct}
          color="green"
          size="2xl"
          icon={<Icon.AddFull color="positive" />}
          variant="link"
        />
        <span className="text-positive-500">Добавить</span>
      </Flow>
      <Flow directory="col" align="center">
        <Button
          onClick={handleToFavorites}
          color="bg-neutral-100"
          size="2xl"
          icon={<Icon.FavoriteFull />}
          variant="link"
        />
        <span>Избранное</span>
      </Flow>
      <Flow directory="col" align="center">
        <Button onClick={handleToProfile} color="bg-neutral-100" size="2xl" icon={<Icon.UserFull />} variant="link" />
        <span>Профиль</span>
      </Flow>
    </nav>
  )
}
