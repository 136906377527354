import { SizeType } from '@platform/ui'

export enum StateTabs {
  active = 1,
  waitPayment = 2,
  waitModeration = 3,
  rejected = 4,
  archive = 5,
}

export enum TypeTabs {
  all = 1,
  sell = 2,
  buy = 3,
  exchange = 4,
  auction = 5,
}

export enum SettingsTabs {
  profile = 1,
  organizationData = 2,
  notifications = 3,
  security = 4,
}

export type ProfileOutletContext = {
  backCallback?: () => void
}
