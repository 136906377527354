import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Category } from '@platform/core/dist/modules/catalog/domain/category'
import { Accordion, AccordionGroup, AccordionItem, Button, Icon } from '@platform/ui'

import { core } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Loading } from '@src/components/common/loading'

import { CategoryNavigationItem } from './category-navigation-item'

const { categoryService } = core
const { categories } = categoryService

export const CategoryNavigation: FC = observer(() => {
  const [activeCategory, setActiveCategory] = useState<Category | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    categoryService.fetchTreeCategories()
  }, [])

  const handleCategoryClick = (category: Category) => {
    if (category.childs.length) {
      setActiveCategory(category)
      return
    }
    navigate(`/category/${category.code}--${category.id}`)
  }

  const categoryList = useMemo(() => {
    if (activeCategory) {
      const category = categoryService.categories.findById(activeCategory.id)
      return [category]
    }

    return categoryService.categories.getTreeById(null)
  }, [activeCategory, categories.list])

  const parentsCategory = useMemo(() => {
    if (!activeCategory) return []
    const parentsList = categories.getListParentsById(Number(activeCategory.id))
    if (parentsList.length === 1) return []
    parentsList.pop()
    return parentsList
  }, [activeCategory, categories.list])

  if (categoryService.isLoading) {
    return <Loading />
  }
  return (
    <Flow directory="col" space={2}>
      <Flow directory="col" align="start">
        {!!activeCategory && (
          <Button
            variant="link"
            color="primary"
            icon={<Icon.ArrowLong position="left" />}
            onClick={() => setActiveCategory(null)}
          >
            Все категории
          </Button>
        )}
        {!!parentsCategory.length &&
          parentsCategory.map((item) => (
            <Button
              key={item.id}
              variant="link"
              color="primary"
              icon={<Icon.ArrowLong position="left" />}
              onClick={() => setActiveCategory(categories.findById(item.id))}
            >
              {item.name}
            </Button>
          ))}
      </Flow>
      <Accordion initialActiveCollapse={activeCategory ? 0 : undefined}>
        {categoryList.map((item) => {
          if (item.childs.length) {
            return (
              <AccordionGroup
                key={item.id}
                title={<CategoryNavigationItem data={item} onClick={handleCategoryClick} />}
              >
                {item.childs.map((childItem) => (
                  <AccordionItem
                    key={childItem.id}
                    title={<CategoryNavigationItem data={childItem} onClick={handleCategoryClick} />}
                  />
                ))}
              </AccordionGroup>
            )
          }
          return (
            <AccordionItem key={item.id} title={<CategoryNavigationItem data={item} onClick={handleCategoryClick} />} />
          )
        })}
      </Accordion>
    </Flow>
  )
})
