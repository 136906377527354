import { FC, useCallback, useEffect, useState, useRef, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Input, Option, ProgressBar } from '@platform/ui'
import { core } from '@src/core'
import { FormItem } from '@src/components/common/form-item'
import { isObject } from '@src/utils/helpers'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { Section } from '@src/components/common/section'
import { Label } from '@src/components/common/label'
import { Map, Placemark, Geometry, MapProps } from '@src/components/common/ymaps'
import { Flow } from '@src/components/common/flow'
import { TooltipInfo } from '@src/components/common/tooltip-info'

import { autocompliteAddres } from './utils'
import { CreateProductContext } from '../context'

const createProductService = core.createProductService
const { createProduct } = createProductService
const { location } = createProduct

export const Location: FC = observer(() => {
  const [progressIsShow, setProgressIsShow] = useState<boolean>(false)
  const nodeLocation = useRef<HTMLDivElement>(null)
  const context = useContext(CreateProductContext)
  const [handleToLocation] = useScrollIntoView({ ref: nodeLocation })

  useEffect(() => {
    if (location.loadingPercent === 100 || !!location.isValid) {
      setProgressIsShow(false)
    }
  }, [location.loadingPercent])

  useEffect(() => {
    context?.addFields([{ key: location.key, handleScroll: handleToLocation, orderMarkup: 3 }])
  }, [])

  const handleAddressChange = useCallback((option: Option) => {
    if (isObject(option)) {
      const { value, geo_lat, geo_lon, kladrCode } = option
      location.setState({
        value: String(value),
        kladrCode: String(kladrCode),
        latitude: geo_lat as string | null,
        longitude: geo_lon as string | null,
      })
    }
  }, [])

  const handleCoordsBlur = useCallback(() => {
    setProgressIsShow(true)
    location.setPercent(0)
    createProductService.geolocateAddress()
  }, [])

  const handleLatitudeChange = useCallback((value: string) => {
    location.changeLatitude(value)
  }, [])

  const handleLongitudeChange = useCallback((value: string) => {
    location.changeLongitude(value)
  }, [])

  const handleMapClick = useCallback((coords: Geometry) => {
    const [latitude, longitude] = coords

    location.setPercent(0)
    location.changeLatitude(String(latitude.toFixed(6)))
    location.changeLongitude(String(longitude.toFixed(6)))
    createProductService.geolocateAddress()
  }, [])

  let mapState: MapProps['state']
  if (location.isValid && location.latitude && location.longitude) {
    mapState = {
      center: [Number(location.latitude), Number(location.longitude)],
      zoom: 16,
      behaviors: ["disable('scrollZoom')"],
    }
  }

  return (
    <Section ref={nodeLocation}>
      <FormItem
        className="flex-col space-y-4"
        label={
          <Label required={location.required} label={location.label} textStyle="text-lg md:text-1xl font-medium" />
        }
        errorText={location.errorText ?? ''}
      >
        <Flow align="start" className="w-3/4" space={5}>
          <Input.Autocomplete
            placeholder="Укажите адрес, где находится ваш товар"
            error={!location.isValid}
            getOptions={autocompliteAddres}
            onChange={handleAddressChange}
            size="lg"
            value={
              location.value
                ? {
                    value: location.value,
                  }
                : ''
            }
          />
          <Flow directory="col" align="end" space={1.5}>
            <Flow align="start" space={3}>
              <Input
                placeholder="Широта"
                size="lg"
                value={location.latitude ?? ''}
                onBlur={handleCoordsBlur}
                onChangeValue={handleLatitudeChange}
                error={!location.isValid}
              />
              <Input
                placeholder="Долгота"
                size="lg"
                value={location.longitude ?? ''}
                onBlur={handleCoordsBlur}
                onChangeValue={handleLongitudeChange}
                error={!location.isValid}
              />
            </Flow>
            <TooltipInfo labelText="latitude and longitude" text="Как узнать широту и долготу?" />
          </Flow>
        </Flow>
      </FormItem>
      <div className="relative w-full">
        <div className="absolute w-full -top-1.5">
          {progressIsShow && <ProgressBar procent={location.loadingPercent} />}
        </div>
        <Map state={mapState} onClick={handleMapClick} height={320}>
          <Placemark geometry={[Number(location.latitude), Number(location.longitude)]} />
        </Map>
      </div>
    </Section>
  )
})
