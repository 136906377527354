import view1 from './images/view.png'
import view2 from './images/view2.png'
import view3 from './images/view3.png'

const IMAGES = [{ view: view1 }, { view: view2 }, { view: view3 }]

export const TARIFFS = [
  {
    name: 'Стандарт',
    details: ['тарифный план номер', 'тарифный план номер', 'тарифный план номер', 'тарифный план номер'],
    price: '1600',
    active: false,
  },
  {
    name: 'Премиум',
    details: ['тарифный план номер', 'тарифный план номер', 'тарифный план номер', 'тарифный план номер'],
    price: '2600',
    active: true,
    popular: true,
  },
  {
    name: 'Все включено',
    details: ['тарифный план номер', 'тарифный план номер', 'тарифный план номер', 'тарифный план номер'],
    price: '3600',
    active: false,
  },
]

export const UPGRADES = [
  {
    id: 1,
    price: 400,
    name: 'Выделите объявление цветом',
    description:
      'Объявление с цветовой рамкой отличается от других и сильнее привлекает внимание пользователей. На выделенные объявления приходит больше откликов, чем на объявления без выделения.',
  },
  {
    id: 2,
    price: 400,
    name: 'Выделите объявление цветом',
    description:
      'Объявление с цветовой рамкой отличается от других и сильнее привлекает внимание пользователей. На выделенные объявления приходит больше откликов, чем на объявления без выделения.',
  },
  {
    id: 3,
    price: 400,
    name: 'Выделите объявление цветом',
    description:
      'Объявление с цветовой рамкой отличается от других и сильнее привлекает внимание пользователей. На выделенные объявления приходит больше откликов, чем на объявления без выделения.',
  },
]

export const PRODUCT = {
  images: IMAGES,
  description: 'Продам пшеницу, 4-класс, урожай 2019 года',
}

export const SELECTED = {
  tariff: TARIFFS[1],
  upgrades: [UPGRADES[0]],
  total: '2000',
}
