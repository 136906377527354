import React from 'react'
import { Outlet } from 'react-router-dom'
import { DefaultProps } from '@src/types'
import { Box, Info, Steps, Grid, GridCell, ResponsType } from '@platform/ui'
import { Title } from '@src/components/common/title'

import { Totals } from '../totals'
import { ProviderByPage } from '../../context'
import { useStepBy } from '../../hooks/use-step-by'

const responsLGridCell: ResponsType = {
  lg: 'col-span-6',
}
const responsRGridCell: ResponsType = {
  lg: 'col-span-2',
}

export const CartGrid: React.FC<DefaultProps> = (props) => {
  const stepData = useStepBy()

  return (
    <ProviderByPage
      value={{
        steps: stepData.steps,
        setStep: stepData.setCurrentStep,
        currentStep: stepData.currentStep,
      }}
    >
      <Grid>
        <GridCell className="space-y-10 space-y-reverse" width={8} height={1}>
          <Title className="mb-10" size="2xl">
            Корзина
          </Title>
          <Steps
            className="max-w-568px pb-6"
            activeIndex={Number(stepData.currentStep)}
            labels={stepData.labels}
            mode="desktop"
          />
        </GridCell>
        <GridCell width={8} height={1} responsive={responsLGridCell}>
          <Outlet />
        </GridCell>
        <GridCell width={8} height={1} responsive={responsRGridCell}>
          <Grid columnsNumber={1}>
            <GridCell>
              <Box shadow rounded="md">
                <Totals />
              </Box>
            </GridCell>
            <GridCell>
              <Box shadow rounded="md">
                <Info
                  title="Пока вы ничего не платите"
                  titleClassName="font-medium text-md"
                  descClassName="text-sm text-neutral-400"
                >
                  Итоговая стоимость заказа будет расчитана поставщиком после отправки заявки.
                </Info>
              </Box>
            </GridCell>
          </Grid>
        </GridCell>
      </Grid>
    </ProviderByPage>
  )
}

CartGrid.displayName = 'CartGrid'
