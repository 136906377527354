import { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { ProgressBar } from '@platform/ui'
import { ParamsProductLocations } from '@platform/core/dist/api/v1/product'
import { PointLocationDTO } from '@platform/core/dist/modules/location/infra/dtos/pointLocationDTO'

import { Geometry, Map, Cluster } from '@src/components/common/ymaps'
import { Flow } from '@src/components/common/flow'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { Title } from '@src/components/common/title'
import { LINKS } from '@src/routes'
import { core } from '@src/core'
import { MapBounds } from '@src/components/common/ymaps'
import { useSearchParamsApp } from '@src/hooks/use-search-params-app'
import { FilterSearchParam } from '@src/search-params-settings'

import { MapFilterParamsProvider } from './context'
import { MapSidebar } from './container/map-sidebar'

const { locationService } = core
const { locationMap } = locationService

const { CENTER, USER, DELIVERY, CATEGORIES, PAYMENT_METHOD, EXTRA, START_PRICE, END_PRICE } = FilterSearchParam

const DEFAULT_ZOOM = 14

export const MapPage: FC = observer(() => {
  const [progressPercent, setProgressPercent] = useState<number>(0)
  const [bounds, setBounds] = useState<[Geometry, Geometry]>()
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM)
  const [activeLocation, setActiveLocation] = useState<PointLocationDTO | null>(null)

  const { searchParams, deleteSearchParam, hasSearchParam, setSearchParam, getSearchParam } = useSearchParamsApp()

  const params: ParamsProductLocations = useMemo(() => {
    const result: ParamsProductLocations = {}

    searchParams.forEach((value, key) => {
      switch (key) {
        case USER:
          result.searchUserId = Number(value)
          break
        case CATEGORIES:
          result.searchCategories = value.split(',').map(Number)
          break
        case DELIVERY:
          result.searchDeliveryForm = value.split(',').map(Number)
          break
        case PAYMENT_METHOD:
          result.searchPaymentForm = value.split(',').map(Number)
          break
        case START_PRICE:
          result.searchStartPrice = Number(value)
          break
        case END_PRICE:
          result.searchEndPrice = Number(value)
          break
        case EXTRA:
          result.tag = value.split(',').map(String) as any
          break
        default:
          break
      }
    })
    return result
  }, [searchParams])

  useEffect(() => {
    if (bounds && hasSearchParam(CENTER)) {
      locationService.fetchProductLocations(zoom, bounds, params)
    }
  }, [zoom, params, hasSearchParam])

  const state = useMemo(() => {
    if (hasSearchParam(CENTER)) {
      const [lat, long] = (getSearchParam(CENTER) as string).split(',')
      return { center: [Number(lat), Number(long)], zoom }
    }
    return
  }, [zoom, getSearchParam])

  const handleMapBoundsChange = useCallback(
    (bounds: MapBounds) => {
      const { newCenter, newBounds, newZoom } = bounds
      setZoom(newZoom)
      setBounds(newBounds)
      setSearchParam(CENTER, `${newCenter[0]},${newCenter[1]}`)
    },
    [setSearchParam]
  )

  const handlePlacemarkClick = useCallback((hash: string, count: number, coords: Geometry) => {
    setActiveLocation({
      lat: coords[0],
      lon: coords[1],
      hash,
      count,
    })
  }, [])

  const handleBack = useCallback(() => {
    setActiveLocation(null)
  }, [])

  return (
    <MapFilterParamsProvider
      value={{
        getSearchParam,
        hasSearchParam,
        setSearchParam,
        deleteSearchParam,
        searchParams,
      }}
    >
      <div className="relative w-full overflow-hidden">
        <Flow className="absolute top-28px left-144px z-10" directory="col" space={5}>
          <Flow className="bg-white rounded-xl py-7 px-8" directory="col" space={7}>
            <Breadcrumbs items={[{ children: 'Главная', href: LINKS.HOME }, { children: 'Искать на карте' }]} />
            <Title size="2xl" weight="semibold">
              Искать на карте
            </Title>
          </Flow>
          <MapSidebar params={params} activeLocation={activeLocation} onBackCallback={handleBack} />
        </Flow>
        <ProgressBar procent={progressPercent} />
        <Map state={state} onBoundsChange={handleMapBoundsChange} options={{ minZoom: 3, maxZoom: 17 }}>
          {locationMap.locationList.map((item) => (
            <Cluster
              key={item.hash}
              count={item.count}
              geometry={[item.lat, item.lon]}
              hash={item.hash}
              onClick={handlePlacemarkClick}
            />
          ))}
        </Map>
      </div>
    </MapFilterParamsProvider>
  )
})

MapPage.displayName = 'MapPage'
