import { FC } from 'react'
import { Link } from 'react-router-dom'

import { linkMap } from './constants'

export const NavBar: FC = () => {
  return (
    <nav className="flex gap-5 w-full">
      {linkMap.map(({ name, to }, index) => (
        <Link to={to} key={index} className="text-neutral-500 font-medium leading-md">
          {name}
        </Link>
      ))}
    </nav>
  )
}
