import { FC } from 'react'
import { Tooltip, Icon } from '@platform/ui'
import { Flow } from '../flow'

import { TooltipInfoProps } from './types'

export const TooltipInfo: FC<TooltipInfoProps> = ({ text, labelText }) => {
  return (
    <Tooltip label={labelText}>
      <Flow space={1} align="center">
        <Icon.InfoFull color="neutral" colorNumber={300} size="xs" />
        {!!text && <span className="text-neutral-400 font-semibold text-xss">{text}</span>}
      </Flow>
    </Tooltip>
  )
}
