import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { List, ListItem, Title } from '@platform/ui'

import { ExtraProps } from './types'

export const Extra: FC<ExtraProps> = ({ direction, deliveryList, paymentList }) => {
  return (
    <Flow directory={direction} space={8}>
      <Flow directory="col" className="space-y-4 md:space-y-18px">
        <Title size="md" textClassName="font-medium" color="black">
          Способ доставки
        </Title>
        <List className="list-disc list-inside space-y-3 md:space-y-3.5">
          {deliveryList.map((item, key) => (
            <ListItem key={key} className="text-sm lg:text-md">
              {item}
            </ListItem>
          ))}
        </List>
      </Flow>
      <Flow directory="col" className="space-y-4 md:space-y-18px">
        <Title size="md" textClassName="font-medium" color="black">
          Способ оплаты
        </Title>
        <List className="list-disc list-inside space-y-3 md:space-y-3.5">
          {paymentList.map((item, key) => (
            <ListItem key={key} className="text-sm lg:text-md">
              {item}
            </ListItem>
          ))}
        </List>
      </Flow>
    </Flow>
  )
}
