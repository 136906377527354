import { FC } from 'react'
import { Button, Icon } from '@platform/ui'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

export const UserGuestBar: FC = () => {
  const handleToEntry = useNavigationApp({ to: LINKS.AUTH_SIGNIN })
  const handleToRegister = useNavigationApp({ to: LINKS.AUTH_SIGNUP })

  return (
    <div className="flex items-center space-x-2">
      <Button icon={<Icon.Entry />} size="md" variant="link" color="white" onClick={handleToEntry}>
        Вход
      </Button>
      <Button size="md" variant="link" color="white" onClick={handleToRegister}>
        Регистрация
      </Button>
    </div>
  )
}
