import { createContext } from 'react'

import { CategoryDTO } from '@platform/core/dist/modules/catalog/infra/dtos'

export interface IContext {
  activeSection: CategoryDTO
  setActiveSection: (categoryCode: string) => void
}

export const CategoriesContext = createContext<IContext | null>(null)
