import { useCallback, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Carousel, Button, Icon } from '@platform/ui'

import { core } from '@src/core'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { Flow } from '@src/components/common/flow'
import { Title } from '@src/components/common/title'
import { Price } from '@src/container/price'
import { Field } from '@src/components/common/field'
import { LINKS } from '@src/routes'

import { ProductDetailsProps } from './types'

const { createProductService } = core
const { createdProduct } = createProductService

export const ProductDetails: FC<ProductDetailsProps> = observer(({ withCancelBtn }) => {
  const handleToEdit = useNavigationApp({ to: LINKS.CREATE_PRODUCT })

  const handleDelete = useCallback(async () => {
    const result = await createProductService.deleteCreatedProduct()
    if (result.isSuccess) {
      handleToEdit()
    }
  }, [])

  if (!createdProduct.data) {
    return null
  }

  return (
    <Flow directory="row" space={4} full>
      <div className="max-w-345px w-full">
        <Carousel data={createdProduct.data.images.map((item) => ({ view: item.src, nav: item.src }))} mode="mobile" />
      </div>
      <Flow directory="row" space={4} className="justify-between" full align="start">
        <Flow directory="col" space={8} align="start">
          <Title size="1xl">{createdProduct.data.name}</Title>
          <Flow directory="col" space={'18px'} className="min-w-345px">
            {[...createdProduct.data.attributes].slice(0, 4).map(({ name, valueText, id, measure }) => (
              <Field
                key={id}
                label={name}
                value={`${valueText}${measure ?? ''}`}
                valueClassName="font-medium text-md"
              />
            ))}
          </Flow>
          <Button
            className="underline font-normal"
            color="primary"
            variant="link"
            iconPosition="left"
            onClick={() => {}}
            icon={<Icon.Eye />}
          >
            Предпросмотр объявления
          </Button>
        </Flow>
        <Flow directory="col" space={6} className="w-345px shadow-xl px-8 py-7 rounded-xl">
          {createdProduct.data.price && (
            <Price
              value={createdProduct.data.price}
              oldPrice={createdProduct.data.oldPrice}
              measure={createdProduct.data.measure.name}
              colorName={createdProduct.data.oldPrice ? 'red' : 'dark'}
              colorNumber={createdProduct.data.oldPrice ? 500 : 900}
            />
          )}
          <Flow directory="col" space={2.5}>
            <Button
              variant="link"
              color="primary"
              className="underline font-normal"
              iconPosition="left"
              icon={<Icon.Pencil />}
              onClick={handleToEdit}
            >
              Редактировать объявление
            </Button>
            {withCancelBtn && (
              <Button
                variant="link"
                color="red"
                className="underline font-normal"
                iconPosition="left"
                icon={<Icon.Close />}
                onClick={() => {}}
              >
                Отменить модерацию
              </Button>
            )}
            <Button
              variant="link"
              color="red"
              className="underline font-normal"
              iconPosition="left"
              icon={<Icon.BasketFull />}
              onClick={handleDelete}
            >
              Удалить
            </Button>
          </Flow>
        </Flow>
      </Flow>
    </Flow>
  )
})
