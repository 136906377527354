import { FC, useEffect, useRef, useContext, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { core } from '@src/core'
import { Label } from '@src/components/common/label'
import { FormItem } from '@src/components/common/form-item'
import { Checkbox } from '@platform/ui'
import { useScrollIntoView } from '@src/hooks/use-scroll-into-view'
import { SectionH2 } from '@src/components/common/section'
import { Flow } from '@src/components/common/flow'
import { FieldCheckboxOption } from '@platform/core/dist/modules/product/domain/fields/models'

import { CreateProductContext } from '../context'

const { createProductService } = core
const { createProduct } = createProductService
const { delivery, paymentMethod } = createProduct

export const Logistics: FC = observer(() => {
  const nodeFile = useRef<HTMLDivElement>(null)
  const [handleToLogistics] = useScrollIntoView({ ref: nodeFile })
  const context = useContext(CreateProductContext)

  useEffect(() => {
    createProductService.fetchProductPaymentList(context?.isEdit)
    context?.addFields([{ key: delivery.key, handleScroll: handleToLogistics, orderMarkup: 4 }])
    context?.addFields([{ key: paymentMethod.key, handleScroll: handleToLogistics, orderMarkup: 4 }])
  }, [])

  const handleDeliveryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const { checked } = event.target
      delivery.changeChecked(value, checked)
    },
    [delivery.changeChecked]
  )

  const handleMethodPaymentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const { checked } = event.target
      paymentMethod.changeChecked(value, checked)
    },
    [paymentMethod.changeChecked]
  )

  return (
    <SectionH2 title="Доставка и оплата">
      <div ref={nodeFile}>
        <Flow space={20}>
          <FormItem
            className="flex-col space-y-4"
            label={<Label required={delivery.required} label={delivery.label} textStyle="text-md font-medium" />}
            errorText={delivery.errorText ?? ''}
          >
            <Flow directory="col" space={3.5}>
              {(delivery.value as FieldCheckboxOption[]).map((item, key) => (
                <Checkbox
                  key={key}
                  defaultChecked={item.checked}
                  onChange={(event) => handleDeliveryChange(event, item.value)}
                >
                  {item.label}
                </Checkbox>
              ))}
            </Flow>
          </FormItem>
          <FormItem
            className="flex-col space-y-4"
            label={
              <Label required={paymentMethod.required} label={paymentMethod.label} textStyle="text-md font-medium" />
            }
            errorText={paymentMethod.errorText ?? ''}
          >
            <Flow directory="col" space={3.5}>
              {(paymentMethod.value as FieldCheckboxOption[]).map((item, key) => (
                <Checkbox
                  key={key}
                  defaultChecked={item.checked}
                  onChange={(event) => handleMethodPaymentChange(event, item.value)}
                >
                  {item.label}
                </Checkbox>
              ))}
            </Flow>
          </FormItem>
        </Flow>
      </div>
    </SectionH2>
  )
})
