import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Button } from '@platform/ui'
import ImageNotFound from './images/404.png'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { LINKS } from '@src/routes'

export const NotFound: FC = () => {
  const handleToHome = useNavigationApp({ to: LINKS.HOME })
  return (
    <Flow className="content-main mt-40 justify-between">
      <Flow directory="col" space={16} className="max-w-rem3">
        <h1 className="text-2xl font-semibold">
          <span className="flex flex-col">
            <span>Упс..</span>
            <span>У нас нет страницы, которую вы искали.</span>
          </span>
        </h1>
        <span className="text-xl">Зато есть многих других товаров по хорошим ценам. И они вам понравятся!</span>
        <Button color="primary" className="w-56" size="xl" onClick={handleToHome}>
          Перейти в каталог
        </Button>
      </Flow>
      <div>
        <img src={ImageNotFound} className="h-568px w-568px" />
      </div>
    </Flow>
  )
}
