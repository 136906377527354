import React from 'react'
import { Link } from 'react-router-dom'

import { LogoProps } from './types'
import { ReactComponent as LogoWhitwSVG } from './logo-white.svg'
import { ReactComponent as LogoBlueSVG } from './logo-blue.svg'

export const Logo: React.FC<LogoProps> = ({ className = 'h-5', type = 'white' }) => {
  const Logo = type === 'white' ? LogoWhitwSVG : LogoBlueSVG
  return (
    <Link to="/">
      <Logo className={className} />
    </Link>
  )
}
