import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Info, Button } from '@platform/ui'

import { core } from '@src/core'
import { LINKS } from '@src/routes'
import { useNavigationApp } from '@src/hooks/use-navigation-app'

import { AuthCodeProps } from './types'

const userService = core.userService

export const Code: React.FC<AuthCodeProps> = ({ defaultLinkPath = '' }) => {
  const [searchParams] = useSearchParams()
  const effectRan = useRef(false)

  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  const [loading, setLoading] = useState(false)

  const code = searchParams.get('code') as string

  useEffect(() => {
    setLoading(true)
    setSuccess(false)
    setFailure(false)

    if (!effectRan.current) {
      userService.verify({ code }).then((result) => {
        setLoading(false)

        if (result.isSuccess) {
          setSuccess(true)
          return
        }

        if (result.isFailure) {
          setFailure(true)
        }
      })
      return () => {
        effectRan.current = true
      }
    }
  }, [code])

  const handleAutorized = useNavigationApp({ to: defaultLinkPath + LINKS.AUTH_SIGNIN })

  const renderDescription = () => {
    if (!code) {
      return <Info color="attention" type="warning" title="Код подтверждения не найден" size="3xl" />
    }

    if (failure) {
      return <Info color="negative" type="failure" title="Код подтверждения не действителен" size="3xl" />
    }

    if (success) {
      return (
        <Info color="positive" type="success" title="Код подтвержден" size="3xl">
          <Button onClick={handleAutorized}>Авторизоваться</Button>
        </Info>
      )
    }

    if (loading) {
      return <Info color="primary" type="loading" title="Выполняется проверка кода" size="3xl" />
    }

    return null
  }

  return (
    <div className="flex flex-col items-center justify-center py-10 w-full">
      <div className="rounded-lg mx-1 text-lg p-10 w-full max-w-xl bg-white shadow-lg shadow-dark-500/50">
        {renderDescription()}
      </div>
    </div>
  )
}
