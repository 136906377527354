import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export interface UseNavigationAppProps {
  to: string
  callback?: () => void
}

export const useNavigationApp = ({ to, callback }: UseNavigationAppProps) => {
  const navigate = useNavigate()

  const handleTo = useCallback(() => {
    navigate(to)
    callback?.()
  }, [to])

  return handleTo
}
