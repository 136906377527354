import React from 'react'

export const VerifyPhone: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="rounded-lg mx-1 p-3 lg:p-5 xl:p-10 max-w-2xl bg-white shadow-lg shadow-dark-500/50">
        <h2 className="text-2xl font-medium leading-10">Подтвердите почту</h2>
        <p className="text-lg py-3 leading-6"> На указанную " " было отправлено письмо с кодом потверждения.</p>
      </div>
    </div>
  )
}
