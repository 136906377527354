import { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { core } from '@src/core'
import { ROUTES, LINKS } from '@src/routes'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import { Ads } from './ads'
import { Notifications } from './notifications'
import { Settings } from './settings'
import { Favorites } from './favorites'
import { Layout } from './container/layout'

import NotFound from '../not-found'

const { PROFILE } = ROUTES

const { authService } = core

const ProfilePages = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!authService.isAuthorized) {
      navigate(LINKS.AUTH_SIGNIN)
      return
    }
  }, [])

  if (!authService.isAuthorized) return null

  return (
    <ScrollToTop>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Ads />} />
          <Route path={PROFILE.MESSENGER.INDEX} element={<></>} />
          <Route path={PROFILE.SETTINGS.INDEX} element={<Settings />} />
          <Route path={PROFILE.NOTIFICATIONS.INDEX} element={<Notifications />} />
          <Route path={PROFILE.FAVORITES.INDEX} element={<Favorites />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

export default ProfilePages
